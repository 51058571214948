import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import {
  ButtonFilled,
  Checkbox,
  Container,
  Field,
  Form,
  Prose,
  Link as Go1dLink,
  PasswordInput,
  Select,
  SubmitButton,
  Text,
  TextInput,
  View,
  foundations,
  RadioGroup,
  DatePicker,
} from '@go1d/go1d';
import IconInProgress from '@go1d/go1d/build/components/Icons/InProgress';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { REACT_APP_BASE_DIRECTORY, REACT_APP_API_URL, REACT_APP_PUBLIC_PORTAL } from '../../config';
import { PORTAL_PLAN } from '../../actions/portal';
import LogoContainer from '../../components/LogoContainer';
import track from '../../services/houston';
import { customerRequest, portalRequest, userRequest } from '../../services/requestModels';
import {
  findAccount, getCurrentUser, redirectToPortalWithOTT, getStoredJWT,
} from '../../services/user';
import getNested from '../../utils/getNested';
import createUser from '../../utils/user';

const BaseDirectory = REACT_APP_BASE_DIRECTORY || '';

export default class Signup extends React.Component {
  static propTypes = {
    dispatchStoreSignupValues: PropTypes.func.isRequired,
    dispatchErrorValues: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    storedFormValues: PropTypes.objectOf(PropTypes.any).isRequired,
    storedErrorValues: PropTypes.objectOf(PropTypes.any),
    storedPortalValues: PropTypes.objectOf(PropTypes.any),
  }

  checkEmailTimer = null;

  checkedEmails = {};

  constructor(props) {
    super(props);
    const { storedFormValues } = props;
    const {
      first_name, last_name, email, jwt, freemium,
    } = storedFormValues;

    this.state = {
      dirty: {},
      hasExistingUser: first_name && last_name && email && jwt,
      validatingEmail: false,
      suppressEmailError: false,
      hasFreemium: freemium,
    };
  }

  componentDidMount() {
    this.checkExisting();
  }

  // Cannot use componentDidMount only, on page hard refresh or if a user lands directly on this page, when didMount is called, portal settings are not there yet.
  componentDidUpdate(prevProps) {
    const { storedPortalValues = {} } = this.props;
    const { prevStoredPortalValues = {} } = prevProps;
    if (prevStoredPortalValues.dataLoaded !== storedPortalValues.dataLoaded) {
      this.checkExisting();
    }
  }

  onChange = initialValues => (values) => {
    const { dirty, hasExistingUser, hasFreemium } = this.state;
    const errors = {};

    if (!values.first_name) {
      errors.first_name = 'You must enter a first name.';
    }
    if (!values.last_name) {
      errors.last_name = 'You must enter a last name.';
    }
    if (!values.email) {
      errors.email = 'You must enter an email.';
    } else if (hasFreemium && (!/^([\w.-\\+]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/i.test(values.email))) {
      errors.email = 'You must enter a company/work email.';
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(values.email)) {
      errors.email = 'Invalid email format.';
    }
    if (!hasExistingUser) {
      if (!/[a-zA-Z]+/i.test(values.password)) {
        errors.password = 'Password must contain at least 1 letter. ';
      }
      if (values.password.length <= 7) {
        errors.password = 'Password must be at least 8 characters long. ';
      }
    }

    Object.entries(values).forEach((v) => {
      if (initialValues[v[0]] !== v[1]) {
        dirty[v[0]] = true;
      }
    });

    const { storedPortalValues = {} } = this.props;
    if (storedPortalValues.customFields) {
      storedPortalValues.customFields.forEach(field => {
        if (field.mandatory === '1' && !values[field.name]) {
          errors[field.name] = 'You must enter a value';
        }
      });
    }

    if (storedPortalValues.simplifiedTermsAgreement) {
      if (!values.agreed_to_age_check) {
        errors.agreed_to_age_check = 'You must be over 16 and agree to the privacy policy';
      }
      if (!values.agreed_to_terms_and_conds) {
        errors.agreed_to_terms_and_conds = 'You must agree to the terms and conditions';
      }
      if (!values.agreed_to_information_collection) {
        errors.agreed_to_information_collection = 'You must agree to the privacy policy';
      }
    }
    this.setState({ dirty });
    if (!errors.email && !hasExistingUser) {
      return this.checkEmail(values.email, errors);
    }
    return errors;
  };

  checkEmail = (email, errors) => {
    const formErrors = errors;
    // as per documentation of formik: https://jaredpalmer.com/formik/docs/guides/validation, need a timeout here, so not every keystroke is going to trigger an api call
    /* eslint-disable-next-line no-return-assign */
    const sleep = ms => new Promise(resolve => (this.checkEmailTimer = setTimeout(resolve, ms)));
    if (this.checkEmailTimer) {
      clearTimeout(this.checkEmailTimer);
    }
    const errorMsg = (
      <Text color="accent">
        It looks like you are already a Go1 user, to be added to this portal simply
        {' '}
        <Link to={`${BaseDirectory}/login?email=${email}&redirect_url=signup`}>
          <Text fontWeight="bold">click here</Text>
        </Link>
        {' '}
        to login
      </Text>
    );
    /* Caching of results */
    const cachedResult = this.checkedEmails[email];
    if (typeof cachedResult !== 'undefined') {
      if (cachedResult === true) {
        formErrors.email = errorMsg;
      } else if (cachedResult === false) {
        delete formErrors.email;
      }
      return formErrors;
    }
    this.setState({ suppressEmailError: true }); // Hide invalid message during async validation
    return sleep(500).then(() => {
      this.setState({ validatingEmail: true });
      return axios.get(`${REACT_APP_API_URL}/user/account/found/${REACT_APP_PUBLIC_PORTAL}/${email}`).then(
        (result) => {
          if (result.data && result.data.found > 0) {
            formErrors.email = errorMsg;
            this.checkedEmails[email] = true;
          } else {
            this.checkedEmails[email] = false;
          }
        },
      ).finally(() => {
        this.setState({ validatingEmail: false, suppressEmailError: false });
        throw formErrors;
      });
    });
  }

  onSubmit = async (values) => {
    const {
      history,
      location,
      dispatchStoreSignupValues,
      storedPortalValues = {},
    } = this.props;
    const {
      contentPackageSignupFlow,
      enableLearnerSignupFlow,
      noFocusTerms,
      simplifiedTermsAgreement,
    } = storedPortalValues;
    const isPackageSignupFlow = contentPackageSignupFlow || enableLearnerSignupFlow;
    dispatchStoreSignupValues(values);
    if (isPackageSignupFlow) {
      track(
        'signup',
        {
          id: 'submit-signup-page',
          email: values.email,
        },
      );
    }
    /* istanbul ignore next */
    if (values.type === 'author') {
      history.push(`${REACT_APP_BASE_DIRECTORY}/author_details`);
    } else if (isPackageSignupFlow) {
      if (simplifiedTermsAgreement) {
        await this.onPackageSignupFlowCreateUser();
      } else {
        let searchQuery = qs.parse(location.search);
        const jwt = getStoredJWT();
        let policyId = 'user-terms';
        if (jwt) {
          policyId = 'customer-terms';
        }
        /**
         * To detect if user (in portal already has config contentPackageSignupFlow = true)
         * came from sign up flow or somewhere else, because Policy page is a public page
         * @type {{packageSignupFlowReferer: boolean}}
         */
        searchQuery = { ...searchQuery, packageSignupFlowReferer: true };
        let url = `${REACT_APP_BASE_DIRECTORY}/policy`;
        if (noFocusTerms) {
          url = `${url}?${qs.stringify(searchQuery)}`;
        } else {
          url = `${url}/${policyId}?${qs.stringify(searchQuery)}`;
        }
        history.push(url);
      }
    } else if (simplifiedTermsAgreement) {
      this.onSignupCreateUser();
    } else {
      history.push(`${REACT_APP_BASE_DIRECTORY}/policy/customer-terms?signupTOSAgreement=true`);
    }
  }

  onPackageSignupFlowCreateUser = async () => {
    const {
      location, storedFormValues, storedPortalValues, dispatchOnboardValues,
    } = this.props;

    const searchQuery = qs.parse(location.search);
    const {
      type, email, password, first_name, last_name,
    } = storedFormValues;
    const values = {
      type, email, password, first_name, last_name,
    };
    track('policy', { id: 'agree-policy' });

    if (storedPortalValues.enableLearnerSignupFlow) {
      // 'individual' purchase_level flow
      const user = userRequest(storedFormValues);
      const ONE_SEAT = 1;
      const portal = portalRequest(storedPortalValues, ONE_SEAT);
      const customer = customerRequest(values);
      // creates a new user
      const registeredUser = await this.registerNewUser();
      if (registeredUser) {
        user.accounts = registeredUser.accounts; // copies the 'accounts' property
        const onboardData = {
          user, portal, customer,
        };
        dispatchOnboardValues({ ...onboardData, creation_path: PORTAL_PLAN.REFERRAL_CONTENT_PARTNER });
        this.navigateToChooseLearningPage();
      }
    } else {
      // default flow
      this.navigateToCreatePortalPage(qs.stringify(searchQuery));
    }
  }

  onSignupCreateUser = async () => {
    const { history, storedFormValues } = this.props;
    const { type, plan } = storedFormValues;
    if (type === 'individual') {
      // creates a new user
      const registeredUser = await this.registerNewUser();
      this.navigateToPortalPage(registeredUser);
    } else if (type === 'author' || plan) {
      // do redirects
      history.push(`${REACT_APP_BASE_DIRECTORY}/create`);
    } else if (type === 'team' || type === 'referral') {
      history.push(`${REACT_APP_BASE_DIRECTORY}/choose`);
    }
  }

  navigateToPortalPage = (user) => {
    if (user) {
      const {
        history, storedFormValues, dispatchErrorValues, storedPortalValues,
      } = this.props;
      const { redirect_url } = storedFormValues;
      const { portalId } = storedPortalValues;
      // find correct account id
      const targetAccountId = findAccount(user.accounts, portalId) || getNested(['accounts', 0, 'id'], user);
      redirectToPortalWithOTT(targetAccountId, user.jwt, redirect_url).catch(() => {
        dispatchErrorValues({ email: 'Signup error' });
        history.push(`${REACT_APP_BASE_DIRECTORY}/`);
      });
    }
  }

  navigateToCreatePortalPage = (searchQuery) => {
    const { history } = this.props;
    if (searchQuery) {
      history.push(`${REACT_APP_BASE_DIRECTORY}/create?${searchQuery}`);
    }
  }

  navigateToChooseLearningPage = (searchQuery) => {
    const { history } = this.props;
    history.push(`${REACT_APP_BASE_DIRECTORY}/choose_learning?${searchQuery}`);
  }

  registerNewUser = async () => {
    const {
      history, storedFormValues, dispatchErrorValues, storedPortalValues = {},
    } = this.props;

    try {
      return await createUser(storedFormValues, storedPortalValues);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400 || err.response.status === 403) {
          const errorMsg = err.response.status === 403 ? 'User cannot be added to public portal or already exists.' : err.response.data.message;
          dispatchErrorValues({ email: errorMsg });
          history.push(`${REACT_APP_BASE_DIRECTORY}/`);
        }
      }
    }
    return undefined;
  }

  getCustomFields = () => {
    const {
      storedPortalValues = {},
    } = this.props;
    const { customFields = [] } = storedPortalValues;
    return customFields.map(field => this.getCustomField(field));
  }

  getCustomField = (field) => {
    let component;
    const otherFields = {};
    switch (field.type) {
      case 'float':
      case 'integer':
      case 'string':
        if (field.enum && field.enum.length > 0) {
          component = Select;
          otherFields.options = field.enum.map(item => ({ label: item, value: item }));
          break;
        }
      // eslint-disable-next-line no-fallthrough
      case 'text':
        component = TextInput;
        break;
      case 'datetime':
        otherFields.time = true;
        // eslint-disable-next-line no-fallthrough
      case 'date':
        component = DatePicker;
        break;
      default:
        component = TextInput;
        break;
    }
    return (
      <View marginBottom={5}>
        <Field
          css={{ minHeight: 40 }}
          component={component}
          name={field.name}
          required={!!field.mandatory}
          label={field.label}
          description={field.description}
          data-testid={field.name}
          {...otherFields}
        />
      </View>
    );
  }

  getSimplifiedTermsCheckboxes = (enabled) => {
    if (enabled) {
      const { storedPortalValues = {} } = this.props;
      const termsUrl = storedPortalValues.signupIntoExisting || storedPortalValues.enableLearnerSignupFlow
        ? 'https://www.go1.com/en-au/terms/user-terms'
        : 'https://www.go1.com/en-au/terms/customer-terms';

      return (
        <View marginBottom={5}>
          <Field component={Checkbox} name="agreed_to_age_check" required>
            <Text css={{ whiteSpace: 'pre-wrap' }}>
              I am 16 or more years of age and I have read and agree to the
              {' '}
              <Go1dLink
                href="https://www.go1.com/en-au/terms/privacy-policy"
                target="_blank"
                color="accent"
              >
                Go1 Privacy Policy
              </Go1dLink>
              .
            </Text>
          </Field>
          <Field
            component={Checkbox}
            name="agreed_to_terms_and_conds"
            required
          >
            <Text css={{ whiteSpace: 'pre-wrap' }}>
              I have read and agree to the
              {' '}
              <Go1dLink href={termsUrl} target="_blank" color="accent">
                Go1 terms and conditions of service
              </Go1dLink>
              .
            </Text>
          </Field>
          <Field component={Checkbox} name="agreed_to_information_collection" required>
            <Text css={{ whiteSpace: 'pre-wrap' }}>
              I consent to Go1 collecting my information and to be processed, managed, stored and used as set out in the
              {' '}
              <Go1dLink
                href="https://www.go1.com/en-au/terms/privacy-policy"
                target="_blank"
                color="accent"
              >
                Go1 Privacy Policy
              </Go1dLink>
              .
            </Text>
          </Field>
        </View>
      );
    }
    return undefined;
  }

  checkExisting() {
    const {
      storedPortalValues = {},
      storedFormValues,
    } = this.props;
    const {
      contentPackageSignupFlow, enableLearnerSignupFlow, signupIntoExistingPortal, portalId,
    } = storedPortalValues;

    const isPackageSignupFlow = contentPackageSignupFlow || enableLearnerSignupFlow;
    const { redirect_url } = storedFormValues;
    if (isPackageSignupFlow) {
      track(
        'signup',
        {
          id: 'open-signup-page',
        },
      );
    }
    // If user is supposed to sign up into an existing portal check if JWT for that particular portal is present, if so -> log user in
    const jwt = getStoredJWT();
    const isSignupToExistingPortal = (signupIntoExistingPortal || enableLearnerSignupFlow) && jwt;
    if (isSignupToExistingPortal) {
      getCurrentUser(jwt, true)
        .then((res) => {
          const targetAccountId = findAccount(getNested(['data', 'accounts'], res, []), portalId);
          if (targetAccountId) {
            redirectToPortalWithOTT(targetAccountId, jwt, redirect_url).catch(() => {
            });
          }
        }, () => {
          const jwtDecoded = jwt_decode(jwt);
          const targetAccountId = findAccount(getNested(['object', 'content', 'accounts'], jwtDecoded, []), portalId);
          if (targetAccountId) {
            redirectToPortalWithOTT(targetAccountId, jwt, redirect_url).catch(() => {
            });
          }
        });
    }
  }

  render() {
    const {
      storedFormValues,
      storedErrorValues,
      storedPortalValues = {},
    } = this.props;
    const { logo, signupIntoExistingPortal } = storedPortalValues;
    const {
      dirty, hasExistingUser, validatingEmail, suppressEmailError, hasFreemium,
    } = this.state;
    const allTypeOptions = [
      {
        label: 'Individual',
        value: 'individual',
      },
      {
        label: 'Team',
        value: 'team',
      },
      {
        label: 'Author',
        value: 'author',
      },
    ];

    const {
      signupTagline,
      signupSecondaryTagline,
      signupMessage,
      signupUnitOptions,
      contentPackageSignupFlow,
      enableLearnerSignupFlow,
      simplifiedTermsAgreement,
      referral,
    } = storedPortalValues;

    const isPackageSignupFlow = contentPackageSignupFlow || enableLearnerSignupFlow;
    // Allow Go1 staff to custom data types from portal settings
    const typeOptions = signupUnitOptions && signupUnitOptions.length > 0
      ? allTypeOptions.filter(option => signupUnitOptions.indexOf(option.value) > -1)
      : (!isPackageSignupFlow ? allTypeOptions : []);
    const canSelectType = storedFormValues.type !== 'referral' && typeOptions.length > 1;

    const initialValues = {
      first_name: storedFormValues.first_name ? storedFormValues.first_name : '',
      last_name: storedFormValues.last_name ? storedFormValues.last_name : '',
      email: storedFormValues.email ? storedFormValues.email : '',
      phone: storedFormValues.phone || '',
      password: storedFormValues.password ? storedFormValues.password : '',
      type: signupUnitOptions && signupUnitOptions.length === 1 ? signupUnitOptions[0] : (storedFormValues.type ? storedFormValues.type : 'individual'),
    };

    if (simplifiedTermsAgreement) {
      initialValues.agreed_to_age_check = false;
      initialValues.agreed_to_terms_and_conds = false;
      initialValues.agreed_to_information_collection = false;
    }

    let enableReinitialize = false;
    if (storedPortalValues.customFields) {
      enableReinitialize = true;
      storedPortalValues.customFields.forEach(field => {
        initialValues[field.name] = storedFormValues[field.name] || '';
      });
    }

    return (
      <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
        <Container contain="narrow" marginTop={6}>
          <LogoContainer
            title={signupTagline || (hasExistingUser && !signupIntoExistingPortal ? 'Create a new Go1 portal' : (logo ? 'Sign up' : 'Sign up to Go1'))}
            description={!signupMessage && (signupSecondaryTagline || 'Access thousands of learning items from top content providers.')}
          >
            {signupMessage && <View marginBottom={4}><Prose css={{ p: { fontSize: foundations.type.scale.lg[2] } }} fontSize={1} HTML={signupMessage} /></View>}
            <Form
              onSubmit={this.onSubmit}
              validate={this.onChange(initialValues)}
              initialValues={initialValues}
              enableReinitialize={enableReinitialize}
            >
              {canSelectType && (
                <View
                  marginBottom={5}
                >
                  <Field
                    component={RadioGroup}
                    defaultValue={storedFormValues.type ? storedFormValues.type : 'individual'}
                    maxWidth={signupUnitOptions && signupUnitOptions.length === 2 ? 200 : 320}
                    css={{
                      flexDirection: 'column',
                      '@media(min-width: 375px)': {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                      [foundations.breakpoints.sm]: {
                        '> div': {
                          paddingTop: foundations.spacing[3],
                          paddingBottom: foundations.spacing[3],
                        },
                      },
                    }}
                    options={typeOptions}
                    name="type"
                    required
                  />
                </View>
              )}
              <View
                css={{
                  [foundations.breakpoints.sm]: {
                    flexDirection: 'column',
                  },
                  [foundations.breakpoints.md]: {
                    flexDirection: 'row',
                  },
                }}
                marginBottom={5}
              >
                <View
                  css={{
                    [foundations.breakpoints.sm]: {
                      paddingRight: 0,
                      width: '100%',
                    },
                    [foundations.breakpoints.md]: {
                      paddingRight: 10,
                      width: '50%',
                    },
                  }}
                  flexGrow={1}
                >
                  <Field
                    component={TextInput}
                    name="first_name"
                    disabled={hasExistingUser}
                    required
                    label="Full Name"
                    placeholder="First Name"
                    errorMessage={(storedErrorValues && storedErrorValues.first_name) && !dirty.first_name ? storedErrorValues.first_name : ''}
                  />
                </View>
                <View
                  css={{
                    [foundations.breakpoints.sm]: {
                      paddingLeft: 0,
                      width: '100%',
                    },
                    [foundations.breakpoints.md]: {
                      paddingLeft: 10,
                      width: '50%',
                    },
                  }}
                  flexGrow={1}
                >
                  <Field
                    component={TextInput}
                    name="last_name"
                    label=" "
                    disabled={hasExistingUser}
                    required
                    placeholder="Last Name"
                    errorMessage={(storedErrorValues && storedErrorValues.last_name) && !dirty.last_name ? storedErrorValues.last_name : ''}
                  />
                </View>
              </View>
              <View
                marginBottom={5}
              >
                <Field
                  component={TextInput}
                  name="email"
                  label={!hasFreemium ? 'Email' : 'Company Email'}
                  type="email"
                  disabled={hasExistingUser}
                  suppressError={suppressEmailError} // Hide invalid message during async validation
                  required
                  errorMessage={(storedErrorValues && storedErrorValues.email) && !dirty.email ? storedErrorValues.email : ''} // Hide invalid message during async validation
                  suffixNode={
                    validatingEmail ? (
                      <span className="loader">
                        <IconInProgress color="accent" marginX={3} />
                      </span>
                    ) : (
                      ''
                    )
                  }
                />
              </View>
              {!hasExistingUser && (
                <View
                  marginBottom={5}
                >
                  <Field
                    component={PasswordInput}
                    name="password"
                    label="Password"
                    required
                    errorMessage={(storedErrorValues && storedErrorValues.password) && !dirty.password ? storedErrorValues.password : ''}
                  />
                  <Text color="subtle">
                    Must include at least
                    {' '}
                    <strong>1 letter</strong>
                    {' '}
                    and be
                    {' '}
                    <strong>at least 8 characters long</strong>
                  </Text>
                </View>
              )}
              {hasFreemium && (
                <View paddingBottom={5}>
                  <Field
                    component={TextInput}
                    name="phone"
                    label="Contact Phone Number"
                    placeholder="+61 1 2345 6789"
                    disabled={hasExistingUser}
                  />
                </View>
              )}
              {this.getCustomFields()}
              {this.getSimplifiedTermsCheckboxes(simplifiedTermsAgreement)}
              <SubmitButton
                justifyContent="center"
                size="lg"
                css={{
                  width: '100%',
                }}
              >
                Continue
              </SubmitButton>
            </Form>
          </LogoContainer>

          {!hasExistingUser
            && (
              <ButtonFilled
                element={Link}
                to={`${BaseDirectory}/login${referral ? `?${qs.stringify({ referral })}` : ''}`}
                width="100%"
                size="lg"
                marginTop={6}
              >
              Have an account? Log in
              </ButtonFilled>
            )
          }
        </Container>
      </Container>
    );
  }
}
