import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
} from '@go1d/go1d';
import IconGo1Logo from '@go1d/go1d/build/components/Icons/Go1Logo';

//  This is the original design for the header. It will be visible on the pages remaining, EXCLUDING login and signup.

const Header = ({ logo, portal }) => (
  <View id="HeaderNavWrapper" element="header" zIndex={50} paddingY={6} alignItems="center">
    {logo
        && (
        <View maxWidth={200}>
          <a target="_blank" rel="noopener noreferrer" aria-label="Logo" href={`https://${portal}`}>
            <img src={logo} alt={`${portal} Logo`} />
          </a>
        </View>
        )
      }
    {!logo
        && (
        <a target="_blank" rel="noopener noreferrer" aria-label="Go1 Logo" href="https://www.go1.com/">
          <IconGo1Logo color="default" size={8} />
        </a>
        )
      }
  </View>
);

Header.propTypes = {
  portal: PropTypes.string,
  logo: PropTypes.string,
};


export default Header;
