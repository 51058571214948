import React from 'react';
import { foundations, View } from '@go1d/go1d';

const date = new Date();
const year = date.getFullYear();

export default () => (
  <View
    css={{
      strong: {
        fontWeight: 'bold',
      },
      p: {
        marginBottom: '.5rem',
        marginTop: '1.25rem',
      },
      li: {
        margin: '.5rem 0',
        marginTop: '1.25rem',
      },
      ul: {
        listStyleType: 'disc',
        paddingLeft: '2rem',
      },
      ol: {
        listStyleType: 'decimal',
        paddingLeft: '2rem',
      },
      h3: {
        color: foundations.colors.accent,
        fontWeight: 'bold',
        margin: '0.5rem 0',
      },
      a: {
        color: foundations.colors.accent,
      },
    }}
  >
    <p>
    © Go1 Copyright
      {' '}
      {year}
    </p>
  </View>
);
