import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { foundations, Text, View } from '@go1d/go1d';
import go1Terms from '../../../constants/go1Terms';
import { REACT_APP_BASE_DIRECTORY } from '../../../config';

export default () => {
  const searchQuery = get(window, 'location.search');
  const baseDirectory = REACT_APP_BASE_DIRECTORY || '';
  const privacyLink = `${baseDirectory}/policy/privacy-policy${searchQuery}`;
  const acceptableUseLink = `${baseDirectory}/policy/acceptable-use${searchQuery}`;

  return (
    <View
      css={{
        strong: {
          fontWeight: 'bold',
        },
        p: {
          marginBottom: '.5rem',
          marginTop: '1.25rem',
        },
        li: {
          margin: '.5rem 0',
          marginTop: '1.25rem',
        },
        ul: {
          listStyleType: 'disc',
          paddingLeft: '2rem',
        },
        ol: {
          listStyleType: 'decimal',
          paddingLeft: '2rem',
        },
        h3: {
          color: foundations.colors.accent,
          fontWeight: 'bold',
          margin: '0.5rem 0',
        },
        h1: {
          color: foundations.colors.subtle,
          marginBottom: '.5rem',
          marginTop: '1.25rem',
          fontSize: foundations.spacing[5],

        },
        a: {
          color: foundations.colors.accent,
        },
        tab: {
          marginRight: '1.25rem',
        },
        tab2: {
          marginRight: '2rem',
        },
      }}
    >
      <h1>
        <tab>
          1.
        </tab>
        Application
      </h1>
      <p>
        <strong>From the word GO</strong>
      </p>
      <li>
        <tab>
          1.1
        </tab>
        These terms and conditions
        <Text fontWeight="semibold">(“‘Customer Terms”)</Text>
        will apply (including to all to
        all Services) from the beginning of our relationship with you, as the Customer (“Start Date”), which occurs when
        you use any of the Services or when you click the “sign up” button or initiate a trial, test or other preliminary
        use and do not opt out or you otherwise indicate acceptance on or through the Website or the App when you sign a
        Subscription Agreement or Order Form. Please read these Customer Terms carefully, as they are legally binding upon
        you.
      </li>
      <li>
        <tab>
          1.2
        </tab>
        When reference is made to “we” or “us” or “our” in these Customer Terms, it means Go1.
      </li>
      <li>
        <tab>
          1.3
        </tab>
        Where a document provided by you purports to be made on or subject to terms and conditions other than these
        Customer Terms, you agree that such other terms and conditions are disregarded and form no part of the Contract
        unless we have expressly agreed otherwise in writing.
      </li>
      <li>
        <tab>
          1.4
        </tab>
        Where you have elected to use the Software for your own Content or Content other than Go1 Content, you acknowledge
        that you have read and understood the Content Provider Agreement, to which you agree to be bound.
      </li>
      <li>
        <tab>
          1.5
        </tab>
        Where there is any inconsistency(s) between these Customer Terms and the Subscription Agreement, Order Form,
        Partner Agreement or Content Provider Agreement, the terms and conditions of the Subscription Agreement, Order
        Form,
        {' '}
        <a target="_blank" href={go1Terms.partnerAgreement} rel="noopener noreferrer">Partner Agreement</a>
        {' '}
        or Content Provider Agreement (as the case may be) prevail over these Customer Terms to the extent of the inconsistency(s).
      </li>
      <li>
        <tab>
          1.6
        </tab>
        Heading are used for convenience only and do not affect the interpretation of these Customer Terms.
      </li>
      <li>
        <tab>
          1.7
        </tab>
        The capitalised terms used are defined at the end of these Customer Terms.
      </li>
      <li>
        <tab>
          1.8
        </tab>
        Any version of these Customer Terms in a language other than English is provided for convenience only and you
        understand and agree that the English language version will control if there is any conflict.
      </li>
      <p>
        <strong>
          <tab2>
            2.
          </tab2>
          Services and support
        </strong>
      </p>
      <p>
        <strong>
          Good to GO
        </strong>
      </p>
      <li>
        <tab>
          2.1
        </tab>
        Subject to these Customer Terms, we will use commercially reasonable efforts to provide you with the Services. As
        part of the registration process, you will identify an administrative user name and password for your Go1 account.
        We reserve the right to refuse registration of, or cancel passwords we deem inappropriate.
      </li>
      <li>
        <tab>
          2.2
        </tab>
        Subject to these Customer Terms, we will provide you with reasonable technical support services in accordance with
        our standard practice.
      </li>
      <li>
        <tab>
          2.3
        </tab>
        The Services may also extend to any related items quoted and billed by us.
      </li>
      <li>
        <tab>
          2.4
        </tab>
        We may modify the Services or discontinue their availability at any time.
      </li>
      <p>
        <strong>
          <tab2>
            3.
          </tab2>
          Restrictions and responsibilities
        </strong>
      </p>
      <p>
        <strong>
          GO and no GO
        </strong>
      </p>
      <li>
        <tab>
          3.1
        </tab>
        You acknowledge that you have read our
        {' '}
        <Link to={acceptableUseLink}>Acceptable Use Policy</Link>
        {' '}
        and
        {' '}
        <Link to={privacyLink}>Privacy Policy</Link>
        {' '}
        and that you agree with the content of and to be bound by both the
        {' '}
        <Link to={acceptableUseLink}>Acceptable Use Policy</Link>
        {' '}
        and
        {' '}
        <Link to={privacyLink}>Privacy Policy</Link>
        {' '}
        , which are incorporated by reference into these Customer Terms.
      </li>
      <li>
        <tab>
          3.2
        </tab>
        You represent, warrant and covenant that:
        <ul>
          <li>
            you are over the age of 18, or, if not, you will only use the Services with the involvement, supervision and approval of a
            parent or legal guardian (children under the age of 16 must not register for an account or register for
            or purchase Courses); and
          </li>
          <li>
            you have read, understood, and agree to be bound by the pricing information (see clause 5 (Pricing and payment of fees));
          </li>
        </ul>
      </li>
      <li>
        <tab>
          3.3
        </tab>
        You understand and agree that:
      </li>
      <ul>
        <li>
          the Website, special technology used in connection with the Website, the App, the Services, the Software, the Courses
          and the Materials are provided by us or third-party providers (including Content Providers) and are the copyrighted
          works of Go1 and/or such third parties;
        </li>
        <li>
          Content Providers may choose to terminate their relationships with us and become inactive (we do not guarantee
          Content Provider or Course availability and we are not liable for any issues relating to Content Provider or
          Course availability);
        </li>
        <li>
          you assume any and all risks from any meetings or contact you have with any Content Providers or other Customers
          or Users; and
        </li>
        <li>
          you are responsible for your account with us and for all uses of the account or the Equipment, with or without
          your knowledge or consent.
        </li>
      </ul>
      <p>
        <strong>
          <tab2>
            4.
          </tab2>
          Confidentiality; proprietary rights
        </strong>
      </p>
      <p>
        <strong>
          GO confidentially
        </strong>
      </p>
      <li>
        <tab>
          4.1
        </tab>
        Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may
        disclose business, technical or financial information relating to the Disclosing Party’s business (hereinafter
        referred to as “Proprietary Information” of the Disclosing Party). Proprietary Information of Go1 includes
        non-public information regarding features, functionality and performance of the Services. Your Proprietary
        Information includes Customer Data. The Receiving Party agrees: (i) to take reasonable precautions to protect such
        Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted in
        these Customer Terms) or divulge to any third person any such Proprietary Information. The Disclosing Party agrees
        that the foregoing will not apply with respect to any information after five (5) years following the disclosure of
        the Proprietary Information or any information that the Receiving Party can document (a) is or becomes generally
        available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party,
        or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed
        without use of any Proprietary Information of the Disclosing Party, or (e) is expressly excluded by these Customer
        Terms, or (f) is required to be disclosed by law (in which case, the Receiving Party must not disclose any
        Proprietary Information until the Disclosing Party has a reasonable opportunity to take such action as it
        considers appropriate in the circumstances).
      </li>
      <li>
        <tab>
          4.2
        </tab>
        You agree that we, or our licensors, own and retain all right, title and interest in and to (a) the Services
        and Software (including all improvements, enhancements or modifications to the Services and Software), (b) any
        software, applications, inventions or other technology developed in connection with Implementation Services or
        support, (c) the Materials, and (d) all Intellectual Property in or related to any of the foregoing. You are
        not authorised to use any of our Intellectual Property except as is expressly allowed under these Terms.
      </li>
      <li>
        <tab>
          4.3
        </tab>
        Unless otherwise expressly provided in these Customer Terms, Content remains the proprietary property of the
        person or entity supplying it (or their affiliated and/or third party providers and suppliers, as is relevant) and
        is protected, without limitation, pursuant to Australian and foreign copyright and other intellectual property
        laws.
      </li>
      <li>
        <tab>
          4.4
        </tab>
        Go1 has not agreed to and does not agree to treat as confidential any suggestion or idea provided by you or any
        User (“Feedback”), and nothing in these Customer Terms or in the parties’ dealings arising out of or related to
        these Customer Terms will restrict Go1’s right to use, profit from, disclose, publish, or otherwise exploit any
        Feedback, without compensation to you or any User providing Feedback.
      </li>
      <li>
        <tab>
          4.5
        </tab>
        You grant us the right to use the Customer Content, in accordance with clause 4.5 to 4.8, inclusive, and for the
        purpose of and as necessary for the delivery by us of the Services. You represent and warrant that you have all
        licenses, rights, consents and permissions necessary to grant such right and that we will not need to obtain any
        licenses, rights, consents or permissions from, or make any payments to, you or any third party, or have any
        liability to you or any other party as a result of any use of the Customer Content.
      </li>
      <li>
        <tab>
          4.6
        </tab>
        We grant you a limited, non-exclusive, non-transferable license for the Term to access and use the Customer
        Content and the Go1 Content (after the required Fees have been paid), solely for the purpose of the education or
        training of the User(s), through the Services, in accordance with these Customer Terms and any conditions or
        restrictions associated with particular Courses or Services. All other uses are prohibited without our express
        written consent.
      </li>
      <li>
        <tab>
          4.7
        </tab>
        You must not reproduce, redistribute, transmit, assign, sell, broadcast, rent, share, lend, modify, adapt, edit,
        create derivative works of, license, or otherwise transfer or use any Go1 Content, in whole or in part, other than
        as expressly authorised under these Customer Terms or as separately authorised by us in writing.
      </li>
      <li>
        <tab>
          4.8
        </tab>
        You must not accept licensed rights to Content from Content Providers and any such direct license is a material
        breach of these Customer Terms.
      </li>
      <li>
        <tab>
          4.9
        </tab>
        If you are an employer or if you engage contractors (for the purpose of this clause 4.8 your employee(s) and
        contractor(s) are ‘employed User(s)’), you agree that we may, in accordance with our Privacy Policy: (a) retain
        and use personal information (as that phrase is defined in the Privacy Policy) of employed User(s), including,
        without limitation, name, address, contact details and Courses undertaken or scheduled; and (b) contact, deal
        directly with and offer Courses to employed User(s), including after the employed User(s) have ceased employment
        or engagement with you. You agree and warrant that your employed Users have read and agreed to the terms of the
        Privacy Policy.
      </li>
      <li>
        <tab>
          4.10
        </tab>
        Notwithstanding the foregoing, we reserve the right to revoke the license to access and use the Content granted to
        you as set out in these Customer Terms.
      </li>
      <li>
        <tab>
          4.11
        </tab>
        Notwithstanding anything to the contrary, and subject to the Privacy Policy, Go1 has the right to collect and
        analyse data and other information relating to the provision, use and performance of various aspects of the
        Services and related systems and technologies (including information concerning Customer Data and data derived
        therefrom), and we will be free (during and after the Term) to (i) use such information and data to improve and
        enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services
        and other Go1 offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection
        with our business. No rights or licenses are granted except as expressly set forth in these Customer Terms.
      </li>
      <li>
        <tab>
          4.12
        </tab>
        This clause shall only apply if and to the extent that the EU General Data Protection Regulation 2016/679 (“GDPR“)
        applies to any of the data with which you use the Services. If this clause applies, the provisions of Appendix 1
        (Data Protection Compliance) and Appendix 2 (Data Processing Agreement) shall apply.
      </li>
      <li>
        <tab>
          4.13
        </tab>
        Go1 does not screen the Customer Content and all use of the Customer Content by you is at your own risk. We have
        no responsibility or liability for such use. In particular, no review or posting or appearance of the Customer
        Content on the Services or though the Services is intended to act as an endorsement or representation that any
        Customer Content is free of violation of any copyright, privacy or other laws or will suit a particular purpose or
        be accurate or useful.
      </li>
      <li>
        <tab>
          4.14
        </tab>
        If you believe that the Customer Content violates any law, or is inaccurate or poses any risk whatsoever to a
        third party, it is your responsibility to take such steps that you deem necessary to correct the situation. If you
        believe that any Customer Content, originating from a third party, or any Go1 Content violates any laws, including
        any copyright laws, you must report such belief to us.
      </li>
      <p>
        <strong>
          <tab2>
            5.
          </tab2>
          Pricing and payment of fees
        </strong>
      </p>
      <p>
        <strong>GO $figure</strong>
      </p>
      <li>
        <tab>
          5.1
        </tab>
        You must pay us the then applicable fees described in the Order Form or Subscription Agreement or through the
        online subscription or order process for the Services (the “License Fees”), including for the Implementation
        Services (the “Implementation Fees”) in accordance with the terms in the Order Form or Subscription Agreement or
        as stipulated in the online subscription or order process. If your use of the Services exceeds the Service
        Capacity set forth in the Subscription Agreement or Order Form or online subscription or order process, or you
        require access or use of the Services for additional instance(s) or additional User(s) or if your Services needs
        or use constitute a requirement to pay additional fees, you will be billed for such usage and you agree to pay the
        additional fees in the manner required by us. Any such additional access or use of the Services by you will be
        subject to these Customer Terms.
      </li>
      <li>
        <tab>
          5.2
        </tab>
        We reserve the right to change the Implementation Fees and License Fees or applicable charges and to institute new
        charges and Fees at the end of the Initial Service Term, or then current Renewal Term, upon thirty (30) days prior
        notice to you (which may be sent by email). If you believe that we have billed you incorrectly, you must contact
        us no later than 60 days after the closing date on the first billing statement in which the error or problem
        appeared, in order to receive an adjustment or credit. Your inquiries should be directed to our customer support
        department.
      </li>
      <li>
        <tab>
          5.3
        </tab>
        We may choose to bill through an invoice, in which case, full payment for invoices issued in any given month must
        be received by us fourteen (14) days after the issue date of the invoice. Unpaid amounts are subject to a finance
        charge of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is lower, plus all
        expenses of collection and may result in immediate termination of the Services.
      </li>
      <li>
        <tab>
          5.4
        </tab>
        Pricing of Courses is published on the Website or the App and is otherwise available directly from us.
      </li>
      <li>
        <tab>
          5.5
        </tab>
        You agree to pay the Fees, for Courses that you purchase, and authorise Go1 to charge your credit card for such
        Fees. If your credit card is declined, you agree to pay Go1 the Fees within thirty (30) days of notification from
        us, and pay a late payment charge of 1.5% per month, or the maximum permitted by law, whichever is lower (which
        may be charged at our discretion).
      </li>
      <li>
        <tab>
          5.6
        </tab>
        If you are a Customer in the European Union, by purchasing the Course(s), you are confirming that you want the
        Course(s) immediately credited to your Go1 account and that, by doing so, you waive any and all cancellation
        rights under applicable laws.
      </li>
      <p>
        <strong>
          <tab2>
            6.
          </tab2>
            GST
        </strong>
      </p>
      <p>
        <strong>GST GO getter</strong>
      </p>
      <li>
        <tab>
          6.1
        </tab>
        Unless otherwise specified, all Fees are exclusive of GST and must be calculated without regard to GST.
      </li>
      <li>
        <tab>
          6.2
        </tab>
        If a supply made under these Customer Terms or a Contract is a taxable supply or subject to GST, the recipient
        of that taxable supply (Recipient) must, in addition to any other consideration, pay to the party making the taxable
        supply (Supplier) the amount of GST in respect of the supply within 14 (fourteen) days of the issue date of the invoice.
        The Recipient will only be required to pay an amount of GST to the Supplier if and when the Supplier provides a valid tax
        invoice to the Recipient in respect of the taxable supply.
      </li>
      <li>
        <tab>
          6.3
        </tab>
        If there is an adjustment to a taxable supply made under these Customer Terms or a Contract then the Supplier must provide
        an adjustment note to the Recipient or otherwise account for the GST adjustment in accordance with GST Law. The amount of
        a party’s entitlement under these Customer Terms or a Contract to recovery or compensation for any of its costs, expenses
        or liabilities is reduced by the input tax credits, or equivalent under the GST Law, to which that party is entitled to recover
        in respect of those costs, expenses or liabilities.
      </li>
      <li>
        <tab>
          6.4
        </tab>
        European Union: In the event that the sale or delivery of any Course(s) to any Customer in the European Union is subject to
        any value added tax (VAT), under applicable law, we will collect and remit the VAT to the competent tax authority for sales
        of such Course(s) to Customers in the European Union. We may, at our own discretion, increase the sale price of such Course(s)
        where we are of the view that VAT may be due and we will have a liability to account for the VAT. Such Customer in the European
        Union will indemnify us and hold us harmless against any and all claims by any tax authority for any underpayment of VAT and any
        associated penalties and/or interest.
      </li>
      <li>
        <tab>
          6.5
        </tab>
        All Other Countries: For sales of any Course(s) in countries other than the European Union, the Customer is responsible for
        remitting the taxes to the appropriate taxing authority (which may be different to the taxing authority in the Customer’s resident
        location).
      </li>
      <li>
        <tab>
          6.6
        </tab>
        The parties shall otherwise comply with the GST Law. We do not provide you with tax advice and you should consult your own tax advisor.
      </li>
      <p>
        <strong>
          <tab2>
            7.
          </tab2>
          Term and termination
        </strong>
      </p>
      <p>
        <strong>Come & GO</strong>
      </p>
      <li>
        <tab>
          7.1
        </tab>
        Subject to earlier termination, as provided below, the Contract is for the Initial Service Term, and will be
        automatically renewed for additional period(s) of the same duration as the Initial Service Term (“Renewal Term”)
        (together, the “Term”), unless either party with at least thirty (30) days notice to terminate the Contract,
        such notice not to expire at any time other than the end of a Renewal Term, in which case the Contract shall end
        at the end of the relevant Renewal Term.
      </li>
      <li>
        <tab>
          7.2
        </tab>
        In addition to any other remedies a party may have, either we or you may also terminate the Contract upon thirty (30)
        days’ notice if the other party materially breaches any of these Customer Terms and that party fails to remedy the breach
        within 14 days of that party being notified in writing to do so.  We may terminate the Contract immediately and without
        notice in the case of non-payment by you. Either party may terminate the Contract immediately if the other party ceases to
        operate, declares bankruptcy, or becomes insolvent or otherwise unable to meet its financial obligations. Notwithstanding
        the foregoing, you must pay at least the amount due for the Services up to and including the last day of the Initial Service
        Term. Upon any termination, we may, but are not obligated to (unless required by applicable laws, including Privacy Laws),
        delete stored Customer Data. All parts of these Customer Terms, which by their nature should survive termination, will survive
        termination, including accrued rights to payment, confidentiality obligations, warranty disclaimers and limitations of liability.
      </li>
      <li>
        <tab>
          7.3
        </tab>
        Except where an exclusive remedy may be specified in these Customer Terms, the exercise by either party of any remedy, including
        termination, will be without prejudice to any other remedies it may have under these Customer Terms, by law, or otherwise.
      </li>
      <li>
        <tab>
          7.4
        </tab>
        Except as set forth in this clause, once the Contract terminates, then: (i) the rights and licenses granted by Go1 to you will cease
        immediately (except as set forth in this clause); (ii) you (and your Users) must cease all use of the Services and any Materials; (iii)
        you must pay to us any and all outstanding Fees for the Term;  (iv) you are required to delete the Services and any Materials made available
        to you under the Contract, including any Go1 Confidential Information from your systems as applicable (including any third party systems
        operated on your behalf) and, if requested by us, provide written certification to us that you have done so at our request; and (v) you
        undertake not to attempt to access the Services or any data stored in the Service (except in accordance with our Privacy Policy), any
        Materials, the Website or the App after the date of termination.
      </li>
      <li>
        <tab>
          7.5
        </tab>
        If we become aware of a breach by you of these Terms, then we may specifically request that you suspend the applicable User account.
        If you fail to comply with our request to suspend an account, then we may do so. The duration of any suspension by us will be until
        the applicable User has cured the breach which caused the suspension.
      </li>
      <p>
        <strong>
          <tab2>
            8.
          </tab2>
          Information, general exclusions and operation of laws
        </strong>
      </p>
      <p>
        <strong>
          GO all in
        </strong>
      </p>

      <li>
        <tab>
          8.1
        </tab>
        Whilst all Materials and other information communicated to you via Content Providers or our employees,
        contractors, agents or representatives are provided in good faith and are believed to be accurate and current as
        at the date of publication, presentation or communication, we provide no warranty or guarantee that any Materials,
        information on linked websites or information otherwise communicated to you will be accurate or complete. In
        addition, all such material is not intended as professional advice and must not be relied upon as such.
      </li>
      <li>
        <tab>
          8.2
        </tab>
        Prior to entering into the Contract or other transaction or taking any particular course of action in connection
        with the Website or the App or Services, you should make your own enquiries and seek independent advice tailored to your
        specific circumstances, needs and objectives.
      </li>
      <li>
        <tab>
          8.3
        </tab>
        The Services are a web based marketplace for Content Providers and Customers. Go1 does not hire or employ Content
        Providers.
      </li>
      <li>
        <tab>
          8.4
        </tab>
        We are not responsible or liable for any interactions between any Content Providers and Customers who purchase or use a Content Provider’s
        Course via the Services. We are not responsible for disputes, claims, losses, injuries or damages of any kind that might arise out of or
        relate to conduct of Content Providers or Customers, including any Customer’s reliance upon any information provided by a Content Provider.
      </li>
      <li>
        <tab>
          8.5
        </tab>
        You also understand that, by using the Services, you may be exposed to content that you consider objectionable. We have no responsibility
        to keep such content from you and no liability for your access or use of any Course or content, to the extent permissible under applicable law.
      </li>
      <li>
        <tab>
          8.6
        </tab>
        You acknowledges and agrees that: (a) prior to entering into the Contract you have been given a reasonable opportunity to examine and satisfy
        yourself regarding all goods and services which are the subject of the Contract and that, prior to entering into the Contract, you availed
        yourself of that opportunity; (b) at no time prior to entering into the Contract have you relied on the skill or judgment of Go1 or any of
        our employees, contractors, agents or representatives and that it would be unreasonable for you to rely on any such skill or judgment; and
        (c) where any acquisition of goods under the Contract has been made by reference to a sample or demonstration model, prior to entering into
        the Contract, you have been given a reasonable opportunity to: (i) satisfy yourself that the goods correspond with the sample or demonstration
        model as to quality, state and condition; and (ii) examine the sample or demonstration model for any apparent defects, and that you have availed
        yourself of that opportunity.
      </li>
      <li>
        <tab>
          8.7
        </tab>
        We understand and you acknowledge and agree that the Services and any other goods or services supplied by us under the Contract are not of a kind
        ordinarily acquired for personal, domestic or household use or consumption and that you do not intend such use or consumption. If, at any time, this
        becomes not the case, you must notify us; you are welcome to discuss and raise any issues of concern you have with these Customer Terms
      </li>
      <li>
        <tab>
          8.8
        </tab>
        To the maximum extent permitted by law:
        <ul>
          <li>
            We make no guarantee as to the usefulness of the Services or Materials, nor any of the products and services promoted on the Website or App or
            via linked websites or communicated to you by us. In particular, we make no guarantee as to the appropriateness of the Courses or Materials provided
            to you by us or our employees, contractors, agents or representatives.
          </li>
          <li>
            We make no warranty or representation (express or implied) regarding the quality, accuracy, reliability, currency, performance, completeness or
            fitness for purpose of any part of the Services, the Website, the App, the Materials and other information communicated to you by us or our employees,
            contractors, agents or representatives.
          </li>
          <li>
            We do not warrant that the Website, the App or the Services will be uninterrupted; nor do we make any warranty as to the results that may be obtained
            from use of the Services. We do not represent or warrant (expressly or impliedly) that the Website, the Materials or the facilities that make the Website
            and the Go1 Content available, will not cause damage, or are free from any computer virus or any other defects or errors.
          </li>
          <li>
            We are not liable (whether in contract, negligence or otherwise) for any loss or damage arising from the use of the Website, the App, the Materials or the Services.
          </li>
          <li>
            Our liability for breach of any implied warranty or conditions that cannot be excluded is restricted, at Go1’s option, to:
          </li>
          <ul>
            <li>the re-supply of the relevant goods or services;</li>
            <li>the cost of the re-supply of the relevant goods or services; or</li>
            <li>any amount paid by you to Go1 in respect of the relevant goods or services.</li>
          </ul>
          <li>
            You agree that, in no circumstances, will we be liable for any indirect, incidental, special and/or consequential losses or damages of whatever nature
            arising out of your use of or reliance on the Services, the Materials or the Website, or the App including losses or damages arising out of, or attributable
            to, an act or omission by us for:
          </li>
          <ul>
            <li>Loss of profits;</li>
            <li>Loss of sales or business;</li>
            <li>Loss of agreements or contracts;</li>
            <li>Loss of anticipated savings;</li>
            <li>Loss of use or corruption of software, data or information;</li>
            <li>Loss of or damage to goodwill; and</li>
            <li>Any indirect or consequential loss.</li>
          </ul>
          <li>
            Notwithstanding the above, to the maximum extent permitted by law, in no event shall our aggregate liability for any claims arising out of or related to
            these customer terms or the contract exceed the amount that you paid, if any, to us for access or use of the services during the twelve (12) months immediately
            prior to the event giving rise to such liability.
          </li>
          <li>
            You agree to indemnify Go1 and its affiliates, officers, agents and employees (indemnified parties) in respect of any claim, action, damage, loss, liability,
            cost, charge, expense, outgoing or payment (including legal expenses (on a full indemnity basis), arising from or relating to: (I) your use of the services or
            any materials; (ii) a breach of these customer terms or the contract by you; and (iii) your breach of any applicable law.
          </li>
          <li>
            We do not exclude any rights or remedies available to you under the Australian Consumer Law that cannot be excluded or restricted.
          </li>
          <li>
            Nothing in these Customer Terms shall limit or exclude our liability for death or personal injury caused by negligence, or the negligence of our employees,
            agents or contractors (as applicable), fraud or fraudulent misrepresentation, breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982,
            or any matter in respect of which it would be unlawful for us to exclude or restrict liability.
          </li>
          <li>
            We shall not be in breach of this Contract nor liable for delay in performing, or failure to perform, any of our obligations under this Contract if such delay or
            failure results from events, circumstances, or causes beyond our reasonable control (including, without limitation, fire, flood, acts of God, interruption or failure
            of utility or telecommunications service, government actions, labour disputes or other similar events). In such circumstances, we shall be entitled to a reasonable
            extension of time for performing such obligations. If the period of delay or non-performance continues for 4 weeks, either we or you may terminate the Contract by
            giving 7 days’ written notice.
          </li>
        </ul>
      </li>
      <p>
        <strong>
          <tab2>
            9.
          </tab2>
          Links to other websites
        </strong>
      </p>
      <p>
        <strong>
          GO between
        </strong>
      </p>
      <li>
        <tab>
          9.1
        </tab>
        The Website or the App may contain links and pointers to other websites operated by third parties, which are included solely
        for your convenience. Links to third party websites do not constitute endorsement, sponsorship or approval by us
        of the content, policies or practices of those third parties, nor the content available on or for download from
        those third party sites.
      </li>
      <li>
        <tab>
          9.2
        </tab>
        You agree that, by accessing any third party linked website, you do so at entirely your own risk.
      </li>
      <li>
        <tab>
          9.3
        </tab>
        We are not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
        or in connection with, use or reliance on any goods or services available on or through any third party website.
      </li>
      <p>
        <strong>
          <tab2>
            10.
          </tab2>
          Disputes
        </strong>
      </p>
      <p>
        <strong>
          When things GO south
        </strong>
      </p>
      <li>
        <tab>
          10.1
        </tab>
        Any dispute arising in connection with these Customer Terms or the Contract, which cannot be settled by negotiation between the parties or their representatives, will
        be submitted to mediation through the applicable Mediation Method. During such mediation, both parties may be legally represented.
      </li>
      <li>
        <tab>
          10.2
        </tab>
        If parties fail to reach an agreement during mediation, the dispute will be resolved by arbitration in accordance with applicable Arbitration Method. The seat of
        arbitration will be the capital city of the Applicable Jurisdiction. The language of the arbitration will be English. The number of arbitrators will be three, unless agreed otherwise between the parties.
      </li>
      <li>
        <tab>
          10.3
        </tab>
        Prior to referring a matter to mediation pursuant to clause 10.1, the parties will: (a) formally refer the dispute
        to their respective contract managers for consideration; and (b) if the respective contract managers are unable to
        resolve the dispute after 5 days (or such other period as is agreed between the parties) from the date of
        referral, refer the dispute to the respective chief executive officers of each party.
      </li>
      <p>
        <strong>
          <tab2>
            11.
          </tab2>
          Updates to Terms
        </strong>
      </p>
      <p>
        <strong>
          New way to GO
        </strong>
      </p>
      <li>
        <tab>
          11.1
        </tab>
        From time to time, we may update these Customer Terms to clarify our practices or to reflect new or different practices, such as when we add new features, or for other reasons. We reserve the right in our sole discretion to modify
        and/or make changes to these Customer Terms at any time. If we make any material change to these Customer Terms which meaningfully reduces your rights, we will notify you using prominent means, such as by email notice sent to the
        latest email address that we have on record for you, or by posting a notice through our Services or the Website or the App. Modifications will become effective on the day they are posted or otherwise published, unless stated
        otherwise.
      </li>
      <li>
        <tab>
          11.2
        </tab>
        Your continued use of our Services after changes become effective will mean that you accept those updated Terms.  If you object to the updated Terms, you must notify Go1 in writing within fourteen (14) days in order to reach an
        agreement, after which you will be deemed to have accepted those Updated Terms. You must visit the Services and the Website or the App regularly to ensure that you are aware of the latest version of the Customer Terms, as any
        evised Customer Terms will supersede all previous Customer Terms.
      </li>
      <p>
        <strong>
          <tab2>
            12.
          </tab2>
          Entire Agreement
        </strong>
      </p>
      <p>
        <strong>
          Full GO
        </strong>
      </p>
      <p>
        <tab2>
          12.1
        </tab2>
        The Contract is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject
        matter of the Contract. All waivers must be in a writing signed by both parties.
      </p>
      <p>
        <strong>
          <tab2>
            13.
          </tab2>
          Miscellaneous
        </strong>
      </p>
      <p>
        <strong>
          GO formal
        </strong>
      </p>
      <li>
        <tab>
          13.1
        </tab>
        If any provision of these Customer Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Customer Terms will otherwise remain in full force and
        effect and enforceable. The Contract is not assignable, transferable or able to be sub-licensed by you except with our prior written consent. We may transfer and assign any of our rights and obligations under the Contract.
      </li>
      <li>
        <tab>
          13.2
        </tab>
        No relationship of agency, partnership, joint venture, or employment is created as a result of the Contract or these Customer Terms and you do not have any authority of any kind to bind us in any respect whatsoever.
      </li>
      <li>
        <tab>
          13.3
        </tab>
        In any action or proceeding to enforce rights under these Customer Terms or the Contract, the prevailing party will be entitled to recover costs and attorneys’ fees.
      </li>
      <li>
        <tab>
          13.4
        </tab>
        These Customer Terms and the Contract are governed by the laws of the Applicable Jurisdiction and the parties irrevocably submit to the exclusive jurisdiction of the courts of the Applicable Jurisdiction.
      </li>
      <li>
        <tab>
          13.5
        </tab>
        Any notice given by a party under these Customer Terms shall be in writing and in English, be signed by, or on behalf of the party giving it, and be sent to the relevant party’s registered office or place of business, or by
        email at the email address notified by a party to the other. Notices are deemed received if delivered by hand, when left at the registered address; if sent by pre-paid first-class post or next working day delivery service, at
        9.00 am on the second Business Day after posting; if delivered by email, at the time of transmission; or if delivered by commercial courier, on the date and at the time that the courier’s delivery receipt is signed. All references
        to time are to the local time at the place of deemed receipt. The provisions of this clause 13.5 do not apply to notices given in legal proceedings.
      </li>
      <li>
        <tab>
          13.6
        </tab>
        Each of Go1’s affiliates (meaning any entity that directly or indirectly controls, is controlled by, or is under common control with another entity) may enforce any provision of the Contract as if it were Go1. Subject to the
        foregoing, these Customer Terms and the Contract do not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 for any third party to enforce any term of the Contract.
      </li>
      <p>
        <strong>
          <tab2>
            14.
          </tab2>
          Definitions
        </strong>
      </p>
      <p>
        <strong>
          GO for meaning
        </strong>
      </p>
      <p>
        <tab>
          14.1
        </tab>
        <li>
          The following definitions apply:
          <p>
            <strong>App</strong>
&nbsp;
              means any application or interface in which the Go1 Content and Services are accessible.
          </p>
          <p>
            <strong>Applicable Jurisdiction</strong>
            &nbsp;means:
            <br />
            1. England and Wales if the Services are supplied in any country within the United Kingdom or Europe;
            <br />
            2. the State of Delaware in the United States of America (US) if the Services are supplied in the US; or
            <br />
            3. the State of Queensland, Australia if the Services are supplied in any other country.
          </p>
          <p>
            <strong>Arbitration Method </strong>
&nbsp;means:
            <br />
            1. if the Applicable Jurisdiction is England and Wales: through the CEDR.
            <br />
            2. if the Applicable Jurisdiction is the State of Delaware in the United States of America: through the American Arbitration Association.
            <br />
            3. if the Applicable Jurisdiction is Queensland, Australia: through the Australian Centre for International Commercial Arbitration (“ACICA”) in accordance with its rules for arbitration.
          </p>
          <p>
            <strong>Business Day</strong>
&nbsp;
              means a day (other than a Saturday, Sunday or public holiday) when banks in the Applicable Jurisdiction are open for business.
          </p>
          <p>
            <strong>Content</strong>
&nbsp;
              means all software, technology, designs, materials, information, communications, text, graphics, links, electronic art, animations, illustrations, artwork, audio clips, video clips, photos, images, reviews, ideas, and other
              data or copyrightable materials or content, including its selection and arrangements.
          </p>
          <p>
            <strong>Content Provider</strong>
&nbsp;
              means the author, designer, creator or provider of a Course.
          </p>
          <p>
            <strong>Content Provider Agreement</strong>
&nbsp;
              means the terms and conditions, as between Go1 and Content Providers, governing the provision of Courses for the Services, which are available at
            {' '}
            <a href={go1Terms.contentProvider} target="_blank" rel="noopener noreferrer">Content Provider Agreement</a>
              .
          </p>
          <p>
            <strong>Contract</strong>
&nbsp;
              means the agreement between the Customer and Go1 for the supply of the Services, or any other goods or services, such supply being on these Customer Terms.
          </p>
          <p>
            <strong>Course</strong>
&nbsp;
              means a course or other resource for instructing, educating, training, learning and/or tutoring and includes mobile applications and related and/or supporting materials.
          </p>
          <p>
            <strong>Customer</strong>
&nbsp;
              means any person who orders, purchases, applies for, commences, initiates a trial, test or other preliminary use of or otherwise uses Go1’s web based marketplace or learning management facility or Go1 Content, Website,
              the App, any Course, the Materials, the Software or any of the Services, and includes: (a) any representative authorised by such person (whether actual, implied or ostensible) to do any of the foregoing or to accept these
              Customer Terms (in these Customer Terms, the Customer is also referred to as “you” or “your”).
          </p>
          <p>
            <strong>Customer Content</strong>
&nbsp;
              means Content uploaded, transmitted or posted to the Services by a Customer and includes any Customer Data so provided.
          </p>
          <p>
            <strong>Customer Data</strong>
&nbsp;
              non-public data provided by the Customer to Go1 to enable the provision of the Services.
          </p>
          <p>
            <strong>Equipment</strong>
&nbsp;
              includes modems, hardware, servers, software, operating systems, networking, telecommunications, web servers and the like.
          </p>
          <p>
            <strong>Fees</strong>
&nbsp;
              means the Implementation Fees and the License Fees.
          </p>
          <p>
            <strong>Go1</strong>
&nbsp;means:
            <br />
            1. Go1 USA LLC if the Applicable Jurisdiction is the State of Delaware in the United States of America;
            <br />
            2. Go1 UK Learning Limited, registered in England and Wales with company number 11867333 and whose registered office is at 1st Floor West Davidson House, Forbury Square, Reading, Berkshire, RG1 3EU, and whose contact email
            address is as set out in the Subscription Agreement or otherwise as notified to you as part of the Services, if the Applicable Jurisdiction is England and Wales; or
            <br />
            3. Go1 Pty Limited (ABN 91 134 998 020) of Units 4 & 5, 2908 Logan Road, Underwood, Queensland, Australia, 4119 if the Applicable Jurisdiction is the State of Queensland, Australia.
          </p>
          <p>
            <strong>Go1 Content</strong>
&nbsp;
              means Content published or otherwise provided by or through Go1, including through the Website or the App.
          </p>
          <p>
            <strong>GST</strong>
&nbsp;
              means goods and services tax or value added tax or other equivalent indirect tax.
          </p>
          <p>
            <strong>GST Law</strong>
&nbsp;
              has the meaning given in the A New Tax System (Goods and Services Tax) Act 1999 (Cth).
          </p>
          <p>
            <strong>Implementation Fees</strong>
&nbsp;
              has the meaning given to it in clause 5.1.
          </p>
          <p>
            <strong>Implementation Services</strong>
&nbsp;
              means that part of the Services, including any training that Go1 may offer at its discretion, provided by Go1 to enable the Customer to commence to use the Courses.
          </p>
          <p>
            <strong>Initial Service Term</strong>
&nbsp;
              means the initial term, being 12 months from the commencement of the Contract, or other term agreed between Go1 and the Customer, whichever is greater, during which the Services are provided.
          </p>
          <p>
            <strong>Intellectual Property</strong>
&nbsp;
              means all intellectual property rights, including the following rights:
            <br />
            (a) patents, copyright, rights in circuit layouts, designs, trade and service marks (including goodwill in those marks), domain names and trade names and any right to have confidential information kept confidential;
            <br />
            (b) any application or right to apply for registration of any of the rights referred to in paragraph (a); and
            <br />
            (c) all rights of a similar nature to any of the rights in paragraphs (a) and (b) that may subsist anywhere in the world (including Australia),
            {' '}
            <br />
              whether or not such rights are registered or capable of being registered.
          </p>
          <p>
            <strong>License Fees</strong>
&nbsp;
              has the meaning given to it in clause 5.1.
          </p>
          <p>
            <strong>Materials</strong>
&nbsp;
              means documents, information, materials (including marketing materials) and communications, provided or made available by or through Go1, including through the Website, the App and learning management portal, and includes Go1
              Content.
          </p>
          <p>
            <strong>Mediation Method </strong>
&nbsp;means
            <br />
            1. if the Applicable Jurisdiction is England and Wales: through the CEDR in accordance with the CEDR Model Mediation Procedure. Unless otherwise agreed between the parties within 14 days the mediator shall be nominated by CEDR.
            <br />
            2. if the Applicable Jurisdiction is the State of Delaware in the United States of America: through the American Arbitration Association.
            <br />
            3. if the Applicable Jurisdiction is Queensland, Australia: through the Australian Centre for International Commercial Arbitration (“ACICA”) in accordance with its rules for mediation.
          </p>
          <p>
            <strong>Partner Agreement</strong>
&nbsp;
              means the terms and conditions, as between Go1 and providers of distribution and referral services to Go1 (Partners), governing the provision of such services by Partners to Go1, which are available at
            {' '}
            <a href={go1Terms.partnerAgreement} target="_blank" rel="noopener noreferrer">Partner Agreement</a>
              .
          </p>
          <p>
            <strong>Privacy Policy</strong>
&nbsp;
              means GO’s privacy policy as amended from time to time, and available at
            {' '}
            <a href={go1Terms.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              .
          </p>
          <p>
            <strong>Renewal Term</strong>
&nbsp;
              means the term(s), following the Initial Service Term, for the continuing provision of the Services, as defined in clause 7.1.
          </p>
          <p>
            <strong>Service Capacity</strong>
&nbsp;
              means the limit of the Services available to the Customer.
          </p>
          <p>
            <strong>Services</strong>
&nbsp;
              means all services supplied by Go1 to the Customer and/or the User via the Website, the App or other means, including marketing, promoting, facilitating, offering, hosting and providing Courses, providing access to Software
              and access to or licensing of specific Courses and includes the Implementation Services, as may be detailed more specifically at the discretion of Go1 in a Subscription Agreement or Order Form or service and/or support level
              agreement.
          </p>
          <p>
            <strong>Software</strong>
&nbsp;
              means the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services.
          </p>
          <p>
            <strong>Subscription Agreement (also referred to as Order Form)</strong>
&nbsp;
              means a document or subscription or order form that particularises details of the Services, which may include details of the Course(s), Fees, Term, Software and other related matters and which may be in the form of a written
              document or available on line, including on or through the Website or the App.
          </p>
          <p>
            <strong>Term</strong>
&nbsp;
              means the Initial Service Term plus the Renewal Term, as described in clause 7.1.
          </p>
          <p>
            <strong>User</strong>
&nbsp;
              means a person who in any way uses the Website, the App, any Course, the Materials, the Software or any of the Services (a User may also be a Customer).
          </p>
          <p>
            <strong>Website</strong>
&nbsp;
              means Go1’s website at
            {' '}
            <a href="https://www.go1.com/" target="_blank" rel="noopener noreferrer">https://www.go1.com</a>
              .
          </p>
        </li>
        <li>
          Interpretation
          <p>The following rules of interpretation apply unless the contrary intention appears:</p>
          <ul>
            <li>the singular includes the plural and vice versa;</li>
            <li>words that are gender neutral or gender specific include each gender;</li>
            <li>where a word or phrase is given a particular meaning, other parts of speech and grammatical forms of that word or phrase have corresponding meanings;</li>
            <li>the words &apos;such as&apos;, &apos;including&apos;, &apos;particularly&apos; and similar expressions are not used as nor are intended to be interpreted as words of limitation.</li>
            <li>
              a reference to:
              <ul>
                <li>a person includes a natural person, partnership, joint venture, government agency, association, corporation or other body corporate;</li>
                <li>a thing (including a chose in action or other right) includes a part of that thing;</li>
                <li>a party includes its successors and permitted assigns;</li>
                <li>a document (which may be in electronic form) includes all amendments or supplements to that document;</li>
                <li>a clause, term or party is a reference to a clause or term of, or party to these Customer Terms or the Contract;</li>
                <li>
                  a law includes a constitutional provision, treaty, decree, convention, statute, regulation, ordinance, by-law, judgment, rule of common law or equity or a rule of an applicable financial market and is a reference to that
                  law as amended, consolidated or replaced;
                </li>
              </ul>
            </li>
            <li>an agreement on the part of two or more persons binds them jointly and severally; and</li>
            <li>no rule of construction applies to the disadvantage of a party because that party was responsible for the preparation of these Customer Terms.</li>
          </ul>
        </li>
      </p>
    </View>
  );
};
