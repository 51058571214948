export function createAction(type, ...argNames) {
  return (...args) => {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

export function createReducer(initialState, actionHandlers) {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(actionHandlers, action.type)) {
      return actionHandlers[action.type](state, action);
    }
    return state;
  };
}
