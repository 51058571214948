import axios from 'axios';
import { REACT_APP_API_URL } from '../config';

/* istanbul ignore next */
export default function onboardUser({
  user = {},
  portal,
  customer,
  creation_path,
  jwt,
  partner_portal_id,
}) {
  const baseUrl = REACT_APP_API_URL || '';
  const config = {};
  if (jwt) {
    config.headers = { Authorization: `Bearer ${jwt}` };
  }

  return axios.post(
    `${baseUrl}/onboard2/portal?x-expensive=1`,
    {
      portal,
      customer,
      creation_path,
      ...(partner_portal_id && { partner_portal_id }),
      user: {
        ...user,
        consent: {
          ...user.consent,
          term_privacy: true,
          term_tos: true,
          term_collection: true,
        },
      },
    },
    config,
  );
}
