const regionMap = {
  AU: 'AU',
  MY: 'MY',
  US: 'US',
  GB: 'UK',
  AX: 'EU',
  AL: 'EU',
  AD: 'EU',
  AM: 'EU',
  AT: 'EU',
  BY: 'EU',
  BE: 'EU',
  BA: 'EU',
  BG: 'EU',
  HR: 'EU',
  CY: 'EU',
  CZ: 'EU',
  DK: 'EU',
  EE: 'EU',
  FI: 'EU',
  FR: 'EU',
  GE: 'EU',
  DE: 'EU',
  GR: 'EU',
  VA: 'EU',
  HU: 'EU',
  IE: 'EU',
  IT: 'EU',
  LV: 'EU',
  LT: 'EU',
  LU: 'EU',
  MK: 'EU',
  MT: 'EU',
  ME: 'EU',
  NL: 'EU',
  NO: 'EU',
  PL: 'EU',
  PT: 'EU',
  RO: 'EU',
  RU: 'EU',
  CH: 'EU',
  SK: 'EU',
  SI: 'EU',
  ES: 'EU',
  SE: 'EU',
};

export default regionMap;
