import React from 'react';
import {
  Text,
  View,
} from '@go1d/go1d';
import LogoHeader from '../LogoHeader';


const LogoContainer = ({
  children,
  title,
  description,
  logo,
  errorBannerLayout,
  ...props
}) => (
  <View
    boxShadow="soft"
    backgroundColor="background"
    marginTop={[8, 8, 9]}
    paddingX={[5, 6]}
    paddingBottom={6}
    borderRadius={2}
    {...props}
  >
    <LogoHeader logo={logo} />
    {title && (
      <Text element="h1" fontSize={4} fontWeight="semibold" textAlign="center" marginBottom={4}>
        {title}
      </Text>
    )}
    {description && (
      <View textAlign="center" marginY={3} marginBottom={errorBannerLayout ? 5 : 7}>
        <Text color="subtle" element="h2">
          {description}
        </Text>
      </View>
    )}
    {children}
  </View>
);

export default LogoContainer;
