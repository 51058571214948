import { createReducer } from '../../utils';
import { ERROR } from '../../actions/error';

export const actionHandlers = {
  [ERROR.STORE_VALUES]: (state, action) => Object.assign({}, state, {
    ...action.payload,
  }),
};

export default createReducer({}, actionHandlers);
