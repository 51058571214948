import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import jwtDecode from 'jwt-decode';
import {
  ButtonMinimal,
  ButtonFilled,
  Container,
  TagToggle,
  Text,
  View,
} from '@go1d/go1d';
import IconChevronRight from '@go1d/go1d/build/components/Icons/ChevronRight';
import { memoize } from 'lodash';
import getNested from '../../utils/getNested';
import {
  getInterestedData,
  updateInterestedTeams,
  getPredefinedDimension,
  DIMENSION_TYPES,
} from '../../services/recommender';
import { setCredentials } from '../../utils';
import { REACT_APP_BASE_DIRECTORY } from '../../config';
import LogoContainer from '../../components/LogoContainer';

export default class WhosLearning extends React.Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    storedFormValues: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatchStoreValues: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any),
  };

  state = {
    teamList: [],
    pickedList: [],
    hasPickedTeam: false,
  };

  pickTeam = memoize(team => () => {
    const { pickedList } = this.state;
    const index = pickedList.indexOf(team);
    if (index >= 0) {
      pickedList.splice(index, 1);
    } else {
      pickedList.push(team);
    }
    const hasPickedTeam = pickedList.length > 0;
    this.setState({ pickedList, hasPickedTeam });
  });

  componentDidMount() {
    const {
      dispatchStoreValues,
      storedFormValues,
      history,
      location,
    } = this.props;
    const hrefQuery = location && location.search ? QueryString.parse(location.search) : null;
    let currentUser = {};
    let currentPortal = {};
    let accounts = [];

    if (hrefQuery && hrefQuery.userId && hrefQuery.portalId) {
      try {
        const jwtDecoded = jwtDecode(hrefQuery.userJwt);
        accounts = getNested(['object', 'content', 'accounts'], jwtDecoded);
      } catch (err) {
        console.log('Error: ', err);
      }

      currentUser = {
        accounts,
        id: hrefQuery.userId,
        uuid: hrefQuery.userUuid,
        jwt: hrefQuery.userJwt,
      };
      currentPortal = { id: hrefQuery.portalId, title: hrefQuery.portalTitle };
    } else if (storedFormValues && storedFormValues.user && storedFormValues.portal) {
      currentUser = storedFormValues.user;
      currentPortal = storedFormValues.portal;
    } else {
      history.push(`${REACT_APP_BASE_DIRECTORY}`);
      return;
    }

    setCredentials(currentUser.jwt);
    dispatchStoreValues({ user: currentUser, portal: currentPortal });

    getPredefinedDimension(DIMENSION_TYPES.BUSINESS_AREA).then((res) => {
      const teamList = res;
      let pickedList = [];
      getInterestedData(currentPortal.id).then((response) => {
        pickedList = response.data.business_area ? response.data.business_area : [];
      }, () => {}).finally(() => {
        const hasPickedTeam = pickedList && pickedList.length > 0;
        this.setState({ teamList, pickedList, hasPickedTeam });
      });
    });
  }

  skip = () => {
    const { storedFormValues: { portal } } = this.props;
    updateInterestedTeams(portal.id, [])
      .then(() => this.goToWhatToLearn());
  }

  continue = () => {
    const { pickedList } = this.state;
    if (!pickedList || pickedList.length <= 0) {
      return;
    }
    const { storedFormValues: { portal } } = this.props;
    updateInterestedTeams(portal.id, pickedList)
      .then(() => this.goToWhatToLearn());
  }

  goToWhatToLearn() {
    const { history, location: { search } } = this.props;
    history.push(`${REACT_APP_BASE_DIRECTORY}/what_to_learn${search}`);
  }

  renderTeams = () => {
    const { teamList, pickedList } = this.state;
    if (!teamList || teamList.length <= 0) {
      return [];
    }
    const teams = [];
    teamList.forEach((team, i) => {
      teams.push(
        <View
          key={i}
          marginRight={4}
          marginBottom={4}
          flexDirection="column"
          flexWrap="wrap"
        >
          <TagToggle
            onChange={this.pickTeam(team)}
            value={pickedList.indexOf(team) >= 0 ? 1 : ''}
          >
            {team}
          </TagToggle>
        </View>,
      );
    });
    return teams;
  }

  render() {
    const { hasPickedTeam } = this.state;
    const rightIconStyle = { display: 'inline', marginLeft: '5px' };
    return (
      <Container paddingX={4} paddingBottom={4} contain="normal" alignItems="center">
        <Container contain="narrow" alignItems="flex-start" marginTop={6} flexDirection="row" justifyContent="center">
          <LogoContainer
            title="Who’s learning"
            description="We’ll help you find the content you need for your company"
          >

            <View>
              <Text color="subtle" marginBottom={6}>
                Do you have any of these teams in your company?
              </Text>
              <View
                flexDirection="row"
                flexWrap="wrap"
                flexGrow={1}
                marginBottom={6}
              >
                {this.renderTeams()}
              </View>
              <View
                flexDirection="row"
                flexWrap="wrap"
                flexGrow={1}
                justifyContent="flex-end"
              >
                <ButtonMinimal size="lg" marginRight={4} onClick={this.skip}>Skip</ButtonMinimal>
                <ButtonFilled size="lg" color="accent" onClick={this.continue} disabled={!hasPickedTeam}>
                  Continue
                  <IconChevronRight style={rightIconStyle} />
                </ButtonFilled>
              </View>
            </View>
          </LogoContainer>
        </Container>
      </Container>
    );
  }
}
