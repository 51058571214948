import { createReducer } from '../../utils';
import { ONBOARD } from '../../actions/onboard';

const initialState = {};

export const actionHandlers = {
  [ONBOARD.STORE_VALUES]: (state, action) => Object.assign({}, state, {
    ...action.payload,
  }),
};

export default createReducer(initialState, actionHandlers);
