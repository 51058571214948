import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  foundations,
  Form,
  Banner,
  Link,
  Modal,
  Select,
  SubmitButton,
  SlatMini,
  Text,
  View,
  EmptyState,
} from '@go1d/go1d';

import IconEdit from '@go1d/go1d/build/components/Icons/Edit';
import IconCross from '@go1d/go1d/build/components/Icons/Cross';

import {
  SubscriptionFormatPrice,
  SubscriptionGetPercentageAmount,
  SubscriptionGetTaxPercent,
  PackageLicenseType,
  PackagePurchaseLevel,
} from '@go1d/go1d-exchange';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import LogoHeader from '../../components/LogoHeader';
import PaymentForm from '../Payment/components/PaymentForm';
import PowerByStripe from './components/PowerByStripe';
import EditSubscriptionPlan from './components/EditSubscriptionPlan';
import Coupon from './components/Coupon';
import * as subscriptionService from '../../services/subscription';
import { findAccount, getStoredJWT, redirectToPortalWithOTT } from '../../services/user';
import onboardPortal from '../../utils/onboard';
import { getFixedRenewalDates } from '../../utils/package';
import {
  userRequest, portalRequest, customerRequest, validateRequest,
} from '../../services/requestModels';
import { REACT_APP_BASE_DIRECTORY } from '../../config';
import track from '../../services/houston';

class PurchaseSubscription extends React.Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    storedPortalValues: PropTypes.objectOf(PropTypes.any),
    storedPackageValues: PropTypes.objectOf(PropTypes.any),
    storedFormValues: PropTypes.objectOf(PropTypes.any).isRequired,
    storedOnboardValues: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatchRemovePackage: PropTypes.func.isRequired,
    dispatchSelectPackagePlan: PropTypes.func.isRequired,
    dispatchUpdatePackage: PropTypes.func.isRequired,
    dispatchErrorValues: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.paymentFormComponent = React.createRef();
    this.state = {
      subscription: null,
      isModalOpened: false,
      genericError: null,
      coupons: {},
    };
  }

  onClickEditSubscription = (subscription) => {
    track(
      'purchase-subscription',
      {
        id: 'edit-subscription',
        package_id: subscription.id,
      },
    );
    this.setState({ subscription });
    this.toggleSubscriptionModal(true);
  }

  onUpdateSubscription = (subscription) => {
    const { dispatchUpdatePackage } = this.props;
    dispatchUpdatePackage(subscription);
    this.setState({ subscription });
  }

  onRemoveSubscription = (subscription) => {
    const { dispatchRemovePackage } = this.props;
    dispatchRemovePackage(subscription.id);
    this.toggleSubscriptionModal(false);
  }

  getBottomMeta = (selectedPack, selectedPlanId = 'year') => {
    const { storedPortalValues } = this.props;
    const { product = {}, stripePlan, entity } = selectedPack;
    const selectedPlan = stripePlan.find(plan => plan.interval === selectedPlanId);
    const { currency, trialPeriodDays = 0 } = selectedPlan;
    const price = SubscriptionFormatPrice(currency, selectedPlan.amount * selectedPack.seats, 0);

    const isLearnerSignupFlow = storedPortalValues.enableLearnerSignupFlow;
    const secondRow = [
      price !== '' ? `${price} ${selectedPlan.interval}ly` : 'Free',
      !isLearnerSignupFlow ? (selectedPack.seats && selectedPack.seats >= 1 ? `${selectedPack.seats} seats` : '1 seat') : '',
    ];

    if (entity.licenseType === PackageLicenseType.PER_PORTAL) {
      delete secondRow[1];
    }
    const thirdRow = [];
    if (entity && entity.raw && entity.raw.subscription_renewal_date && selectedPlan.interval === 'year') {
      thirdRow.push('Fixed term');
      thirdRow.push(getFixedRenewalDates(entity.raw.subscription_renewal_date));
    } else {
      secondRow.push(this.getStartingDate(selectedPack, trialPeriodDays));
    }

    return [
      [
        `${product.contentCount} resources`,
        product.portalName,
        trialPeriodDays > 0 ? `${trialPeriodDays} days trial` : '',
      ].filter(item => !!item),
      secondRow.filter(item => !!item),
      thirdRow.filter(item => !!item),
    ];
  }

  getPaymentFormInstance = () => (this.paymentFormComponent.current);

  onChange = ({ values }) => {
    const paymentFormInstance = this.getPaymentFormInstance();
    paymentFormInstance.onChange({ values });
  }

  onValidate = (values) => {
    const paymentFormInstance = this.getPaymentFormInstance();
    return paymentFormInstance.onValidate(values);
  }

  redirectBack = () => {
    const { history } = this.props;
    const windowSearchQuery = get(window, 'location.search', '');
    return history.push(`${REACT_APP_BASE_DIRECTORY}/choose_learning${windowSearchQuery}`);
  };

  onSubmit = async (values, actions) => {
    const {
      history,
      storedFormValues,
      storedOnboardValues = {},
      dispatchErrorValues,
      storedPortalValues,
      storedPackageValues: { selectedPackageIds = [], selectedPlanId = 'year', packages = [] },
    } = this.props;
    const { setSubmitting } = actions;
    const paymentFormInstance = this.getPaymentFormInstance();
    const userValidate = userRequest(storedFormValues);
    const portalValidate = portalRequest(storedOnboardValues.portal);
    const customerValidate = customerRequest(storedOnboardValues.customer);
    const errors = validateRequest(userValidate, portalValidate, customerValidate);
    const windowSearchQuery = get(window, 'location.search', '');
    if (selectedPackageIds.length === 0) {
      return this.redirectBack();
    }
    if (Object.keys(errors).length > 0) {
      dispatchErrorValues(errors);
      return history.push(`${REACT_APP_BASE_DIRECTORY}/${windowSearchQuery}`);
    }
    setSubmitting(true);
    this.setState({ genericError: null, isSubmitting: true });
    const {
      user = {},
      portal = {},
      customer = {},
      creation_path = '',
    } = storedOnboardValues;
    const jwt = storedOnboardValues && storedOnboardValues.jwt ? storedOnboardValues.jwt : getStoredJWT();

    track(
      'purchase-subscription',
      {
        id: 'submit-purchase-subscription',
        purchased_packages: selectedPackageIds,
      },
    );

    try {
      let cardId = await paymentFormInstance.onSubmit(values);
      const portalId = Number(storedPortalValues.portalId);
      const selectedPackages = packages.filter(pack => selectedPackageIds.indexOf(pack.id) > -1);
      const isLearnerSignupFlow = storedPortalValues.enableLearnerSignupFlow;
      const { coupons } = this.state;

      if (!isLearnerSignupFlow) {
        const plans = selectedPackages.map((pack) => {
          const currentPlan = pack.stripePlan.find(plan => plan.interval === selectedPlanId);
          const data = {
            id: currentPlan.id,
            quantity: pack.seats,
          };
          if (coupons[currentPlan.product_id]) {
            data.coupon = coupons[currentPlan.product_id].code;
          }
          return data;
        });
        const subscriptionCustomer = {
          ...customer,
          billing_interval: selectedPlanId === 'year' ? 'annual' : `${selectedPlanId}ly`,
          stripe_token: cardId,
          purchase_level: PackagePurchaseLevel.Portal,
          plans,
        };
        const contentPackIds = packages.map(pack => Number(pack.loId));
        const onboardData = {
          user,
          portal: { ...portal, content_pack_ids: contentPackIds },
          customer: subscriptionCustomer,
          creation_path,
          jwt,
          partner_portal_id: portalId,
        };
        await onboardPortal(onboardData);
      } else {
        try {
          const subscriptionIds = [];
          for (let i = 0; i < selectedPackages.length; i += 1) {
            const pack = selectedPackages[i];
            const currentPlan = pack.stripePlan.find(plan => plan.interval === selectedPlanId);
            const onboardData = {
              portal_id: portalId,
              plan_id: currentPlan.id,
              purchase_level: PackagePurchaseLevel.Individual,
              quantity: pack.seats,
            };
            if (cardId) {
              onboardData.stripe_card_token = cardId;
            }

            if (coupons[pack.id]) {
              onboardData.coupon = coupons[pack.id].code;
            }
            try {
              // eslint-disable-next-line no-await-in-loop
              const subRes = await subscriptionService.purchaseSubscription(onboardData, jwt);
              const subscriptionId = subRes && subRes.data && subRes.data.result && subRes.data.result.id;
              if (subscriptionId) {
                subscriptionIds.push(subscriptionId);
                cardId = false;// no need for the card token for subsequent purchases
              }
            } catch (err) {
              this.setState({
                genericError: err.message,
                isSubmitting: false,
              });
              return setSubmitting(false);
            }
          }

          if (subscriptionIds && subscriptionIds.length === selectedPackages.length) {
            const targetAccountId = findAccount(user.accounts, portalId);
            if (targetAccountId && jwt) {
              redirectToPortalWithOTT(targetAccountId, jwt).catch(() => {
              });
            } else {
              this.setState({
                genericError: 'Failed to redirect to portal, please try again or contact us for assistance',
                isSubmitting: false,
              });
              return setSubmitting(false);
            }
          } else {
            this.setState({
              genericError: 'Failed to purchase subscriptions, please try again or contact us for assistance',
              isSubmitting: false,
            });
            return setSubmitting(false);
          }
        } catch (err) {
          this.setState({
            genericError: 'Failed to subcribe, please try again or contact us for assistance',
            isSubmitting: false,
          });
          return setSubmitting(false);
        }
      }
    } catch (e) {
      if (e.message === 'account-error') {
        return history.push(`${REACT_APP_BASE_DIRECTORY}/login${windowSearchQuery}`);
      }
      this.setState({
        genericError: 'There is something went wrong, please try again or contact us for assistance',
        isSubmitting: false,
      });
    }
    return setSubmitting(false);
  };

  loginWithAccount = (account, jwt) => redirectToPortalWithOTT(account.id, jwt).catch(() => Promise.reject(new Error('account-error')));

  getStartingDate = (pack, trialPeriodDays) => {
    const { canTrial, stripeSubscription } = pack;
    if (canTrial) {
      const currentPeriodStart = stripeSubscription ? new Date(stripeSubscription.currentPeriodStart) : new Date();
      const todayDate = new Date().getDate();
      currentPeriodStart.setDate(todayDate + trialPeriodDays);
      return `Starting ${currentPeriodStart.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })}`;
    }
    return null;
  }

  toggleSubscriptionModal = (status) => {
    this.setState({ isModalOpened: status });
  }

  onCouponFound = (foundCoupons) => {
    const coupons = {};
    const { storedPackageValues } = this.props;
    const packages = storedPackageValues.packages || [];

    foundCoupons.forEach(coup => {
      packages.some(pack => {
        if (pack.id === coup.product_id) {
          coupons[coup.product_id] = coup;
          return true;
        }
        return false;
      });
    });

    this.setState({ coupons });
  }

  render() {
    const {
      subscription,
      isModalOpened,
      isSubmitting,
      genericError,
      coupons,
    } = this.state;
    const {
      storedPortalValues = {},
      storedPackageValues: {
        packages = [],
        selectedPackageIds = [],
        selectedPlanId = 'year',
      },
      dispatchSelectPackagePlan,
      storedOnboardValues,
    } = this.props;
    const {
      logo: portalLogo,
      siteName: portalSiteName,
      siteDomain: portalSiteDomain,
      creditCardLegalDisclaimer,
    } = storedPortalValues;
    const selectedPackages = packages
      .filter(pack => selectedPackageIds.indexOf(pack.id) > -1);
    const planTexts = selectedPackages.length
      ? uniq((selectedPackages[0].stripePlan || []).map(plan => plan.interval))
      : [];
    const availablePlansOptions = planTexts.map(text => ({
      value: text,
      label: `${text.charAt(0).toUpperCase()}${text.slice(1)}ly`,
    }));
    //  In current scope for Kineo, we are assuming all packages have same tax value and currency value
    const tax = selectedPackages.length ? selectedPackages[0].tax : null;
    const { currency = 'aud' } = selectedPackages.length ? selectedPackages[0].stripePlan[0] : {};
    const discount = subscriptionService.getDiscount(selectedPackages, selectedPlanId, coupons);
    const subTotalPrice = subscriptionService.getTotalPrice(selectedPackages, selectedPlanId);
    const taxPercentage = SubscriptionGetTaxPercent(tax);
    const priceByTax = SubscriptionGetPercentageAmount(subTotalPrice - discount, taxPercentage);
    const totalPrice = (subTotalPrice - discount) + priceByTax;

    let canTrialFromStripe = false;
    if (selectedPackages.length) {
      selectedPackages.forEach(selectedPack => {
        if (selectedPack.stripePlan && selectedPack.stripePlan.length) {
          selectedPack.stripePlan.forEach(stripePlan => {
            if (stripePlan.interval === selectedPlanId && stripePlan.trialPeriodDays > 0) {
              canTrialFromStripe = true;
            }
          });
        }
      });
    }
    const submitBtnLabel = canTrialFromStripe ? 'Start trial' : 'Confirm subscription';

    const jwt = storedOnboardValues && storedOnboardValues.jwt ? storedOnboardValues.jwt : getStoredJWT();
    const isLearnerSignupFlow = storedPortalValues.enableLearnerSignupFlow;

    return (
      <View backgroundColor="background" position="relative">
        {/* --- Background for LHS on desktop --- */}
        <View
          backgroundColor="faint"
          position="absolute"
          width={[1, 1, 1 / 2]}
          css={{
            left: 0,
            top: 0,
            [foundations.breakpoints.lg]: {
              height: '100%',
            },
          }}
        />
        {/* --- End background for LHS on desktop --- */}

        <Container contain="wide">
          <View
            height="100vh"
            position="relative"
            css={{
              [foundations.breakpoints.lg]: {
                flexDirection: 'row',
              },
            }}
          >
            <View
              backgroundColor="faint"
              paddingX={5}
              paddingY={7}
              width={[1, 1, 1 / 2]}
              css={{
                [foundations.breakpoints.lg]: {
                  paddingBottom: foundations.spacing[8],
                  paddingTop: foundations.spacing[8],
                  paddingRight: foundations.spacing[8],
                  backgroundColor: 'transparent',
                  height: '100%',
                  overflowY: 'auto',
                },
              }}
            >
              <LogoHeader
                size="sm"
                alignSelf="center"
                marginTop={0}
                marginBottom={0}
                logo={portalLogo}
              />

              <View marginTop={7} alignItems="center">
                <Text color="default" fontSize={3} fontWeight="semibold" marginBottom={4}>
                  Portal
                </Text>

                <View flexDirection="row" color="default" alignItems="center">
                  <View
                    flexShrink={1}
                    flexGrow={1}
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <View flexGrow={1} flexShrink={1} flexBasis="100%">
                      <View flexDirection="row" justifyContent="center" marginBottom={2}>
                        <Text fontWeight="semibold" fontSize={2} lineClamp={2} textAlign="center">
                          {portalSiteName}
                        </Text>
                      </View>

                      <Text color="subtle" fontSize={1} textAlign="center">
                        {portalSiteDomain}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View marginTop={7} alignItems="center">
                <View flexDirection="row" justifyContent="space-between" marginBottom={4}>
                  <View
                    position="relative"
                    css={{
                      top: foundations.spacing[2],
                    }}
                  >
                    <Text color="default" fontWeight="semibold" fontSize={3} textAlign="center">
                      Subscriptions
                    </Text>
                  </View>

                  {availablePlansOptions.length > 1 && (
                    <View flexGrow={1} flexShrink={1} flexBasis="100%" maxWidth={140}>
                      <Select
                        options={availablePlansOptions}
                        defaultValue={selectedPlanId}
                        maxWidth={160}
                        onChange={evt => dispatchSelectPackagePlan(evt.target.value)}
                      />
                    </View>
                  )}
                </View>

                <View>
                  {selectedPackages.length === 0 && (
                    <EmptyState
                      css={{
                        backgroundColor: 'transparent',
                      }}
                      title="No subscription yet"
                      actionText="Select content"
                      action={this.redirectBack}
                    >
                      You don&apos;t have any content pack. To kick-start, click Select content to start adding your content pack.
                    </EmptyState>
                  )}
                  {selectedPackages.length > 0 && selectedPackages.map(selectedPack => (
                    <SlatMini
                      key={`subscription-${Math.random()}`}
                      title={selectedPack.product && selectedPack.product.name}
                      image={selectedPack.product && selectedPack.product.image}
                      bottomMeta={this.getBottomMeta(selectedPack, selectedPlanId)}
                      actionRenderer={() => (
                        <View
                          color="muted"
                          css={{
                            cursor: 'pointer',
                            transform: `translateX(${foundations.spacing[4]}px)`, // `marginRight` not work for negative number

                            '&:hover': {
                              color: foundations.colors.accent,
                            },
                          }}
                          onClick={() => {
                            this.onClickEditSubscription(selectedPack);
                          }}
                        >
                          <IconEdit />
                        </View>
                      )}
                    />
                  ))}
                </View>
              </View>

              {selectedPackages.length > 0 && (
                <React.Fragment>
                  <View
                    flexDirection="row"
                    justifyContent="space-between"
                    marginTop={7}
                    fontSize={2}
                  >
                    <Text>
                      Sub total
                      {taxPercentage && tax.taxIncluded ? `(incl ${tax.name})` : ''}
                    </Text>

                    <Text>
                      {SubscriptionFormatPrice(currency, subTotalPrice)}
                    </Text>
                  </View>

                  {discount > 0 && (
                    <View
                      flexDirection="row"
                      justifyContent="space-between"
                      marginTop={3}
                      fontSize={2}
                    >
                      <Text>
                        Discount
                      </Text>

                      <Text color="success">
                        {`-${SubscriptionFormatPrice(currency, discount)}`}
                      </Text>
                    </View>
                  )}

                  {taxPercentage && !tax.taxIncluded && (
                    <View
                      flexDirection="row"
                      justifyContent="space-between"
                      marginTop={3}
                      fontSize={2}
                    >
                      <Text>
                        {tax.name}
                        {' '}
                        (
                        {tax.percentage}
                        %)
                      </Text>

                      <Text>
                        {SubscriptionFormatPrice(currency, priceByTax)}
                      </Text>
                    </View>
                  )}

                  <View
                    flexDirection="row"
                    justifyContent="space-between"
                    fontSize={2}
                    borderColor="soft"
                    borderBottom={1}
                    marginTop={4}
                    paddingY={4}
                  >
                    <View flexDirection="row">
                      <Text fontWeight="semibold">
                        Total
                      </Text>
                    </View>

                    <Text fontWeight="semibold">
                      {SubscriptionFormatPrice(currency, totalPrice)}
                      {` every ${selectedPlanId}`}
                    </Text>
                  </View>
                  {isLearnerSignupFlow && (
                    <View>
                      <Coupon onCouponFound={this.onCouponFound} packages={selectedPackages} jwt={jwt} interval={selectedPlanId} />
                    </View>
                  )}
                </React.Fragment>
              )}
            </View>

            <View
              color="default"
              paddingX={5}
              paddingY={7}
              width={[1, 1, 1 / 2]}
              css={{
                [foundations.breakpoints.lg]: {
                  paddingBottom: foundations.spacing[9],
                  paddingTop: foundations.spacing[9],
                  paddingLeft: foundations.spacing[8],
                  height: '100%',
                  overflowY: 'auto',
                },
              }}
            >
              <View
                css={{
                  [foundations.breakpoints.lg]: {
                    marginTop: foundations.spacing[7],
                    paddingTop: foundations.spacing[8],
                  },
                }}
              >
                {creditCardLegalDisclaimer && <Banner type="note" marginBottom={6}>{creditCardLegalDisclaimer}</Banner>}
                <View flexDirection="row" justifyContent="space-between" marginBottom={4}>
                  <Text fontSize={3} fontWeight="semibold">
                    Payment method
                  </Text>

                  <View flexShrink={1} flexGrow={1} alignItems="flex-end" color="subtle">
                    <Link href="https://stripe.com/" target="_blank">
                      <PowerByStripe width={119} height={26} />
                    </Link>
                  </View>
                </View>

                <Form
                  validateOnBlur={false}
                  initialValues={{
                    name: '',
                    number: '',
                    expiry: '',
                    cvc: '',
                  }}
                  validate={this.onValidate}
                  onChange={this.onChange}
                  onSubmit={this.onSubmit}
                >
                  <PaymentForm
                    errorMessage={genericError}
                    ref={this.paymentFormComponent}
                  />

                  <View marginTop={6}>
                    <SubmitButton
                      data-testid="submit-button"
                      size="lg"
                      color="accent"
                      width="100%"
                      textAlign="center"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Confirming...' : submitBtnLabel}
                    </SubmitButton>
                  </View>
                </Form>
              </View>
            </View>
          </View>
        </Container>

        <Modal
          headerIcon={IconCross}
          isOpen={isModalOpened}
          title="Manage subscription"
          onRequestClose={() => this.toggleSubscriptionModal(false)}
        >
          <EditSubscriptionPlan
            subscription={subscription}
            selectedPlanId={selectedPlanId}
            onCancel={() => this.toggleSubscriptionModal(false)}
            onRemove={() => this.onRemoveSubscription(subscription)}
            onUpdate={updatedSubscription => this.onUpdateSubscription(updatedSubscription)}
            storedPortalValues={storedPortalValues}
          />
        </Modal>
      </View>
    );
  }
}

export default PurchaseSubscription;
