import { connect } from 'react-redux';
import AppContainer from './App.page';
import { portalActions } from './actions/portal';

/* istanbul ignore next */
const mapStateToProps = state => ({
  storedPortalValues: state.portalValues,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  dispatchPortalValues: (...values) => dispatch(portalActions.storeValues(...values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
