import { connect } from 'react-redux';
import PaymentPage from './page';
import { formActions } from '../../actions/form';
import { errorActions } from '../../actions/error';
/* istanbul ignore next */
const mapStateToProps = state => ({
  storedFormValues: state.formValues,
});
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  dispatchStorePaymentValues: (
    number_of_seats,
    name_on_card,
    payment_intervall,
    credit_card_token,
  ) => dispatch(
    formActions.storePaymentValues(
      number_of_seats,
      name_on_card,
      payment_intervall,
      credit_card_token,
    ),
  ),
  dispatchStoreValues: (...variables) => dispatch(formActions.storeValues(...variables)),
  dispatchErrorValues: (...errors) => dispatch(errorActions.storeValues(...errors)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentPage);
