import React from 'react';
import {
  Banner,
  ButtonFilled,
  Container,
  Field,
  Form,
  SubmitButton,
  Text,
  TextInput,
  View,
} from '@go1d/go1d';
import IconSuccess from '@go1d/go1d/build/components/Icons/Success';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import { resetPassword } from '../../services/user';
import getNested from '../../utils/getNested';
import { REACT_APP_PUBLIC_PORTAL } from '../../config';
import LogoContainer from '../../components/LogoContainer';


export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetSuccess: false,
      errorCode: 0,
      errorMessage: '',
      email: '',
    };
  }

  doResetPassword = (formValues, actions) => {
    const { domain } = this.props;
    let errorCode = 500;
    let errorMessage = (
      <Text>
        Something went terribly wrong, please try again later or contact support at support@go1.com for assistance.
      </Text>
    );
    this.setState({ resetSuccess: false, email: formValues.email });
    const portal_url = domain || REACT_APP_PUBLIC_PORTAL;

    resetPassword(formValues.email, `${portal_url}`).then(
      () => {
        this.setState({ errorCode: 0, errorMessage: '', resetSuccess: true });
        actions.setSubmitting(false);
      },
      (error) => {
        if (error.response) {
          errorCode = getNested(['response', 'status'], error);
          if (errorCode === 404) {
            /* errorMessage = (
              <Text>
                Account not found. Please verify you are using the correct email address.
              </Text>
            ); */
            this.setState({ errorCode: 0, errorMessage: '', resetSuccess: true });
            actions.setSubmitting(false);
            return;
          }
          errorMessage = (<Text>{getNested(['response', 'data', 'message'], error)}</Text>);
        }
        this.setState({ errorCode, errorMessage });
        actions.setSubmitting(false);
      },
    );
  };

  validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'You must enter an email.';
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(values.email)) {
      errors.email = 'Invalid email format.';
    }

    return errors;
  };

  render() {
    const {
      errorCode, errorMessage, resetSuccess, email,
    } = this.state;
    const hideBackBtn = QueryString.parse(window.location.search);
    const { storedFormValues } = this.props;
    const hideBackLoginBtn = hideBackBtn.hideBackBtn === 'true';
    return (

      <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
        <Helmet>
          <title>Go1 Reset password</title>
        </Helmet>
        {resetSuccess && (
          <Container data-testid="successMessageContainer" contain="narrow" alignItems="center" marginTop={6} justifyContent="center">
            <LogoContainer
              title="Sent Successfully!"
              description="Check your inbox for a reset password link"
            >
              <View textAlign="center" marginBottom={4} marginX={4} alignItems="center">
                <IconSuccess size={8} color="success" />
              </View>
              <View textAlign="center" marginY={4} marginX={4}>
                <Text color="subtle">
                  Instructions to reset your password should appear in your inbox
                  for &apos;
                  {email}
&apos; soon.
                  <br />
                </Text>
                <Text color="subtle" marginTop={4}>
                  If no email appears, check your spam folder or
                  {' '}
                  <Text color="subtle" data-testid="resend-link" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.setState({ resetSuccess: false })}>resend link</Text>
.
                </Text>
                <Text color="subtle" marginTop={4}>
                  Please contact your system administrator for further assistance.
                </Text>
              </View>
            </LogoContainer>
            {!hideBackLoginBtn
              && (
                <View
                  width="100%"
                  maxWidth={620}
                >
                  <ButtonFilled
                    element={Link}
                    to="login"
                    width="100%"
                    size="lg"
                    color="background"
                    marginTop={6}
                  >
                    Back to log in
                  </ButtonFilled>
                </View>
              )}

          </Container>

        )}
        {!resetSuccess && (


          <Container data-testid="resetPasswordContainer" contain="narrow" alignItems="center" marginTop={6}>
            <LogoContainer
              title="Reset Password"
              description="Enter the email address associated with your account,
              and we'll email you a link to reset your password."
              errorBannerLayout={errorCode}
            >
              {errorCode > 0 && (
              <Banner type="danger" width="100%" maxWidth={620} marginBottom={6}>
                <Text fontWeight="semibold">Password reset failed</Text>
                {errorMessage}
              </Banner>
              )}
              <Form
                onSubmit={this.doResetPassword}
                validate={this.validate}
                initialValues={{
                  email: storedFormValues && storedFormValues.login_email ? storedFormValues.login_email : '',
                }}
              >
                <View
                  marginBottom={5}
                >
                  <Field
                    component={TextInput}
                    name="email"
                    label="Email"
                    type="email"
                    required
                  />
                </View>
                <SubmitButton
                  justifyContent="center"
                  size="lg"
                  id="submitBtn"
                  css={{
                    width: '100%',
                  }}
                >
                  Send reset link
                </SubmitButton>
              </Form>
            </LogoContainer>
            <React.Fragment>
              {!hideBackLoginBtn
                && (
                <View
                  width="100%"
                  maxWidth={620}
                >
                  <ButtonFilled
                    element={Link}
                    to="login"
                    width="100%"
                    size="lg"
                    color="background"
                    marginTop={6}
                  >
                      Back to log in
                  </ButtonFilled>
                </View>
                )}
            </React.Fragment>
          </Container>

        )}
      </Container>
    );
  }
}

ResetPassword.propTypes = {
  storedFormValues: PropTypes.shape(),
  domain: PropTypes.string,
};
