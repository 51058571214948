import React from 'react';
import { connect } from 'react-redux';
import {
  Route,
  Link as RouterLink,
} from 'react-router-dom';
import {
  Container,
  Select,
  Link,
  Provider,
  Text,
  View,
  foundations,
} from '@go1d/go1d';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { customerRequest, portalRequest, userRequest } from '../../services/requestModels';
import { errorActions } from '../../actions/error';
import { REACT_APP_BASE_DIRECTORY } from '../../config';
import { formActions } from '../../actions/form';
import { PORTAL_PLAN } from '../../actions/portal';
import TextContainer from '../../components/TextContainer';
import PolicyHeader from '../../components/PolicyHeader';
import PolicyFooter from '../../components/PolicyFooter';
import policiesMap from './PolicyArray';
import getPolicyData from '../../services/policy';
import getErrors from '../../services/form';
import track from '../../services/houston';
import { findAccount, redirectToPortalWithOTT, getStoredJWT } from '../../services/user';
import getNested from '../../utils/getNested';
import createUser from '../../utils/user';
import { onboardActions } from '../../actions/onboard';

/* eslint-disable no-use-before-define */
export class PolicyPage extends React.Component {
  constructor(props) {
    super(props);
    const jwt = getStoredJWT();
    const { packageSignupFlowReferer } = QueryString.parse(get(window, 'location.search'));
    const policiesMapState = { ...policiesMap };
    if (packageSignupFlowReferer && jwt) {
      policiesMapState.terms.items = policiesMapState.terms.items.filter(item => item.id !== 'user-terms');
    }
    this.state = {
      policiesMapState,
    };
  }

  componentDidMount() {
    const {
      portal, siteName, storedPortalValues, dispatchStoreValues,
    } = this.props;
    if (portal) {
      ['policy', 'copyright', 'tos', 'training_agreement'].forEach((endpointSuffix) => {
        getPolicyData(portal, endpointSuffix).then((res) => {
          const result = get(res, 'data.value') && !get(res, 'data.default') ? get(res, 'data.value') : null;

          let { policiesMapState } = this.state;
          const maps = {
            'partner-terms': 'tos',
            'partner-copyright': 'copyright',
            'partner-policy': 'policy',
          };

          if (endpointSuffix === 'training_agreement' && result) {
            policiesMapState = {
              ...policiesMapState,
              trianingAgreement: {
                label: 'Online Training Agreement',
                items: [
                  {
                    name: 'Online Training Agreement',
                    id: 'training-agreement',
                    description: null,
                    label: 'Online Training Agreement',
                    dataType: 'dynamic',
                  },
                ],
              },
            };
            maps['training-agreement'] = 'training_agreement';
          }

          const policies = Object
            .keys(policiesMapState)
            .reduce((accumulator, currentValue) => [
              ...policiesMapState[currentValue].items, ...accumulator,
            ], []);

          if (result) {
            /* eslint-disable no-param-reassign */
            policies.forEach((policy) => {
              if (policy.name && policy.name.indexOf('Go1') !== 0 && policy.dataType !== 'dynamic') {
                policy.name = `Go1 ${policy.name}`;
              }
            });
          }

          /* eslint-disable no-param-reassign */
          policies.filter(p => p.dataType === 'dynamic').forEach((policy) => {
            if (maps[policy.id] === endpointSuffix) {
              policy.description = result;
              policy.name = policy.description ? `${siteName} ${policy.label}` : null;
            }
          });
          this.setState({
            policiesMapState,
          });
        });
      });

      if (storedPortalValues.enableLearnerSignupFlow) {
        dispatchStoreValues({ plan: PORTAL_PLAN.REFERRAL_CONTENT_PARTNER });
      }
    }
  }

  onRedirect = (event) => {
    const { history, location } = this.props;
    const redirectLink = location.pathname.split('/').shift();
    history.push(`${redirectLink}${event.target.value}${get(window, 'location.search')}`);
  }

  /**
   * Content sign up flows for portal & individual purchase_level
   */
  onPackageSignupFlowSubmit = async () => {
    const {
      history, storedFormValues, storedPortalValues, dispatchErrorValues, dispatchOnboardValues,
    } = this.props;

    const searchQuery = get(window, 'location.search');
    const {
      type, email, password, first_name, last_name,
    } = storedFormValues;
    const values = {
      type, email, password, first_name, last_name,
    };
    const errors = getErrors(values);
    track('policy', { id: 'agree-policy' });

    if (Object.keys(errors).length > 0) {
      // some field(s) missing, pop errors
      dispatchErrorValues(errors);
      history.push(`${REACT_APP_BASE_DIRECTORY}/${searchQuery}`);
    } else if (storedPortalValues.enableLearnerSignupFlow) {
      // 'individual' purchase_level flow
      const user = userRequest(storedFormValues);
      const ONE_SEAT = 1;
      const portal = portalRequest(storedPortalValues, ONE_SEAT);
      const customer = customerRequest(values);
      // creates a new user
      const registeredUser = await this.registerNewUser();
      if (registeredUser) {
        user.accounts = registeredUser.accounts; // copies the 'accounts' property
        const onboardData = {
          user, portal, customer,
        };
        dispatchOnboardValues({ ...onboardData, creation_path: PORTAL_PLAN.REFERRAL_CONTENT_PARTNER });
        this.navigateToChooseLearningPage();
      }
    } else {
      // default flow
      this.navigateToCreatePortalPage(searchQuery);
    }
  }

  onSignupTOSAgreementSubmit = async () => {
    const { history, storedFormValues, dispatchErrorValues } = this.props;
    const {
      type, email, password, first_name, last_name, plan,
    } = storedFormValues;
    const values = {
      type, email, password, first_name, last_name,
    };
    const errors = getErrors(values);

    if (Object.keys(errors).length > 0) {
      // some field(s) missing, pop errors
      dispatchErrorValues(errors);
      this.setState({ submitting: false });
      history.push(`${REACT_APP_BASE_DIRECTORY}/`);
    } else if (type === 'individual') {
      // creates a new user
      const registeredUser = await this.registerNewUser();
      this.navigateToPortalPage(registeredUser);
    } else if (type === 'author' || plan) {
      // do redirects
      history.push(`${REACT_APP_BASE_DIRECTORY}/create`);
    } else if (type === 'team' || type === 'referral') {
      history.push(`${REACT_APP_BASE_DIRECTORY}/choose`);
    }
  }

  /**
   * Registers new user with basic details and/or custom profile eck fields
   * @return a new user with jwt
   */
  registerNewUser = async () => {
    const {
      history, storedFormValues, dispatchErrorValues, storedPortalValues = {},
    } = this.props;

    try {
      return createUser(storedFormValues, storedPortalValues);
    } catch (err) {
      this.setState({ submitting: false });
      if (err.response) {
        if (err.response.status === 400 || err.response.status === 403) {
          const errorMsg = err.response.status === 403 ? 'User cannot be added to public portal or already exists.' : err.response.data.message;
          dispatchErrorValues({ email: errorMsg });
          history.push(`${REACT_APP_BASE_DIRECTORY}/`);
        }
      }
    }
    return undefined;
  }

  navigateToCreatePortalPage = (searchQuery) => {
    const { history } = this.props;
    if (searchQuery) {
      history.push(`${REACT_APP_BASE_DIRECTORY}/create${searchQuery}`);
    }
  }

  navigateToChooseLearningPage = () => {
    const { history } = this.props;
    history.push(`${REACT_APP_BASE_DIRECTORY}/choose_learning${get(window, 'location.search', '')}`);
  }

  navigateToPortalPage = (user) => {
    if (user) {
      const {
        history, storedFormValues, dispatchErrorValues, storedPortalValues,
      } = this.props;
      const { redirect_url } = storedFormValues;
      const { portalId } = storedPortalValues;
      // find correct account id
      const targetAccountId = findAccount(user.accounts, portalId) || getNested(['accounts', 0, 'id'], user);
      redirectToPortalWithOTT(targetAccountId, user.jwt, redirect_url).catch(() => {
        dispatchErrorValues({ email: 'Signup error' });
        history.push(`${REACT_APP_BASE_DIRECTORY}/`);
      });
    }
  }

  render() {
    const {
      policiesMapState,
      submitting,
    } = this.state;
    const {
      match,
      location,
      portal,
      storedPortalValues,
    } = this.props;
    const isAuthenticated = getStoredJWT();
    const { terms, policies, copyright } = policiesMapState;
    const { contentPackageSignupFlow, enableLearnerSignupFlow } = storedPortalValues;
    const activeLink = location.pathname.split('/').pop();
    const helmet = activeLink.replace('-', ' ').toLowerCase().split(' ').map(s => (s.charAt(0).toUpperCase() + s.substring(1)))
      .join(' ');
    const signupTOSAgreement = get(QueryString.parse(location.search), 'signupTOSAgreement');
    const packageSignupFlowReferer = get(QueryString.parse(location.search), 'packageSignupFlowReferer');
    /**
     * To detect if user (in portal already has config contentPackageSignupFlow = true)
     * came from sign up flow or somewhere else, because Policy page is a public page
     * @type {{packageSignupFlowReferer: boolean}}
     */
    const isPackageSignupFlow = (contentPackageSignupFlow || enableLearnerSignupFlow) && packageSignupFlowReferer;
    const subHeaderTitle = isPackageSignupFlow
      ? 'Please confirm you agree with our terms and policies to continue.'
      : null;

    return (
      <View backgroundColor="background">
        <Helmet>
          <title>
            {helmet}
            {' '}
            ･ Go1
          </title>
        </Helmet>
        <PolicyHeader subTitle={subHeaderTitle} />
        <Provider linkComponent={RouterLink}>
          <Container
            contain="wide"
            minHeight="75vh"
            paddingBottom={isPackageSignupFlow ? 10 : 0}
          >
            <View flexDirection={['column', 'row', 'row']}>
              <View width="100%" display={['flex', 'none', 'none']}>
                <Select
                  value={activeLink}
                  options={[
                    {
                      label: '',
                      optgroup: true,
                      values: terms.items.map(item => (
                        {
                          value: item.id,
                          label: item.name,
                        }
                      )).filter(option => option.label),
                    },
                    {
                      label: '',
                      optgroup: true,
                      values: policies.items.map(item => (
                        {
                          value: item.id,
                          label: item.name,
                        }
                      )).filter(option => option.label),
                    },
                    {
                      label: '',
                      optgroup: true,
                      values: copyright.items.map(item => (
                        {
                          value: item.id,
                          label: item.name,
                        }
                      )).filter(option => option.label),
                    }]}
                  onChange={this.onRedirect}
                  backgroundColor="soft"
                  color="subtle"
                  boxShadow="none"
                  borderRadius="0px"
                  css={{
                    span: {
                      fontSize: foundations.type.scale.md[2],
                      color: foundations.colors.subtle,
                      fontWeight: foundations.type.weight.semibold,
                      borderRadius: foundations.type.scale.md[0],
                    },
                  }}
                />
              </View>
              <View flexBasis={0.3} paddingTop={6} display={['none', 'flex', 'flex']}>
                {Object.keys(policiesMapState).map(key => (
                  <View
                    key={key}
                    paddingTop={7}
                    paddingLeft={6}
                    paddingBottom={2}
                  >
                    <Text fontSize={2} fontWeight="semibold">
                      {policiesMapState[key].label}
                    </Text>
                    {policiesMapState[key].items.map(({ name, id }) => {
                      if (name) {
                        return (
                          <Link
                            key={id}
                            to={`${match.url}/${id}${get(window, 'location.search')}`}
                            hoverFocusColor="default"
                            css={{
                              borderLeft: activeLink === id && '3px solid',
                              borderColor: foundations.colors.faded,
                              paddingLeft: foundations.spacing[4],
                              marginTop: foundations.spacing[4],
                            }}
                          >
                            <Text
                              color={activeLink === id ? 'default' : 'subtle'}
                              fontWeight={activeLink === id ? 'semibold' : 'normal'}
                            >
                              {name}
                            </Text>
                          </Link>
                        );
                      }
                      return null;
                    })}
                  </View>
                ))}
              </View>
              <View flexBasis={0.7}>
                <Route
                  path={`${match.path}/:policyId`}
                  render={props => (<Policy portal={portal} policiesMapProp={policiesMapState} {...props} />)}
                />
              </View>
            </View>
          </Container>
          {(signupTOSAgreement || isPackageSignupFlow) && (
            <View
              backgroundColor="background"
              boxShadow="distant"
              width="100%"
              height="auto"
              position="fixed"
              zIndex={2}
              css={{
                left: 0,
                bottom: 0,
              }}
            >
              <Container contain="wide">
                <PolicyFooter baseUrl={match.url} isAuthenticated={isAuthenticated} disabled={submitting} onAgree={isPackageSignupFlow ? this.onPackageSignupFlowSubmit : this.onSignupTOSAgreementSubmit} />
              </Container>
            </View>
          )}
        </Provider>
      </View>
    );
  }
}

export const Policy = (props) => {
  const { match, policiesMapProp } = props;
  const policies = Object
    .keys(policiesMapProp)
    .reduce((accumulator, currentValue) => [...policiesMapProp[currentValue].items, ...accumulator], []);
  const filteredPolicy = policies.find(({ id }) => id === match.params.policyId);

  if (filteredPolicy) {
    return (
      <View>
        {filteredPolicy.description && filteredPolicy.dataType === 'dynamic' && (
          <TextContainer
            title={filteredPolicy.name}
            userText={filteredPolicy.description}
            paddingY={5}
            marginBottom={6}
          />
        )}
        {filteredPolicy.dataType === 'static' && (
          <TextContainer
            title={filteredPolicy.name.indexOf('Go1') === 0 ? filteredPolicy.name : `Go1 ${filteredPolicy.name}`}
            defaultText={filteredPolicy.description}
          />
        )}
      </View>
    );
  }
  return null;
};

PolicyPage.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  portal: PropTypes.string,
  siteName: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  storedFormValues: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchErrorValues: PropTypes.func.isRequired,
  storedPortalValues: PropTypes.objectOf(PropTypes.any),
};

Policy.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  policiesMapProp: PropTypes.objectOf(PropTypes.any),
};
/* istanbul ignore next */
const mapStateToProps = state => ({
  storedFormValues: state.formValues,
  storedErrorValues: state.errorValues,
  storedPortalValues: state.portalValues,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  dispatchErrorValues: email => dispatch(errorActions.storeValues(email)),
  dispatchStoreValues: (...variables) => dispatch(formActions.storeValues(...variables)),
  dispatchOnboardValues: (...values) => dispatch(onboardActions.storeValues(...values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyPage);
