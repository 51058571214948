import { createReducer } from '../../utils';
import { PORTAL } from '../../actions/portal';

const initialState = {
  contentPackageSignupFlow: false,
  enableLearnerSignupFlow: false,
};

export const actionHandlers = {
  [PORTAL.STORE_VALUES]: (state, action) => Object.assign({}, state, {
    ...action.payload,
  }),
};

export default createReducer(initialState, actionHandlers);
