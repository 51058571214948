import http from '../utils/http';
import { REACT_APP_API_URL } from '../config';

function getPortalConfig(domain) {
  return http
    .get(`${REACT_APP_API_URL}/portal/${domain}`, {})
    .then(res => res.data);
}

export default getPortalConfig;
