import React from 'react';
import AcceptableUsePolicy from '../text/AcceptableUsePolicy';
import Privacy from '../text/Privacy';
import Copyright from '../text/Copyright';
import UserTerms from '../text/UserTerms';
import CustomerTerms from '../text/CustomerTerms';

const policiesMap = {
  terms: {
    label: 'Terms',
    items: [
      {
        name: 'User Terms',
        id: 'user-terms',
        description: <UserTerms />,
        label: 'Terms',
        dataType: 'static',
      },
      {
        name: 'Customer Terms',
        id: 'customer-terms',
        description: <CustomerTerms />,
        label: 'Terms',
        dataType: 'static',
      },
      {
        name: null,
        id: 'partner-terms',
        description: null,
        label: 'User Terms',
        dataType: 'dynamic',
      },
    ],
  },
  policies: {
    label: 'Policies',
    items: [
      {
        name: 'Privacy Policy',
        id: 'privacy-policy',
        description: <Privacy />,
        label: 'Policies',
        dataType: 'static',
      },
      {
        name: 'Acceptable Use Policy',
        id: 'acceptable-use',
        description: <AcceptableUsePolicy />,
        label: 'Policies',
        dataType: 'static',
      },
      {
        name: null,
        id: 'partner-policy',
        description: null,
        label: 'Privacy Policy',
        dataType: 'dynamic',
      },
    ],
  },
  copyright: {
    label: 'Copyright',
    items: [
      {
        name: 'Notice',
        id: 'copyright',
        description: <Copyright />,
        label: 'Copyright',
        dataType: 'static',
      },
      {
        name: null,
        id: 'partner-copyright',
        description: null,
        label: 'Copyright',
        dataType: 'dynamic',
      },
    ],
  },
};

export default policiesMap;
