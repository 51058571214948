import React from 'react';
import { foundations, View } from '@go1d/go1d';

export default () => (
  <View
    css={{
      strong: {
        fontWeight: 'bold',
      },
      p: {
        marginBottom: '.5rem',
        marginTop: '1.25rem',
      },
      li: {
        margin: '.5rem 0',
        marginTop: '1.25rem',
      },
      ul: {
        listStyleType: 'disc',
        paddingLeft: '2rem',
      },
      ol: {
        listStyleType: 'decimal',
        paddingLeft: '2rem',
      },
      h3: {
        color: foundations.colors.accent,
        fontWeight: 'bold',
        margin: '0.5rem 0',
      },
      a: {
        color: foundations.colors.accent,
      },
    }}
  >
    <p>
      Here at Go1, we are passionate about lifelong learning and want to make
      learning accessible to people in all parts of the world and provide those
      people with a digital learning record for life. To do this, we need to keep
      our products and services running smoothly, quickly, and without disruption.
      For this to happen, we need help from you, our Customers and Users. We need
      you to use our products and services in ways that respect the work that has
      gone into developing and offering them and show respect for our Content
      Providers and other Customers and Users.
    </p>
    <p>
      We’ve created this Acceptable Use Policy to describe what we expect of you.
      You’ll see the word “Services” a lot throughout this page. That refers to
      all services supplied by Go1 via the Website (go1.com) or other means,
      including marketing, promoting, facilitating, offering, hosting and providing
      Courses and includes the Implementation Services.
    </p>
    <p>
      Please use sound judgment and let’s respect each other so we can keep offering
      great learning experiences. You can find more legal fine print that applies to
      this Acceptable Use Policy at the bottom of this page.
    </p>
    <p>
      Here is what you must not do
    </p>
    <p>
      <strong>
        Disruption
      </strong>
    </p>
    <ul>
      <li>
        directly or indirectly reverse engineer, decompile, disassemble or
        otherwise attempt to discover the source code, object code or underlying
        structure, ideas, know-how or algorithms relevant to the Services or any
        software, documentation or data related to the Services (“Software”);
      </li>
      <li>
        modify, translate, or create derivative works based on the Services
        or any Software (except to the extent expressly permitted by us in writing
        or authorised within the Services);
      </li>
    </ul>
    <p>
      <strong>
        Using the Services
      </strong>
    </p>
    <ul>
      <li>
        use the Services or any Software for timesharing or service bureau purposes or
        otherwise for the benefit of a third party;
      </li>
      <li>
        remove any proprietary notices or labels from Courses or Materials;
      </li>
      <li>
        frame or embed the Services to circumvent the Services;
      </li>
      <li>
        impersonate another person or gain unauthorised access to another person&apos;s
        account, subscription or portal with us;
      </li>
    </ul>
    <p>
      <strong>
      Dealings with others
      </strong>
    </p>
    <ul>
      <li>
        solicit personal information from any Content Provider or other User;
      </li>
      <li>
        recruit, solicit or contact, in any form, Content Providers or potential users
        for employment or contracting, for a business not affiliated with us, without our
        advance written permission (which we may be withheld in our sole discretion);
      </li>
      <li>
        where you do have any direct dealings with a Content Provider, breach the Content
        Provider’s policies, terms and conditions, including those concerning your personal
        information;
      </li>
    </ul>
    <p>
      <strong>
        Inappropriate communications
      </strong>
    </p>
    <ul>
      <li>
        introduce any virus, worm, spyware or any other computer code, file or program
        that may damage or is intended to damage or hijack the operation of any equipment
        and ancillary services needed to connect to, access or otherwise use the Services,
        including, modems, hardware, servers, software, operating systems, networking,
        telecommunications, web servers and the like (collectively, “Equipment”), or any
        other aspect or operation of the Services, or scrape, spider, use a robot or other
      automated means of any kind to access the Services;
      </li>
    </ul>
    <p>
      <strong>
        Inappropriate content
      </strong>
    </p>
    <ul>
      <li>
        upload, post or otherwise transmit any unsolicited or unauthorised advertising,
        promotional materials, junk mail, spam, chain letters, pyramid schemes or any other
        form of solicitation (commercial or otherwise) through the Services;
      </li>
      <li>
        post or provide any inappropriate, offensive, racist, hateful, sexist, pornographic,
      false, misleading, incorrect, infringing, defamatory or libelous content or information.
      </li>
    </ul>
    <p>
      <strong>
        Here is what you must do:
      </strong>
    </p>
    <ul>
      <li>
        use the Services only in compliance with our standard published policies then in
      effect and all applicable laws;
      </li>
      <li>
        maintain the Equipment and meet all costs and expenses whatsoever in relation to
      the Equipment;
      </li>
      <li>
        maintain the security of the Equipment, your account with us, passwords (including
      administrative and User passwords) and files;
      </li>
      <li>
        assume responsibility for controlling how your personal information is disclosed or
      used, including to take appropriate steps to protect such information.
      </li>
    </ul>
    <p>
      Capitalised terms used in this Acceptable Use Policy take their meaning from the Customer
      Terms of Service.  Headings are for convenience only and do not affect the interpretation
      of this Acceptable Use Policy.
    </p>
    <p>
      You agree to indemnify us and hold us harmless against any damages, losses, liabilities,
      settlements and expenses (including costs and attorneys’ fees) in connection with any claim
      or action that arises from an alleged violation of this Acceptable Use Policy or otherwise
      from your use of the Services.
    </p>
    <p>
      Although we have no obligation to monitor your use of the Services, we may do so and may
      prohibit any use of the Services we believe may be (in our sole discretion) or alleged to
      be, in violation of this Acceptable Use Policy.
    </p>
    <p>
      Without affecting any other remedies available to us, we may permanently or temporarily
      terminate or suspend your account or access to or use of the Services without notice or
      liability if we (in our sole discretion) determine that you have violated this Acceptable
      Use Policy.
    </p>
  </View>
);
