import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PackageLicenseType } from '@go1d/go1d-exchange';
import {
  Button,
  Container,
  Text,
  View,
  ButtonFilled,
  Slat,
  ButtonMinimal,
  Banner,
  Link,
  Modal,
  Prose,
} from '@go1d/go1d';
import IconCross from '@go1d/go1d/build/components/Icons/Cross';
import IconPlus from '@go1d/go1d/build/components/Icons/Plus';
import get from 'lodash/get';
import QueryString from 'query-string';
import PropTypes from 'prop-types';

import { REACT_APP_BASE_DIRECTORY } from '../../config';
import { validateRequest } from '../../services/requestModels';
import LogoContainer from '../../components/LogoContainer';
import { getAvailablePackages } from '../../services/subscription';
import onboardPortal from '../../utils/onboard';
import track from '../../services/houston';
import isPackageFree from '../../utils/package';

export default class ChooseLearning extends React.Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    storedPackageValues: PropTypes.objectOf(PropTypes.any).isRequired,
    storedPortalValues: PropTypes.objectOf(PropTypes.any).isRequired,
    storedOnboardValues: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatchErrorValues: PropTypes.func.isRequired,
    dispatchPackageValues: PropTypes.func.isRequired,
    dispatchAddPackage: PropTypes.func.isRequired,
    dispatchRemovePackage: PropTypes.func.isRequired,
    dispatchSelectPackagePlan: PropTypes.func.isRequired,
  }

  state = {
    isLoading: true,
    isSubmitting: false,
    finishedPreselection: false,
  }

  componentDidMount() {
    const { dispatchPackageValues } = this.props;
    const windowSearch = get(window, 'location.search');
    const selectedPackageIds = get(QueryString.parse(windowSearch, { arrayFormat: 'bracket' }), 'packageIds');

    dispatchPackageValues({ selectedPackageIds });
    this.fetchData();
  }

  componentDidUpdate() {
    const { finishedPreselection } = this.state;
    if (!finishedPreselection) {
      const {
        storedPackageValues: {
          packages = [],
          selectedPackageIds = [],
        },
        storedPortalValues: {
          preselectedPackages = [],
        },
        dispatchPackageValues,
      } = this.props;

      if (preselectedPackages.length > 0) {
        const preselectedPackageIds = packages.filter(e => preselectedPackages.includes(`${e.loId}`)).map(e => e.id);
        if (preselectedPackageIds.length > 0) {
          const unselectedIds = preselectedPackageIds.filter(e => !selectedPackageIds.includes(e));
          if (unselectedIds.length > 0) {
            const s = selectedPackageIds.concat(unselectedIds).filter((v, i, a) => a.indexOf(v) === i);
            this.setState({ finishedPreselection: true });
            dispatchPackageValues({ selectedPackageIds: s });
          }
        }
      }
    }
  }

  onSelectPackage = (evt, packageId, actionType) => {
    evt.preventDefault();
    evt.stopPropagation(); // to prevent call tracking when clicking on whole slat
    const { dispatchAddPackage, dispatchRemovePackage } = this.props;
    if (actionType === 'Add') {
      dispatchAddPackage(packageId);
    } else {
      dispatchRemovePackage(packageId);
    }
  }

  onSubmit = () => {
    const {
      history,
      dispatchErrorValues,
      storedPortalValues,
      storedOnboardValues,
    } = this.props;
    const {
      jwt,
      creation_path = '',
      portal = {},
      customer = {},
      user = {},
    } = storedOnboardValues;
    const { portalId } = storedPortalValues;

    const errors = validateRequest(user, portal, customer);
    const windowSearchQuery = get(window, 'location.search', '');
    if (Object.keys(errors).length > 0) {
      dispatchErrorValues(errors);
      history.push(`${REACT_APP_BASE_DIRECTORY}/${windowSearchQuery}`);
    } else {
      const {
        storedPackageValues: {
          packages = [],
          selectedPackageIds = [],
        },
        dispatchSelectPackagePlan,
      } = this.props;

      this.setState({ isSubmitting: true });
      const selectedPackages = packages.filter(pack => selectedPackageIds.indexOf(pack.id) > -1);
      const planTexts = selectedPackages[0] && selectedPackages[0].stripePlan.map(plan => plan.interval);
      track(
        'choose-learning',
        {
          id: 'submit-selected-packages',
          selected_packages: selectedPackageIds,
        },
      );
      const interval = planTexts && planTexts.includes('year') ? 'year' : 'month';
      dispatchSelectPackagePlan(interval);
      let total = 0;
      if (selectedPackages.length > 1) {
        total = selectedPackages.reduce((prev, curr) => {
          const prevAmount = typeof prev === 'object' ? prev && prev.stripePlan && prev.stripePlan[0].amount : prev;
          const currAmount = (curr && curr.stripePlan && curr.stripePlan[0].amount) || 0;
          return prevAmount + currAmount;
        });
      } else if (selectedPackages[0]) {
        total = selectedPackages[0].stripePlan[0].amount;
      }
      if (total === 0) {
        const subscriptionCustomer = {
          ...customer,
          billing_interval: (planTexts && planTexts.includes('year')) ? 'annual' : 'monthly',
          purchase_level: 'portal',
          plans: selectedPackages.map((pack) => {
            const currentPlan = pack.stripePlan.find(plan => plan.interval === interval);
            return {
              id: currentPlan.id,
              quantity: pack.seats,
            };
          }),
        };
        const onboardData = {
          user,
          portal: { ...portal, content_pack_ids: packages.map(pack => Number(pack.loId)) },
          customer: subscriptionCustomer,
          creation_path,
          jwt,
          partner_portal_id: Number(portalId),
        };
        onboardPortal(onboardData).catch((err) => {
          if (err.message === 'account-error') {
            return history.push(`${REACT_APP_BASE_DIRECTORY}/login${windowSearchQuery}`);
          }
          this.setState({ isSubmitting: false });
          return false;
        });
      } else {
        this.setState({ isSubmitting: false });
        history.push(`${REACT_APP_BASE_DIRECTORY}/purchase_subscriptions${windowSearchQuery}`);
      }
    }
  }

  fetchData = () => {
    const {
      storedPortalValues: {
        portalId,
      },
      dispatchPackageValues,
      storedOnboardValues,
    } = this.props;
    this.setState({ isLoading: true });

    getAvailablePackages({ portalId }, storedOnboardValues)
      .then(packages => dispatchPackageValues({ packages }))
      .finally(() => this.setState({ isLoading: false }));
  }

  onClickPackageItem = (e, packId) => {
    const { history } = this.props;
    e.preventDefault();
    track(
      'choose-learning',
      {
        id: 'click-package',
        package_id: packId,
      },
    );
    history.push(`${REACT_APP_BASE_DIRECTORY}/package_overview/${packId}${get(window, 'location.search', '')}`);
  }

  togglePackageModal = (pack, isSelected, e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      isModalOpened: pack !== undefined,
      selectedPackage: pack,
      selectedPackageIsSelected: isSelected,
    });
  }

  renderItem = (pack, isSelected = false) => {
    const planByYear = pack.stripePlan.find(packPlan => packPlan.interval === 'year') || {};
    const packEntity = pack.entity || {};
    const {
      portalName = '',
      name = '',
      contentCount = 0,
      imageUrl = '',
      url = '',
      description = '',
    } = packEntity;

    const {
      storedPortalValues: {
        disablePackageOverview = false,
      },
    } = this.props;

    let priceSuffix = `pp/${planByYear.interval}`;

    const allowAction = !isPackageFree(pack);

    if (packEntity.licenseType === PackageLicenseType.PER_PORTAL) {
      priceSuffix = `${planByYear.interval}`;
    }

    const renderSlat = () => (
      <Slat
        id={pack.id}
        priceSuffix={planByYear.amount ? priceSuffix : ''}
        topMeta={[portalName]}
        title={name}
        description={description.replace(/(<([^>]+)>)/ig, '')}
        currency={planByYear.currency}
        price={planByYear.amount || 'Free'}
        bottomMeta={[
          {
            icon: 'Content',
            text: `${contentCount} resources`,
          },
        ]}
        image={imageUrl}
        type={null}
        actionRenderer={allowAction ? () => (
          <ButtonMinimal
            onClick={e => this.onSelectPackage(e, pack.id, isSelected ? 'Remove' : 'Add')}
            color={isSelected ? 'danger' : 'accent'}
            iconName={isSelected ? IconCross : IconPlus}
            size="sm"
          >
            {isSelected ? 'Remove' : 'Add'}
          </ButtonMinimal>
        ) : undefined}
      />
    );

    return disablePackageOverview ? (
      <RouterLink
        key={pack.id}
        to=""
        onClick={e => this.togglePackageModal(packEntity, isSelected, e)}
      >
        {renderSlat()}
      </RouterLink>
    ) : (
      <RouterLink
        key={pack.id}
        to={url}
        onClick={e => this.onClickPackageItem(e, pack.id)}
      >
        {renderSlat()}
      </RouterLink>
    );
  }

  render() {
    const {
      isLoading, isSubmitting, selectedPackage, selectedPackageIsSelected, isModalOpened,
    } = this.state;
    const { storedPackageValues: { packages = [], selectedPackageIds = [] } } = this.props;
    let availablePackages = packages.filter(pack => selectedPackageIds.indexOf(pack.id) < 0).map(pack => this.renderItem(pack));
    let selectedPackages = packages.filter(pack => selectedPackageIds.indexOf(pack.id) > -1).map(pack => this.renderItem(pack, true));

    if (isLoading) {
      availablePackages = [<Slat key={0} skeleton={isLoading} />];
      selectedPackages = [<Slat key={1} skeleton={isLoading} />];
    }

    return (
      <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
        <Container contain="normal" marginTop={6}>
          <LogoContainer title="Kick-start your library with content packs">
            <View marginTop={6}>
              {(availablePackages.length > 0 || selectedPackages.length > 0) && (
                <React.Fragment>
                  {selectedPackages.length > 0 && (
                    <View marginBottom={availablePackages.length > 0 ? 7 : 0}>
                      <Text fontSize={3} marginBottom={4}>Selected</Text>
                      {selectedPackages}
                    </View>
                  )}
                  {availablePackages.length > 0 && (
                    <View>
                      <Text fontSize={3} marginBottom={4}>Available</Text>
                      {availablePackages}
                    </View>
                  )}
                  <ButtonFilled
                    onClick={this.onSubmit}
                    color="accent"
                    width="100%"
                    size="lg"
                    marginTop={6}
                    disabled={isSubmitting || selectedPackages.length === 0}
                  >
                    {isSubmitting ? 'Loading...' : 'Continue'}
                  </ButtonFilled>
                </React.Fragment>
              )}
              {availablePackages.length === 0 && selectedPackages.length === 0 && !isLoading && (
                <Banner
                  type="danger"
                >
                  <Text fontWeight="semibold">Content packs unavailable</Text>
                  <Text>
                    There was a problem requesting content packs.
                    {' '}
                    <Button
                      onClick={() => this.fetchData()}
                      height="auto"
                      display="inline-flex"
                      color="contrast"
                      css={{
                        textDecoration: 'underline',
                        fontWeight: 600,
                        padding: 0,
                      }}
                    >
                      Retry?
                    </Button>
                  </Text>
                  <Text>
                    Still not working?
                    {' '}
                    <Link
                      target="_blank"
                      href="https://go1.com/contact"
                      css={{
                        textDecoration: 'underline',
                        fontWeight: 600,
                      }}
                    >
                      Contact support
                    </Link>
                  </Text>
                </Banner>
              )}
            </View>
          </LogoContainer>
        </Container>

        <Modal
          headerIcon={IconCross}
          isOpen={isModalOpened}
          title={selectedPackage && selectedPackage.name}
          onRequestClose={() => this.togglePackageModal()}
        >
          {selectedPackage && (
            <View justifyContent="space-between" minHeight={180}>
              <Prose HTML={selectedPackage.description} />
              <ButtonFilled
                color="accent"
                onClick={(e) => {
                  this.onSelectPackage(e, `${selectedPackage.id}`, selectedPackageIsSelected ? 'Remove' : 'Add');
                  this.setState({ isModalOpened: false });
                }}
              >
                {selectedPackageIsSelected ? 'Remove' : 'Add'}
                {' '}
                Package
              </ButtonFilled>
            </View>
          )}
        </Modal>
      </Container>
    );
  }
}
