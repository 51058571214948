import React from 'react';
import {
  Text,
  View,
  ButtonFilled,
  Spinner,
} from '@go1d/go1d';

const SsoLoginButton = ({
  ssoLink,
  marginTop,
  title,
  image,
  loading,
  color,
  ...props
}) => (

  <View
    marginTop={marginTop}
    {...props}
  >
    <ButtonFilled
      marginBottom={5}
      size="lg"
      onClick={ssoLink}
      color={color}
    >
      <View flexDirection="row">
        {image && (
        <img src={image} alt={title} />
        )}
        <Text
          fontSize={[title.length > 15 ? 1 : 2, 2, 3]}
          fontWeight="semibold"
          paddingLeft={[3, 4]}
          textAlign="center"
        >
          {loading && (
            <React.Fragment>
              <Spinner size={2} marginRight={2} />
              {' '}
Checking with your identity provider
            </React.Fragment>
          )}
          {!loading && (
            <React.Fragment>
              Log in with
              {' '}
              {title}
            </React.Fragment>
          )}
        </Text>
      </View>
    </ButtonFilled>
  </View>
);

export default SsoLoginButton;
