import { connect } from 'react-redux';
import WhatToLearn from './page';

/* istanbul ignore next */
const mapStateToProps = state => ({
  storedFormValues: state.formValues,
});


export default connect(mapStateToProps)(WhatToLearn);
