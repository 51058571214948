import React from 'react';
import PropTypes from 'prop-types';
import { Banner, Link, Text } from '@go1d/go1d';

export default function BigCompanyBanner({ companySize, sizeThreshold }) {
  if (companySize <= sizeThreshold) {
    return null;
  }
  return (
    <Banner type="warning" marginBottom={4}>
      <Text>
        You have selected more than
        {` ${sizeThreshold} `}
        users. Please
        {' '}
        <Link
          css={{
            textDecoration: 'underline',
            fontWeight: 'semibold',
          }}
          href="https://www.go1.com/contact#contact_form_start"
          rel="noopener noreferrer"
          target="_blank"
        >
          contact us
        </Link>
        {' '}
        to complete this setup.
      </Text>
    </Banner>
  );
}

BigCompanyBanner.propTypes = {
  companySize: PropTypes.number,
  sizeThreshold: PropTypes.number,
};

BigCompanyBanner.defaultProps = {
  companySize: 0,
  sizeThreshold: 100,
};
