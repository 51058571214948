import { combineReducers } from 'redux';
import { reducer as groupLoItemsReducer } from '@go1d/mine/reducers/group-slats';

import formReducer from './form';
import errorReducer from './error';
import portalReducer from './portal';
import onboardReducer from './onboard';
import packagesReducer from './packages';
import packageEntities from './package-entities';

export default combineReducers({
  entities: packageEntities,
  groupLoItems: groupLoItemsReducer,
  formValues: formReducer,
  errorValues: errorReducer,
  portalValues: portalReducer,
  onboardValues: onboardReducer,
  packageValues: packagesReducer,
});
