import { connect } from 'react-redux';
import Signup from './page';
import { formActions } from '../../actions/form';
import { errorActions } from '../../actions/error';
import { onboardActions } from '../../actions/onboard';

/* istanbul ignore next */
const mapStateToProps = state => ({
  storedFormValues: state.formValues,
  storedErrorValues: state.errorValues,
  storedPortalValues: state.portalValues,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  dispatchErrorValues: email => dispatch(errorActions.storeValues(email)),
  dispatchOnboardValues: (...values) => dispatch(onboardActions.storeValues(...values)),
  dispatchStoreSignupValues: (type, first_name, last_name, email, password) => dispatch(
    formActions.storeValues(type, first_name, last_name, email, password),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Signup);
