import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonFilled,
  Container,
  Text,
  View,
  UL,
  LI,
} from '@go1d/go1d';
import IconSuccess from '@go1d/go1d/build/components/Icons/Success';
import { REACT_APP_BASE_DIRECTORY } from '../../config';
import Header from '../../components/Header';
import { PORTAL_PLAN } from '../../actions/portal';

class ChoosePlan extends React.Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  choosePlan = (plan, history) => {
    const { dispatchStoreValues } = this.props;

    dispatchStoreValues({ plan });
    history.push(`${REACT_APP_BASE_DIRECTORY}/create`);
  }

  render() {
    const { history } = this.props;
    return (
      <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
        <Header />
        <Text element="h1" fontSize={4} fontWeight="semibold">
          Start your 14 day free trial
        </Text>
        <View textAlign="center" marginY={3} paddingX={3}>
          <Text color="subtle" element="h2">Fast setup, all the training you need.</Text>
        </View>
        <Container contain="normal" alignItems="flex-start" marginTop={6} flexDirection="row" css={{ flexWrap: 'wrap' }} justifyContent="space-evenly">
          <View
            boxShadow="soft"
            backgroundColor="background"
            marginX={3}
            overflow="hidden"
            borderRadius={2}
            width="100%"
            maxWidth={390}
            css={{ minWidth: 320 }}
            textAlign="center"
            flexDirection="column"
            flexGrow={1}
            marginBottom={6}
          >
            <View
              borderBottom={1}
              borderColor="soft"
              paddingY={4}
              marginBottom={6}
            >
              <Text fontSize={3} fontWeight="semibold">
                Platform
              </Text>
            </View>

            <Text fontSize={3}>
              From AUD $0 / month
            </Text>
            <View textAlign="center" paddingTop={5} paddingX={6}>
              <Text color="subtle">Select courses from the Marketplace or create your own training programs.</Text>
            </View>
            <View
              borderBottom={1}
              borderColor="soft"
              paddingY={6}
              alignItems="center"
            >
              <ButtonFilled justifyContent="center" size="lg" width={250} id="platform_btn" onClick={() => this.choosePlan(PORTAL_PLAN.PLATFORM, history)}>Free forever</ButtonFilled>
            </View>
            <UL fontSize={2} color="subtle" iconName={IconSuccess}>
              <LI>No credit card details required</LI>
              <LI>Free forever</LI>
              <LI>Full access to administration and learner functions</LI>
              <LI>Course creation tools</LI>
              <LI>Unlimited user accounts</LI>
              <LI>Upgrade for $2 per user per month for access to enterprise and support features</LI>
            </UL>

          </View>
          <View
            boxShadow="soft"
            backgroundColor="background"
            marginX={3}
            overflow="hidden"
            borderRadius={2}
            width="100%"
            maxWidth={390}
            css={{ minWidth: 320 }}
            textAlign="center"
            flexDirection="column"
            flexGrow={1}
          >
            <View
              borderBottom={1}
              borderColor="soft"
              paddingY={4}
              marginBottom={6}
              backgroundColor="accent"
            >
              <Text fontSize={3} fontWeight="semibold" color="background">
                Platform + Premium
              </Text>
            </View>
            <Text fontSize={3}>
              From AUD $12 / month
            </Text>
            <View textAlign="center" paddingTop={5} paddingX={6}>
              <Text color="subtle">Subscribe to thousands of Go1&#39;s most popular off the shelf courses.</Text>
            </View>
            <View
              borderBottom={1}
              borderColor="soft"
              paddingY={6}
              alignItems="center"
            >
              <ButtonFilled color="accent" justifyContent="center" size="lg" id="premium_btn" width={250} onClick={() => this.choosePlan(PORTAL_PLAN.PREMIUM, history)}>Try it free</ButtonFilled>
            </View>
            <UL fontSize={2} color="subtle" iconName={IconSuccess}>
              <LI>Free for 14 Days </LI>
              <LI>Full access to administrator and learner functions </LI>
              <LI>Course creation tools </LI>
              <LI>Advanced reporting </LI>
              <LI>Free access to Go1 Platform and all features </LI>
              <LI>24/7 support for administrators via phone, email and live chat</LI>
            </UL>
          </View>
        </Container>
      </Container>
    );
  }
}

ChoosePlan.propTypes = {
  dispatchStoreValues: PropTypes.func,
};

export default ChoosePlan;
