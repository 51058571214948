import React from 'react';
import {
  Container,
  Text,
  View,
  Form,
  Field,
  TextInput,
  SubmitButton,
  foundations,
  Banner,
} from '@go1d/go1d';
import BlockUi from 'react-block-ui';
import PropTypes from 'prop-types';
import onboardUser from '../../services/userOnboard';
import {
  userRequest, portalRequest, customerRequest, validateRequest,
} from '../../services/requestModels';
import regionMap from '../../services/regionMap';
import BigCompanyBanner from '../../components/BigCompanyBanner/BigCompanyBanner';
import { REACT_APP_BASE_DIRECTORY } from '../../config';
import LogoContainer from '../../components/LogoContainer';
import { PORTAL_PLAN } from '../../actions/portal';
import PaymentForm from './components/PaymentForm';


const PORTAL_TRIAL_DAYS = 14;

export default class Payment extends React.Component {
  static propTypes = {
    dispatchStorePaymentValues: PropTypes.func.isRequired,
    dispatchStoreValues: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    storedFormValues: PropTypes.objectOf(PropTypes.any).isRequired,
    storedPortalValues: PropTypes.objectOf(PropTypes.any),
    dispatchErrorValues: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.paymentFormComponent = React.createRef();

    const { storedFormValues, storedPortalValues = {} } = props;
    const { number_of_seats, companySize } = storedFormValues;

    // preset number of seats
    let numberOfSeats = 10;
    if (!number_of_seats && !storedPortalValues.companySizeStepper && companySize) {
      switch (companySize) {
        case '1-20': numberOfSeats = 20;
          break;
        case '20-100': numberOfSeats = 100;
          break;
        case '100+': numberOfSeats = 150;
          break;
        default: numberOfSeats = 10;
      }
    }

    if (storedPortalValues.companySizeStepper) {
      numberOfSeats = companySize;
    }

    this.state = {
      form: {
        timeFrame: 'Yearly',
        companySize: numberOfSeats,
      },
      isSubmitting: false,
    };
  }

  getPaymentFormInstance = () => (this.paymentFormComponent.current);

  onChange = ({ values }) => {
    const paymentFormInstance = this.getPaymentFormInstance();
    paymentFormInstance.onChange({ values });
  }

  onValidate = (values) => {
    const errors = {};
    const paymentFormInstance = this.getPaymentFormInstance();

    if (values.number_of_seats > 0) {
      this.handleChange({
        target: {
          name: 'companySize',
          value: Math.ceil(values.number_of_seats),
        },
      });
    }

    if (values.number_of_seats === 0) {
      errors.number_of_seats = 'Please enter the number of seats you wish to sign up.';
    }

    return {
      ...paymentFormInstance.onValidate(values),
      ...errors,
    };
  };

  handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    const FieldValue = event.target.type === 'number' ? Number(value) : value;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: FieldValue,
      },
    });
    return true;
  };

  onboardUser = (formValues, cardToken) => {
    const {
      history, dispatchErrorValues, storedFormValues,
      dispatchStorePaymentValues, dispatchStoreValues,
    } = this.props;
    const { jwt } = storedFormValues;
    const { form: formData } = this.state;
    const values = Object.assign({}, formValues, { credit_card_token: cardToken, payment_interval: formData.timeFrame });
    const user = userRequest(storedFormValues);
    const portal = portalRequest(storedFormValues, formValues.number_of_seats);
    const customer = customerRequest({ ...storedFormValues, ...values });
    const creation_path = storedFormValues.type === 'referral' ? PORTAL_PLAN.REFERRAL_PARTNER : PORTAL_PLAN.WEBSITE_PREMIUM;
    const partner_portal_id = storedFormValues.portal ? storedFormValues.portal : null;

    const errors = validateRequest(user, portal, customer);
    if (Object.keys(errors).length > 0) {
      // some field(s) missing, pop errors
      dispatchErrorValues(errors);
      // maintain inputs for when user returns
      dispatchStorePaymentValues(values);
      history.push(`${REACT_APP_BASE_DIRECTORY}/`);
    } else {
      onboardUser({
        user, portal, customer, creation_path, jwt, partner_portal_id,
      })
        .then((res) => {
          const userResponse = res.data.user;
          const portalResponse = res.data.portal;
          dispatchStoreValues({
            ...values,
            user: userResponse,
            portal: portalResponse,
          });
          history.push(`${REACT_APP_BASE_DIRECTORY}/whos_learning`);
        })
        .catch((err) => {
          let errorMessage = 'Your request could not be processed.';
          if (err.response && err.response.status === 403) {
            errorMessage = 'An account already exists with this email.';
          }
          this.setState({ genericError: errorMessage });
        });
    }
  }

  onSubmit = (formValues, actions) => {
    const { setSubmitting } = actions;
    const paymentFormInstance = this.getPaymentFormInstance();

    setSubmitting(true);
    paymentFormInstance.onSubmit(formValues, actions)
      .then(cardToken => this.onboardUser(formValues, cardToken))
      .finally(() => setSubmitting(false));
  }

  getCost = (TimeFrame) => {
    const { form } = this.state;
    const { companySize } = form;
    const { storedFormValues } = this.props;
    const { country } = storedFormValues;
    const region = regionMap[country];
    let basePrice = 12;
    let curr = 'USD $';
    switch (region) {
      case 'AU':
        basePrice = 12;
        curr = 'AUD $';
        break;
      case 'EU':
        basePrice = 11;
        curr = '€';
        break;
      case 'UK':
        basePrice = 10;
        curr = '£';
        break;
      case 'MY':
        basePrice = 50;
        curr = 'RM';
        break;
      default:
        basePrice = 12;
        curr = 'USD $';
        break;
    }

    const Price = {
      Monthly: 'N/A',
      Yearly: 'N/A',
    };

    if (companySize > 0 && companySize < 101) {
      Price.Monthly = `${curr}${(companySize * basePrice)}`;
      Price.Yearly = `${curr}${(companySize * basePrice * 12)}`;
    }

    return Price[TimeFrame] || '';
  };

  getChargeDate = () => {
    const currentDay = new Date().getDate();
    const chargeDate = new Date();

    chargeDate.setDate(currentDay + PORTAL_TRIAL_DAYS);
    return chargeDate.toLocaleDateString();
  }

  render() {
    const { storedFormValues } = this.props;
    const {
      form, isSubmitting, genericError,
    } = this.state;

    const { companySize, timeFrame } = form;
    return (
      <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
        <Container
          contain="normal"
          alignItems="flex-start"
          marginTop={6}
          flexDirection="row"
          css={{ flexWrap: 'wrap' }}
          justifyContent="center"
        >
          <LogoContainer
            title="Almost done..."
            description="Enter your credit card to start your 14 day premium trial."
            errorBannerLayout={genericError}
          >
            <View>
              {genericError && (
                <Banner
                  type="danger"
                  marginBottom={6}
                >
                  <Text fontWeight="semibold">
                    {genericError}
                  </Text>
                  <br />
                  <a href="https://www.go1.com/contact" rel="noopener noreferrer" target="_blank">Please contact support.</a>
                </Banner>
              )}

              <BlockUi tag="div" blocking={false}>
                <Form
                  initialValues={{
                    number_of_seats: storedFormValues.number_of_seats ? storedFormValues.number_of_seats : companySize,
                    name: `${storedFormValues.first_name ? `${storedFormValues.first_name} ` : ''}${storedFormValues.last_name ? storedFormValues.last_name : ''}`,
                    number: '',
                    expiry: '',
                    cvc: '',
                  }}
                  validateOnBlur={false}
                  validate={this.onValidate}
                  onChange={this.onChange}
                  onSubmit={this.onSubmit}
                >
                  <View
                    paddingBottom={companySize < 20 ? 4 : 0}
                    borderBottom={companySize < 20 ? 1 : 0}
                    borderColor="soft"
                    css={{
                      [foundations.breakpoints.sm]: {
                        flexDirection: 'column',
                      },
                      [foundations.breakpoints.md]: {
                        flexDirection: 'row',
                      },
                    }}
                  >
                    <View
                      flexDirection="column"
                      css={{
                        [foundations.breakpoints.sm]: {
                          maxWidth: '100%',
                          marginRight: 0,
                        },
                        [foundations.breakpoints.md]: {
                          maxWidth: 220,
                          marginRight: 30,
                        },
                      }}
                    >
                      <Field component={TextInput} inputType="number" min={1} name="number_of_seats" label="Number of seats" required />
                    </View>
                    <View flexDirection="column" flexGrow={1}>
                      {companySize > 0 && (
                      <View flexDirection="row" css={{ overflow: 'hidden', borderRadius: 4 }}>
                        <View
                          backgroundColor={timeFrame === 'Yearly' ? 'accent' : 'soft'}
                          padding={3}
                          data-testid="timeFrameYearly"
                          onClick={() => this.handleChange({
                            target: {
                              name: 'timeFrame',
                              value: 'Yearly',
                            },
                          })
                              }
                          width="50%"
                          textAlign="center"
                        >
                          <Text color={timeFrame === 'Yearly' ? 'background' : 'subtle'}>
                            <Text fontSize={1}>
                                  YEARLY
                            </Text>
                            <br />
                            <Text
                              fontSize={3}
                              fontWeight="semibold"
                              color={timeFrame === 'Yearly' ? 'background' : 'default'}
                            >
                              {this.getCost('Yearly')}
                            </Text>
                            <br />
                            {companySize <= 20 && (
                            <Text fontSize={1}>Flat fee / 1-20 users</Text>
                            )}
                          </Text>
                        </View>
                        <View
                          backgroundColor={timeFrame === 'Monthly' ? 'accent' : 'soft'}
                          data-testid="timeFrameMonthly"
                          onClick={() => this.handleChange({
                            target: {
                              name: 'timeFrame',
                              value: 'Monthly',
                            },
                          })}
                          textAlign="center"
                          padding={3}
                          width="50%"
                        >
                          <Text color={timeFrame === 'Monthly' ? 'background' : 'subtle'}>
                            <Text fontSize={1}>
                                  MONTHLY
                            </Text>
                            <br />
                            <Text
                              fontSize={3}
                              fontWeight="semibold"
                              color={timeFrame === 'Monthly' ? 'background' : 'default'}
                            >
                              {this.getCost('Monthly')}
                            </Text>
                            <br />
                            {companySize <= 20 && (
                            <Text fontSize={1}>Flat fee / 1-20 users</Text>
                            )}
                          </Text>
                        </View>
                      </View>
                      )}
                    </View>
                  </View>

                  {companySize < 101 && (
                    <View marginTop={6} marginBottom={4} textAlign="center">
                      <Text color="subtle" fontSize={1} fontWeight="semibold">
                        No commitment for
                        {' '}
                        {PORTAL_TRIAL_DAYS}
                        {' '}
                        days, cancel anytime.
                        <br />
                        After
                        {' '}
                        {PORTAL_TRIAL_DAYS}
                        {' '}
                        days (
                        {this.getChargeDate()}
                        ) you&apos;ll be charged
                        {' '}
                        <Text fontSize={1} color="contrast">
                          {this.getCost(timeFrame)}
                          {' '}
                          <Text fontSize={1} textTransform="lowercase">
                            {timeFrame}
                          </Text>
                        </Text>
                      </Text>
                    </View>
                  )}

                  <BigCompanyBanner companySize={companySize} />
                  <View
                    css={{
                      opacity: companySize >= 101 ? 0.6 : 1,
                      pointerEvents: companySize >= 101 ? 'none' : 'auto',
                    }}
                  >
                    <PaymentForm
                      ref={this.paymentFormComponent}
                    />

                    <View alignItems="center" marginTop={6}>
                      <SubmitButton
                        width="100%"
                        justifyContent="center"
                        size="lg"
                        color="accent"
                        disabled={isSubmitting === true}
                      >
                        Start premium trial
                      </SubmitButton>
                    </View>
                  </View>
                </Form>
              </BlockUi>
            </View>
          </LogoContainer>
        </Container>
      </Container>
    );
  }
}
