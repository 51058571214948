import { connect } from 'react-redux';
import Login from './page';

import { formActions } from '../../actions/form';

/* istanbul ignore next */
const mapStateToProps = state => ({
  storedFormValues: state.formValues,
  storedPortalValues: state.portalValues,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  dispatchStoreValues: (...variables) => dispatch(formActions.storeValues(...variables)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
