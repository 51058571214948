import _ from 'lodash';

export const FORM = {
  STORE_VALUES: 'FORM_STORE_VALUES',
  AUTHOR_DETAILS_STORE_VALUES: 'FORM_AUTHOR_DETAILS_STORE_VALUES',
  PAYMENT_STORE_VALUES: 'FORM_PAYMENT_STORE_VALUES',
};

export const formActions = {
  storeValues(payload) {
    return {
      type: FORM.STORE_VALUES,
      payload,
      meta: {
        analytics: {
          eventType: 'track',
          eventPayload: {
            event: 'User Register',
            properties: {
              contentId: payload.id,
              portalId: _.get(payload, 'portal.id', null),
              partner_id: _.get(payload, 'portal.partner_id', null),
              userId: _.get(payload, 'user.userId', null),
              source: payload.source,
            },
          },
        },
      },
    };
  },
  storeAuthorDetailsValues(payload) {
    return {
      type: FORM.AUTHOR_DETAILS_STORE_VALUES,
      payload,
      meta: {
        analytics: {
          eventType: 'track',
          eventPayload: {
            event: 'Signup - Author Details',
            properties: {
              contentId: payload.id,
              portalId: _.get(payload, 'portal.id', null),
              partner_id: _.get(payload, 'portal.partner_id', null),
              userId: _.get(payload, 'user.userId', null),
              source: payload.source,
            },
          },
        },
      },
    };
  },
  storePaymentValues(payload) {
    return {
      type: FORM.PAYMENT_STORE_VALUES,
      payload,
      meta: {
        analytics: {
          eventType: 'track',
          eventPayload: {
            event: 'Signup - Payment',
            properties: {
              contentId: payload.id,
              portalId: _.get(payload, 'portal.id', null),
              partner_id: _.get(payload, 'portal.partner_id', null),
              userId: _.get(payload, 'user.userId', null),
              source: payload.source,
            },
          },
        },
      },
    };
  },
};
