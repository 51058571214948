import QueryString from 'query-string';
import jwt_decode from 'jwt-decode';
import { createReducer } from '../../utils';
import { FORM } from '../../actions/form';
import { getStoredJWT } from '../../services/user';

export const actionHandlers = {
  [FORM.STORE_VALUES]: (state, action) => Object.assign({}, state, {
    ...action.payload,
  }),
  [FORM.AUTHOR_DETAILS_STORE_VALUES]: (state, action) => Object.assign({}, state, {
    ...action.payload,
  }),
  [FORM.PAYMENT_STORE_VALUES]: (state, action) => Object.assign({}, state, {
    ...action.payload,
  }),
};

const QueryParams = QueryString.parse(window.location.search);
let InitialState = {};
let existingUser = null;

// data passed via query string
if (QueryParams.initial) {
  try {
    InitialState = JSON.parse(QueryParams.initial);
  } catch (err) {
    console.log('Error: ', err);
  }
}

// if referral
if (QueryParams.referral) {
  try {
    InitialState.portal = JSON.parse(QueryParams.referral);
    InitialState.type = 'referral';
  } catch (err) {
    console.log('Error: ', err);
  }
}

// if freemium
if (QueryParams.freemium) {
  try {
    InitialState.freemium = QueryParams.freemium.toLowerCase() === 'true';
  } catch (err) {
    console.log('Error: ', err);
  }
}

// if skip_preference
if (QueryParams.skip_preference) {
  try {
    InitialState.skip_preference = QueryParams.skip_preference.toLowerCase() === 'true';
  } catch (err) {
    console.log('Error: ', err);
  }
}

// auth JWT in localstorage
// TODO: Read JWT from Cookie
const jwt = getStoredJWT();
if (jwt) {
  try {
    const jwtDecoded = jwt_decode(jwt);
    // Check if JWT is expired
    // TODO: Account for timezones, currently JWTs are valid for 7 days, so should be alright if we ignore timezones for now
    if (jwtDecoded.exp > (new Date().getTime() / 1000)) {
      existingUser = jwtDecoded.object.content || null;
      if (existingUser) {
        InitialState.jwt = jwt;
      }
    } else {
      localStorage.removeItem('jwt');
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

// add redirect url to redux
if (QueryParams.redirect_url) {
  InitialState.redirect_url = QueryParams.redirect_url;
}

// build reducer values in case of JWT
if (existingUser) {
  const name = existingUser.name.split(' ');
  const [first_name, last_name] = name;
  InitialState.first_name = first_name;
  InitialState.last_name = last_name;
  InitialState.email = existingUser.mail;
  InitialState.type = InitialState.type ? InitialState.type : 'team';
  // this is a bit sketchy but allows normal behaviour throughout app
  InitialState.password = 'jwt supplied';
  InitialState.accounts = existingUser.accounts;
}

export default createReducer(InitialState, actionHandlers);
