import axios from 'axios';
import { REACT_APP_INSTANCE, REACT_APP_PUBLIC_PORTAL, REACT_APP_API_URL } from '../config';

/* istanbul ignore next */
export default function registerUser({
  instance = `${REACT_APP_INSTANCE}`,
  portal,
  email,
  password,
  first_name,
  last_name,
  data = {},
  jwt = null,
}) {
  const portalId = portal || `${REACT_APP_PUBLIC_PORTAL}`;
  return axios.post(`${REACT_APP_API_URL}/user/account`, {
    instance,
    portal: portalId,
    email,
    password,
    first_name,
    last_name,
    data: {
      ...data,
      consent: {
        ...data.consent,
        term_policy: 1,
        term_tos: 1,
        term_collection: 1,
        term_privacy: 1,
      },
    },
  }, jwt ? { headers: { Authorization: `Bearer ${jwt}` } } : {});
}
