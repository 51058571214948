import React from 'react';
import { foundations, View } from '@go1d/go1d';

export default () => (
  <View
    css={{
      strong: {
        fontWeight: 'bold',
      },
      p: {
        marginBottom: '.5rem',
        marginTop: '1.25rem',
      },
      li: {
        margin: '.5rem 0',
        marginTop: '1.25rem',
      },
      ul: {
        listStyleType: 'disc',
        paddingLeft: '2rem',
      },
      ol: {
        listStyleType: 'decimal',
        paddingLeft: '2rem',
      },
      h3: {
        color: foundations.colors.accent,
        fontWeight: 'bold',
        margin: '0.5rem 0',
      },
      a: {
        color: foundations.colors.accent,
      },
    }}
  >
    <p>

      The business of Go1 includes an online marketplace for e-learning
      resources (alongside software and services to produce and deliver such
      training and resources), which are made available by and through a number
      of partners, including content sharing partners, distribution partners and
      implementation partners, (all of whom are referred to as ‘affiliates’ for
      the purpose of this policy).
    </p>
    <p>

      We are passionate about lifelong learning, making learning accessible to
      people in all parts of the world and providing those people with a digital
      learning record for life. We operate in numerous countries, including
      Australia, EU countries, the US and several Asian and African countries
      and are continually expanding our global reach. We acknowledge our
      obligations to all recipients of our services and products, including
      visitors to our website at
      {' '}
      <a href="http://www.go1.com" target="_blank" rel="noopener noreferrer">www.go1.com</a>
,
      our registered users (‘learners’) and administrators of our software
      portals on behalf of learners (‘administrators’) ( collectively, ‘users’)
      to collect, manage, process and use personal information in accordance
      with the laws and regulations of each of the countries in which we
      operate, including the latest General Data Protection Regulation that
      applies to the European Union.
    </p>
    <p> Our Privacy Policy explains: </p>
    <ul>
      <li> what information we collect and why we collect it; </li>
      <li> how we collect, use and secure that information; </li>
      <li>
        the choices we offer, including how to access and update information;
        and
      </li>
      <li> how we will respond to data breaches. </li>
    </ul>
    <p>

      <strong>What we collect and why</strong>
    </p>
    <p>

      We collect personal information including the name, email address, phone
      number, country of residence and, where relevant, name of employer and
      position title. From time to time we might also collect other information
      volunteered by users (that is, entered on our system or our website or
      provided to our team) or given to us with consent of users or by use of
      cookies (e.g. IP addresses – please refer to the Cookies and log files
      heading below). We also retain information about the learning activities
      undertaken by users, including course completion. The credit card details
      provided by users, when they pay for our products and services, are passed
      directly to our payment processor and are not retained by us.
    </p>
    <p>

      The reasons we collect, store, process and use this kind of information
      include:
    </p>
    <ul>

      <li>

        to identify and authenticate users, including administrators and
        associated learners;
      </li>
      <li>

        to allow users within an organisation (e.g. within an employer) to
        communicate with each other;
      </li>
      <li>

        for support or response purposes, when users request support or make an
        inquiry;
      </li>
      <li>

        to plan, improve, tailor, optimise and promote our products and services
        for users;
      </li>
      <li>

        to provide users with relevant information and offers of courses and
        other services that we believe might be of interest to users, which we
        may do by email;
      </li>
      <li> to manage users’ accounts; </li>
      <li>

        to send users prizes, certificates or Scholarships™ to use our courses
        and other services and products;
      </li>
      <li>

        to provide learners with a lifelong digital learning record; and
      </li>
      <li>

        for contractual and other lawful legitimate business purposes or for the
        establishment, exercise or defence of legal claims.
      </li>
    </ul>
    <p>

      We do not collect more data than is necessary for the above purposes.
    </p>
    <p>

      Our affiliates and other third party service providers to Go1, which may
      include cloud based customer relationship, content management and hosting
      services and involve application programming interfaces, are also given
      access to some or all of the above information for similar purposes. For
      example:
    </p>
    <ul>

      <li>

        our course content providers may have access to names of learners
        enrolled in the providers’ courses;
      </li>
      <li>

        our distribution partners, who offer our content for sale through their
        own platforms or who otherwise integrate with our software, may collect
        and handle the same enrolment information; and
      </li>
      <li>

        our implementation partners may have access to the information we
        collect for the purpose of assisting us and our distribution partners to
        develop our systems to offer and deliver our products and services.
      </li>
    </ul>
    <p>

      All such third parties are bound by the same laws and regulations that we
      are, wherever they are located in the world, and we require them to adopt
      and apply data protection policies and practices that are consistent with
      this policy.
    </p>
    <p>

      Users need to understand that their choice not to provide certain personal
      information, as described above, will limit or prevent their ability to
      access and use our products and services.
    </p>
    <p>

      <strong>How we collect information</strong>
    </p>
    <p>

      We ordinarily ask for consent from users to collect, process, manage,
      store and use personal information, as set out in this policy, including
      when users purchase or request a trial of our products or services, as a
      prerequisite to such purchase, trial and use. This is ordinarily done
      on-line, as part of registration (including for trial purposes) or of
      course enrolment. When users ‘get in touch’ with us via the contact page
      on our website or directly to
      {' '}
      <a href="mailto:support@go1.com">support@go1.com</a>
      {' '}
they will voluntarily
      provide the kind of information set out above.
    </p>
    <p>

      <strong>How long we hold information</strong>
    </p>
    <p>

      We hold information only for so long as is necessary for the purposes set
      out above; in this, we are guided by our contractual obligations and by
      other lawful legitimate business interests. For example, we commit to
      learners that we will retain their digital learning record for life, as
      part of our service. If, at any time, a learner wishes us to delete or
      transfer their learning record, they may do so as set out below.
    </p>
    <p>

      <strong>
        Accessing, updating and deleting personal information
      </strong>
    </p>
    <p>

      Upon registration, administrators and learners have ongoing access to
      their online accounts, within which they can correct inaccuracies or
      update or add information. Additionally, they (and other users such as
      those who trial our products and services) can request us, via
      {' '}
      <a href="mailto:support@go1.com">support@go1.com</a>
      {' '}
to:
    </p>
    <ul>

      <li>

        make changes to their accounts, including to personal information;
      </li>
      <li>

        send to them, or to transmit to another controller, all of their stored
        personal information (in which case we will do so in a structured,
        commonly used and machine-readable format);
      </li>
      <li> restrict processing of specific personal information; or </li>
      <li> delete information. </li>
    </ul>
    <p>

      Similarly, users may withdraw their consent for us to hold any or all of
      their personal information by request to
      {' '}
      <a href="mailto:support@go1.com">support@go1.com</a>
      {' '}
and may unsubscribe
      to any or all emails through the automated facility sent with each email.
    </p>
    <p>

      As mentioned above, these actions or requests will limit the ability of
      users to access and use our products and services.
    </p>
    <p>

      It may be the case that, for contractual or other lawful legitimate
      business purposes, we retain and use an archived version of users’
      personal information, which may be pseudonymised, anonymised or otherwise
      de-identified.
    </p>
    <p>

      We have facilities in place to request affiliates and other third parties,
      as necessary, to amend or delete personal information held on their
      systems.
    </p>
    <p>

      <strong>Information security</strong>
    </p>
    <p>

      We work hard to protect Go1 and our users from misuse, interference, loss,
      unauthorised access, modification or disclosure of information we hold. In
      doing so, we apply technical and organisational measures to ensure a level
      of security appropriate to the risk including:
    </p>
    <ul>

      <li>

        analysing and assessing privacy and security issues, risks and impacts
        during the design and development of new features and solutions for our
        products, services and delivery platform, governed by guidelines and
        security standards for our IT developers;
      </li>
      <li>

        ensuring confidentiality, integrity, availability and resilience of
        processing systems and services (for instance, we restrict access to
        personal information to Go1 employees, contractors; affiliates and other
        third parties described above, who need the information for the purposes
        described above and who are subject to strict contractual privacy and
        confidentiality obligations);
      </li>
      <li>

        the ability to promptly restore availability and access to personal data
        in the event of an incident;
      </li>
      <li>

        regular review and testing of our information collection, storage and
        processing practices, including physical security measures, aimed to
        ensure security of data processing; and
      </li>
      <li>

        as we deem necessary, pseudonymisation and encryption (e.g. using SSL)
        of data; for example, for the purpose of transfers, when data is
        encrypted and access is restricted at rest.
      </li>
    </ul>
    <p>

      We are guided in these activities by ISO/IEC 27001:2013, the international
      standard that describes best practice for an information security
      management system and by the ‘Guide to securing personal information
      (‘Reasonable steps’ to protect personal information)’, dated January 2015,
      issued by the Office of the Australian Information Commissioner.
    </p>
    <p>

      <strong>International transfers</strong>
    </p>
    <p>

      Given that Go1 operates internationally, personal information of users may
      be transferred across national and continental borders, including for
      contractual and other lawful legitimate business purposes or at the
      request of users. Within the Go1 organisation, such international
      transfers are governed by internal controls and rules, consistent with
      this policy. For such international transfers to and from affiliates and
      other third parties, Go1 has in place enforceable agreements containing
      privacy and data protection obligations consistent with this policy.
    </p>
    <p>

      <strong>Cookies and log files</strong>
    </p>
    <p>

      We allow limited use of various technologies to collect and store
      information when users visit our website; this may include using cookies
      (i.e. a string of unique data that a website stores on a user’s computer
      and that the user’s browser provides to the website each time the user
      returns) or similar technologies to identify the user’s browser or device.
      For example, at the time of publication of this policy, we use Intercom
      (for our support tools, including chat within an organisation of learners)
      and Google analytics and mix panel (for website traffic metrics and
      analytics). This helps us to optimise our users’ experience and to
      continually improve and tailor our products and services for users.
    </p>
    <p>

      Users may set their browser to block all cookies or to indicate when a
      cookie is being set.
    </p>
    <p>

      We automatically log IP addresses, browser types and dates/times, which
      assist with our support efforts, diagnoses of user-initiated issues and
      queries, and audits for security purposes.
    </p>
    <p>

      <strong>Privacy breach</strong>
    </p>
    <p>

      We acknowledge the various data breach obligations in each of the
      jurisdictions in which we operate, including in Australia. We commit to
      promptly identify and respond to any breaches, to act to prevent harm and
      to report, if and as required, to the relevant supervisory authority and
      users.
    </p>
    <p>

      <strong>Changes to Privacy Policy</strong>
    </p>
    <p>

      Our Privacy Policy may change from time to time. We will not reduce users’
      rights under this Privacy Policy without their explicit consent. We will
      post any changes on this page and, if the changes are significant, we will
      provide a more prominent notice (which may include email notification of
      Privacy Policy changes). We will also keep prior versions of this Privacy
      Policy in an archive for review by users upon request.
    </p>
    <p>

      <strong>Complaints, inquiries and requests</strong>
    </p>
    <p>

      As mentioned above, requests to access, update, restrict or delete
      personal information can be made to
      {' '}
      <a href="mailto:support@go1.com">support@go1.com</a>
.
    </p>
    <p>

      We have appointed a Privacy Officer within Go1, to whom other inquiries
      about our Privacy Policy and data protection practices and complaints can
      be made. Our Privacy Officer can be reached by email at
      {' '}
      <a href="mailto:privacy@go1.com">privacy@go1.com</a>
      {' '}
or by writing to
      “Privacy Officer, Units 4 &amp; 5, 2908 Logan Road, Underwood, QLD 4119
      Australia”. We will gladly provide the contact details of the relevant
      supervisory authorities within the countries in which we operate. We aim
      to respond to all inquiries and complaints within 30 days.
    </p>
    <p>

      <strong>Last updated: May 23, 2018</strong>
    </p>
  </View>
);
