import * as React from 'react';
import PropTypes from 'prop-types';
import {
  View, foundations, Theme,
} from '@go1d/go1d';

import IconGo1Logo from '@go1d/go1d/build/components/Icons/Go1Logo';

const logoHeaderSizes = {
  sm: {
    height: [96, 128],
    width: [96, 128],
  },
  md: {
    height: [128, 160],
    width: [128, 160],
  },
};

const LogoHeader = ({ ...props }) => (
  <Theme.Consumer>
    {({ logo }) => (
      <View
        id="LogoHeaderWrapper"
        zIndex={50}
        marginTop={props.marginTop !== undefined ? props.marginTop : ['-64px', '-80px']}
        marginBottom={props.marginBottom !== undefined ? props.marginBottom : 6}
        width={props.size ? logoHeaderSizes[props.size].width : [128, 160]}
        height={props.size ? logoHeaderSizes[props.size].height : [128, 160]}
        boxShadow="soft"
        borderColor="soft"
        border={2}
        backgroundColor="background"
        flexDirection="row"
        justifyContent="center"
        position="relative"
        alignItems="center"
        css={{
          alignSelf: props.alignSelf || 'center',
          borderRadius: '80px',
        }}
      >
        {logo && (
          <View
            height="100%"
            width="100%"
            css={{
              verticalAlign: 'middle',
              borderRadius: '50%',
              backgroundImage: `url('${logo}')`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        )}

        {!logo && (
          <View
            position="relative"
            justifyContent="center"
            paddingY={6}
            height="100%"
            width="100%"
            alignItems="center"
            css={{
              borderRadius: '50%',
            }}
          >
            <IconGo1Logo
              color="default"
              alignItems="center"
              css={{
                position: 'relative',
                top: '4px',
                [foundations.breakpoints.sm]: {
                  width: '64px',
                  height: '64px',
                },
                [foundations.breakpoints.md]: {
                  width: '80px',
                  height: '80px',
                },
              }}
            />
          </View>
        )}
      </View>
    )}
  </Theme.Consumer>
);

LogoHeader.propTypes = {
  size: PropTypes.string,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  alignSelf: PropTypes.string,
};

export default LogoHeader;
