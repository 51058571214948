import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Provider,
  Text,
  View,
  OptionList,
  Option,
  ButtonMinimal,
  Spinner,
} from '@go1d/go1d';
import IconPlus from '@go1d/go1d/build/components/Icons/Plus';
import IconChevronRight from '@go1d/go1d/build/components/Icons/ChevronRight';
import IconGo1Logo from '@go1d/go1d/build/components/Icons/Go1Logo';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { REACT_APP_BASE_DIRECTORY, REACT_APP_WEBSITE_URL } from '../../config';
import {
  exchangeJwt, getCurrentUser, redirectToPortalWithOTT, getStoredJWT,
} from '../../services/user';
import getNested from '../../utils/getNested';
import LogoContainer from '../../components/LogoContainer';

const BaseDirectory = REACT_APP_BASE_DIRECTORY || '';

export default class Portals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingOption: null,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const user = getNested(['location', 'state', 'user'], history);
    const filteredAccounts = getNested(['location', 'state', 'filteredAccounts'], history);
    const hideCreateNewPortalFromFilter = getNested(['location', 'state', 'hideCreateNewPortal'], history) || false;
    if (user) {
      this.setState({ user, accountsSelection: filteredAccounts, hideCreateNewPortalFromFilter });
    } else {
      const jwt = getStoredJWT();
      getCurrentUser(jwt, true)
        .then(({ data }) => this.setState({ user: data, accountsSelection: data.accounts, hideCreateNewPortalFromFilter: false }))
        .catch(() => {
          history.push(`${BaseDirectory}/login`);
        });
    }
  }

  handleLogin = (account) => {
    const { storedPortalValues } = this.props;
    const redirectUrl = account.instance && account.instance.type === 'team' ? `${REACT_APP_WEBSITE_URL}/home` : storedPortalValues.redirectUrl;
    this.setState({ loadingOption: account.id });
    const jwt = getStoredJWT();

    exchangeJwt(jwt, account.instance_name).then(({ data }) => {
      localStorage.setItem('jwt', data.jwt);
      redirectToPortalWithOTT(account.id, data.jwt, redirectUrl).catch(() => {
        this.setState({ loadingOption: null });
      });
    }).catch(() => {
      this.setState({ loadingOption: null });
    });
  }

  handleLogout = () => {
    const { history } = this.props;
    history.push(`${BaseDirectory}/logout`);
  }

  handleNewPortal = () => {
    const { history } = this.props;
    history.push(`${BaseDirectory}/signup`);
  }

  portalOption = (account, loadingOption) => (
    <Option
      key={account.id}
      onClick={() => this.handleLogin(account)}
      action={IconChevronRight}
      disabled={account.status !== true || (loadingOption && loadingOption !== account.id)}
      title={(
        <View flexDirection="row" position="relative" alignItems="center" width={['100%', '90%']}>
          <View width="100px" marginRight={4} display={['none', 'inline-block']} alignItems="center">
            {account.instance.files && account.instance.files.logo ? (
              <View
                element="img"
                height="auto"
                maxHeight="65px"
                width="auto"
                maxWidth="100px"
                marginX="auto"
                src={account.instance.files.logo}
              />
            ) : (
              <IconGo1Logo
                color="default"
                size={7}
                marginX="auto"
              />
            )}
          </View>
          <View flexDirection="column" width={['100%', '80%']}>
            <Text ellipsis="true">
              {getNested(['instance', 'configuration', 'site_name'], account)}
            </Text>
            <Text
              ellipsis="true"
              fontSize={1}
              color="subtle"
            >
              {getNested(['instance', 'configuration', 'domain'], account) ? account.instance.configuration.domain : account.instance_name}
            </Text>
          </View>
        </View>
      )}
    />
  );

  render() {
    const {
      user, loadingOption, accountsSelection, hideCreateNewPortalFromFilter,
    } = this.state;
    const { storedPortalValues } = this.props;
    const { hideLogout, hideCreateNewPortal } = storedPortalValues;

    if (!user) {
      return (
        <View height="100vh" justifyContent="center" alignItems="center">
          <Spinner size={6} />
        </View>
      );
    }

    const arrLength = accountsSelection.length;
    if (arrLength > 250) {
      accountsSelection.splice(0, arrLength - 250);
    }

    const { avatar } = user;

    return (
      <Provider linkComponent={RouterLink} logo={avatar && avatar.uri}>
        <Container paddingBottom={4} contain="wide" alignItems="center">
          <Helmet>
            <title>Portals</title>
          </Helmet>
          <Container contain="narrow" marginTop={6} marginBottom={8}>
            <LogoContainer title="My Portals">
              <View marginTop={4}>
                <OptionList>
                  {accountsSelection && accountsSelection
                    .filter(account => account.instance && account.instance_name !== 'public.mygo1.com')
                    .sort((a, b) => b.login - a.login)
                    .map(account => this.portalOption(account, loadingOption))
                  }
                </OptionList>
                {(!hideCreateNewPortalFromFilter && !hideCreateNewPortal) && (
                <View>
                  <View
                    element="hr"
                    marginY={5}
                    border={0.5}
                    borderColor="faded"
                  />
                  <View position="relative" alignItems="center" marginX="auto" backgroundColor="background" paddingX={5} css={{ top: '-32px' }}>OR</View>

                  <Option
                    title="Create a new portal"
                    actionIcon={IconPlus}
                    onClick={this.handleNewPortal}
                  />
                </View>
                )}
              </View>
            </LogoContainer>
            {!hideLogout && (
              <View marginTop={6} flexDirection="row" justifyContent="space-around">
                <ButtonMinimal onClick={this.handleLogout}>Logout</ButtonMinimal>
              </View>
            )}
          </Container>
        </Container>
      </Provider>
    );
  }
}

Portals.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  storedPortalValues: PropTypes.objectOf(PropTypes.any),
};
