import { createReducer } from '../../utils';
import { PACKAGES } from '../../actions/packages';
import isPackageFree from '../../utils/package';

const initialState = {
  packages: [],
  selectedPlanId: 'year',
  selectedPackageIds: [],
};

export const actionHandlers = {
  [PACKAGES.STORE_PACKAGES]: (state, action) => {
    const { selectedPackageIds } = state;
    if (action.payload.packages) {
      action.payload.packages.forEach(pack => {
        if (isPackageFree(pack)) {
          selectedPackageIds.push(pack.id);
        }
      });
    }
    if (action.payload.selectedPackageIds) {
      action.payload.selectedPackageIds.forEach(pack => {
        selectedPackageIds.push(pack);
      });
    }
    return Object.assign({}, state, { ...action.payload, selectedPackageIds });
  },
  [PACKAGES.ADD_PACKAGE]: (state, action) => {
    const { selectedPackageIds } = state;
    selectedPackageIds.push(action.payload);
    return Object.assign({}, state, { selectedPackageIds });
  },
  [PACKAGES.REMOVE_PACKAGE]: (state, action) => {
    const { selectedPackageIds } = state;
    return Object.assign({}, state, { selectedPackageIds: selectedPackageIds.filter(id => id !== action.payload) });
  },
  [PACKAGES.SELECT_PACKAGE_PLAN]: (state, action) => Object.assign({}, state, { selectedPlanId: action.payload }),
  [PACKAGES.UPDATE_PACKAGE]: (state, action) => {
    const pack = action.payload;
    const { packages } = state;
    const newPackages = packages.map((p) => {
      if (p.id === pack.id) {
        return pack;
      }
      return p;
    });
    return Object.assign({}, state, { packages: newPackages });
  },
};

export default createReducer(initialState, actionHandlers);
