import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import {
  Route, BrowserRouter as Router, Redirect, Switch,
} from 'react-router-dom';
import get from 'lodash/get';
import map from 'lodash/map';
import QueryString from 'query-string';
import {
  View, NotificationContainer, globalCSS, Provider,
} from '@go1d/go1d';
import {
  REACT_APP_API_URL,
  REACT_APP_BASE_DIRECTORY,
  REACT_APP_STRIPE_TOKEN,
} from './config';
import { extractSubDomain } from './utils';

import getPortalConfig from './services/portal';
import getAccountCustomFields from './services/eck';

import Signup from './app/Signup';
import ChoosePlanContainer from './app/ChoosePlan';
import AuthorDetailsContainer from './app/AuthorDetails';
import PaymentContainer from './app/Payment';
import PurchaseSubscriptions from './app/PurchaseSubscriptions';
import LoginContainer from './app/Login';
import LogoutContainer from './app/Logout';
import PortalsContainer from './app/Portals';
import CreatePortalContainer from './app/CreatePortal';
import ResetPasswordContainer from './app/ResetPassword';
import SetNewPasswordContainer from './app/SetNewPassword';
import WhosLearningContainer from './app/WhosLearning';
import WhatToLearnContainer from './app/WhatToLearn';
import Page404 from './app/Page404';
import ScrollToTop from './components/ScrollToTop';
import PolicyPageLayout from './app/Policy';
import ChooseLearning from './app/ChooseLearning';
import PackageOverView from './app/PackageOverview';
import { genericHosts } from './utils/host';
import { identify, setContext as setBeamContext } from './services/houston';

import './App.scss';


const BaseDirectory = REACT_APP_BASE_DIRECTORY || '';

globalCSS();

class App extends Component {
  static propTypes = {
    dispatchPortalValues: PropTypes.func.isRequired,
  }

  state = {
    primaryColor: null,
    backgroundImage: null,
    logo: null,
    allowRegister: true,
    siteName: 'Go1',
    loadingTheme: true,
  }

  async componentDidMount() {
    const { dispatchPortalValues } = this.props;
    const host = window.location && !genericHosts.includes(window.location.hostname) ? window.location.hostname : null;
    const query = window.location && window.location.search ? QueryString.parse(window.location.search) : null;
    const domain = host || (query && query.portal);
    // Since querystrings are... well... strings, we convert it to a boolean this way
    const ignorePortal = get(query, 'ignore_portal', 'false').toLowerCase() !== 'false';
    const portalSelectionFilter = (query && query.portal_selection_filter) || false;
    const adminOnly = (query && query.admin_only === 'true') || false;
    const hideCreateNewPortal = (query && query.hide_create_new_portal === 'true') || false;
    const hideLogout = (query && query.hide_logout === 'true') || false;

    const referral = get(query, 'referral', null);

    const defaultPortalValues = { referral };


    if (domain) {
      try {
        const res = await getPortalConfig(domain);
        const siteDomain = get(res, 'title', domain);
        const logo = get(res, 'files.logo', null);
        const contentPackageSignupFlow = get(res, 'configuration.content_package_signup_flow');
        const enableLearnerSignupFlow = get(res, 'configuration.enable_learner_signup_flow');
        const portalId = get(res, 'id');
        const publicKey = get(res, 'data.uuid', null);
        const allowRegister = get(res, 'configuration.modulesEnabled.allowRegister', true);
        const signupIntoExisting = (query && query.signupIntoExisting === 'true') || (!contentPackageSignupFlow && !enableLearnerSignupFlow && !referral && allowRegister);
        const alternativeLogoutUrl = get(res, 'configuration.landing_logout_page', null);
        const alternativeLoginUrl = get(res, 'configuration.landing_login_page', null);
        const simplifiedTermsAgreement = get(res, 'configuration.simplified_terms_agreement', null);

        const configuration = {
          ...defaultPortalValues,
          ...contentPackageSignupFlow && {
            signupUnitOptions: map(get(res, 'configuration.signup_unit_options'), option => option.value),
            creditCardLegalDisclaimer: get(res, 'configuration.credit_card_legal_disclaimer'),
            portalUrlHelpText: get(res, 'configuration.portal_url_help_text'),
            noFocusTerms: get(res, 'configuration.no_focus_terms'),
            preselectedPackages: get(res, 'configuration.preselect_packages'),
            disablePackageOverview: get(res, 'configuration.disable_package_overview'),
            companySizeStepper: get(res, 'configuration.company_size_stepper'),
            portalCreationTagline: get(res, 'configuration.portal_creation_tagline'),
            portalCreationSecondaryTagline: get(res, 'configuration.portal_creation_secondary_tagline'),
          },
          ...enableLearnerSignupFlow && {
            signupUnitOptions: ['individual'],
            creditCardLegalDisclaimer: get(res, 'configuration.credit_card_legal_disclaimer'),
            portalUrlHelpText: get(res, 'configuration.portal_url_help_text'),
            noFocusTerms: get(res, 'configuration.no_focus_terms'),
            preselectedPackages: get(res, 'configuration.preselect_packages'),
            disablePackageOverview: get(res, 'configuration.disable_package_overview'),
            companySizeStepper: get(res, 'configuration.company_size_stepper'),
            portalCreationTagline: get(res, 'configuration.portal_creation_tagline'),
            portalCreationSecondaryTagline: get(res, 'configuration.portal_creation_secondary_tagline'),
          },
          customDomain: (get(res, 'configuration.child_portal_domain_suffix',
            extractSubDomain(get(res, 'configuration.primary_domain')))),
          logo,
          portalId,
          publicKey,
          redirectUrl: get(query, 'redirect_url', ''),
          portalSelectionFilter,
          adminOnly,
          hideCreateNewPortal,
          hideLogout,
          ignorePortal,
          contentPackageSignupFlow,
          enableLearnerSignupFlow,
          siteName: get(res, 'configuration.site_name'),
          siteDomain,
          domain,
          signupTagline: get(res, 'configuration.signup_tagline', null),
          signupMessage: get(res, 'configuration.signup_message', null),
          signupSecondaryTagline: get(res, 'configuration.signup_secondary_tagline', null),
          loginTagline: get(res, 'configuration.login_tagline', null),
          loginSecondaryTagline: get(res, 'configuration.login_secondary_tagline', null),
          // Allow people to signup for an existing portal, activated via the signupIntoExisting parameter
          ...signupIntoExisting && {
            signupIntoExistingPortal: true,
            signupUnitOptions: ['individual'],
          },
          customFields: [],
          simplifiedTermsAgreement,
          dataLoaded: true,
        };
        this.setState({
          logo,
          primaryColor: get(res, 'data.theme.primary', null),
          backgroundImage: get(res, 'files.login_background', null),
          portal: siteDomain,
          allowRegister,
          ssoAllowLogin: get(res, 'configuration.integrations.sso.data.passwordAllowed', true),
          siteName: get(res, 'configuration.site_name'),
          ssoButtons: get(res, 'configuration.sso_button', null),
          loadingTheme: false,
          alternativeLogoutUrl,
          alternativeLoginUrl,
          domain,
        });

        // store in Redux for using later
        dispatchPortalValues(configuration);

        if (configuration.signupIntoExistingPortal || configuration.enableLearnerSignupFlow) {
          getAccountCustomFields(siteDomain).then((response) => {
            if (response.fields) {
              const items = Object.keys(response.fields).map(key => response.fields[key]);
              items.sort((a, b) => a.weight > b.weight);
              dispatchPortalValues({
                customFields: items,
              });
            }
          });
        }
      } catch (_err) {
        this.setState({ loadingTheme: false });
      }
    } else {
      dispatchPortalValues({
        ...defaultPortalValues,
        signupUnitOptions: ignorePortal ? ['individual'] : undefined,
        redirectUrl: get(query, 'redirect_url', ''),
        ignorePortal,
        adminOnly,
        hideCreateNewPortal,
        hideLogout,
        portalSelectionFilter,
        dataLoaded: true,
      });
      this.setState({
        loadingTheme: false,
      });
    }
  }

  componentDidUpdate() {
    setBeamContext();
    identify();
  }

  /* eslint-disable react/sort-comp */
  /* eslint-disable no-param-reassign */
  injectStripeScript = ({ scriptTags }) => {
    if (scriptTags) {
      scriptTags[0].onload = this.handleStripeHelmetInjection;
    }
  }

  handleStripeHelmetInjection = () => {
    const windowStripe = window.Stripe;
    if (windowStripe) {
      const stripePublicKey = REACT_APP_STRIPE_TOKEN
        || (REACT_APP_API_URL === 'https://api.go1.co' ? 'pk_live_BdjpUX7iAZSnxa4iowq35mly' : 'pk_test_tHhXNXRSMSKAQ5gTccIHGjhT');

      windowStripe.setPublishableKey(stripePublicKey);
    }
  }

  render() {
    const {
      domain, primaryColor, backgroundImage, logo, portal, allowRegister, siteName, loadingTheme, ssoButtons, ssoAllowLogin, alternativeLogoutUrl, alternativeLoginUrl,
    } = this.state;
    const query = get(window, 'location.search', '');
    const searchQuery = QueryString.parse(query);
    const referral = searchQuery.referral || null;

    return (
      <Provider accent={primaryColor} logo={logo}>
        {!loadingTheme
        && (
        <View
          backgroundColor="faint"
          css={{
            backgroundImage: `url(${backgroundImage})` || null,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
            minHeight: '100vh',
          }}
        >
          <Helmet
            script={[{ src: 'https://js.stripe.com/v2/' }]}

            // Helmet doesn't support `onload`
            onChangeClientState={(newState, addedTags) => this.injectStripeScript(addedTags)}
          />

          <Router>
            <ScrollToTop>
              <Switch>
                <Route
                  exact
                  path={`${BaseDirectory}/`}
                  component={() => (<Redirect to={`${BaseDirectory}/signup${query}`} />)}
                />
                <Route
                  path={`${BaseDirectory}/signup`}
                  exact
                  render={(props) => {
                    if (allowRegister !== true && referral === null) {
                      return (<Redirect to={`${BaseDirectory}/login${query}`} />);
                    }
                    return (<Signup {...props} />);
                  }}
                />
                <Route
                  path={`${BaseDirectory}/login`}
                  exact
                  render={props => {
                    // If alternative login URL is set, redirect immediately. Check for recursive redirection
                    if (alternativeLoginUrl && !alternativeLoginUrl.match(`${window.location.host}${BaseDirectory}/login`)) {
                      window.location.assign(alternativeLoginUrl);
                      return <></>;
                    }
                    return (
                      <LoginContainer
                        siteName={siteName}
                        portal={portal}
                        allowRegister={allowRegister}
                        logo={logo}
                        ssoButtons={ssoButtons}
                        ssoAllowLogin={ssoAllowLogin}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  path={`${BaseDirectory}/portals`}
                  exact
                  component={(props) => <PortalsContainer {...props} nextUrl={`${BaseDirectory}/`} />}
                />
                <Route
                  path={`${BaseDirectory}/logout`}
                  exact
                  component={(props) => <LogoutContainer ssoAllowPasswordLogin={ssoAllowLogin} alternativeLogoutUrl={alternativeLogoutUrl} {...props} />}
                />
                <Route
                  path={`${BaseDirectory}/reset_password`}
                  exact
                  component={(props) => <ResetPasswordContainer domain={domain} {...props} />}
                />
                <Route
                  path={`${BaseDirectory}/set_new_password/:one_time_token`}
                  exact
                  component={SetNewPasswordContainer}
                />
                <Route
                  path={`${BaseDirectory}/choose`}
                  exact
                  component={ChoosePlanContainer}
                />
                <Route
                  path={`${BaseDirectory}/create`}
                  component={CreatePortalContainer}
                />
                <Route
                  path={`${BaseDirectory}/payment`}
                  component={PaymentContainer}
                />
                <Route
                  exact
                  path={`${BaseDirectory}/purchase_subscriptions`}
                  component={PurchaseSubscriptions}
                />
                <Route
                  path={`${BaseDirectory}/author_details`}
                  exact
                  component={AuthorDetailsContainer}
                />
                <Route
                  path={`${BaseDirectory}/whos_learning`}
                  component={WhosLearningContainer}
                />
                <Route
                  path={`${BaseDirectory}/what_to_learn`}
                  component={WhatToLearnContainer}
                />
                <Route
                  path={`${BaseDirectory}/policy`}
                  render={props => (<PolicyPageLayout siteName={siteName} portal={portal} {...props} />)}
                />
                <Route
                  exact
                  path={`${BaseDirectory}/choose_learning`}
                  component={ChooseLearning}
                />
                <Route
                  exact
                  path={`${BaseDirectory}/package_overview/:packageId`}
                  component={PackageOverView}
                />
                <Route path="*" component={Page404} />
              </Switch>
            </ScrollToTop>
          </Router>
          <NotificationContainer />
        </View>
        )
        }
      </Provider>
    );
  }
}

export default App;
