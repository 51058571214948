const GO1 = window.GO1 || {};

/* eslint-disable prefer-destructuring */
export const REACT_APP_API_URL = GO1.REACT_APP_API_URL;
export const REACT_APP_INSTANCE = GO1.REACT_APP_INSTANCE;
export const REACT_APP_PUBLIC_PORTAL = GO1.REACT_APP_PUBLIC_PORTAL;
export const REACT_APP_ENV = GO1.REACT_APP_ENV;
export const REACT_APP_BASE_DIRECTORY = GO1.REACT_APP_BASE_DIRECTORY;
export const REACT_APP_STRIPE_TOKEN = GO1.REACT_APP_STRIPE_TOKEN;
export const REACT_APP_BEAM_URL = GO1.REACT_APP_BEAM_URL;
export const REACT_APP_WEBSITE_URL = GO1.REACT_APP_WEBSITE_URL;
export const REACT_APP_PORTAL_ROOT_DOMAIN = GO1.REACT_APP_PORTAL_ROOT_DOMAIN || 'mygo1.com';
export const REACT_APP_USE_COOKIE_SESSION = GO1.REACT_APP_USE_COOKIE_SESSION;
