import React from 'react';
import {
  Container,
  foundations,
  Link,
  View,
  Text,
} from '@go1d/go1d';
import IconGo1Logo from '@go1d/go1d/build/components/Icons/Go1Logo';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';

const Page404 = () => (
  <Container
    contain="full"
    height={600}
    justifyContent="center"
    css={{
      [foundations.breakpoints.sm]: {
        height: 400,
      },
    }}
  >
    <Header />
    <Helmet>
      <title>GO1 - 404 | Page not found</title>
    </Helmet>
    <View flexDirection="row" justifyContent="center">
      <Text
        color="default"
        fontSize={6}
        fontWeight="semibold"
        lineHeight="paragraph"
        css={{
          [foundations.breakpoints.sm]: {
            marginTop: foundations.spacing[2],
          },
        }}
      >
        {"Uh oh, that's a no"}
      </Text>
      <IconGo1Logo
        color="default"
        size={6}
        marginTop={4}
        marginLeft={3}
      />
    </View>
    <View flexDirection="row" alignItems="center" justifyContent="center">
      <Text
        color="subtle"
        fontSize={3}
        lineHeight="paragraph"
        textAlign="center"
      >
        We can’t seem to find the page you’re looking for.
        <br />
        {' '}
Try going
        {' '}
        <Link href="/"><Text color="accent" fontSize={3}>home</Text></Link>
, or ask for
        {' '}
        <Link href="/help"><Text color="accent" fontSize={3}>help</Text></Link>
.
      </Text>
    </View>
  </Container>
);

export default Page404;
