import * as React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import creditCardType, { types as CardType } from 'credit-card-type';
import { chunk, head } from 'lodash';
import {
  Banner,
  Field,
  foundations,
  Text,
  TextInput,
  View,
} from '@go1d/go1d';

import IconCreditCard from '@go1d/go1d/build/components/Icons/CreditCard';
import IconVisa from '@go1d/go1d/build/components/Icons/Visa';
import IconMastercard from '@go1d/go1d/build/components/Icons/Mastercard';
import IconAmex from '@go1d/go1d/build/components/Icons/Amex';
import IconLock from '@go1d/go1d/build/components/Icons/Lock';

const PaymentField = ({ format, ...fieldProps }) => {
  if (format) {
    return <NumberFormat {...fieldProps} format={format} customInput={TextInput} size="lg" />;
  }

  return <TextInput {...fieldProps} size="lg" />;
};

class PaymentForm extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
  };

  state = {
    creditCard: {},
  };

  cardNumber = (val) => {
    const numbersArray = String(val || '').split('');
    const numbersGroup = (chunk(numbersArray, 4) || []).map(group => group.join(''));

    return numbersGroup.join(' ');
  }

  cardExpiry = (value) => {
    const expiryString = String(value);
    const month = this.formatExpiryMonth(expiryString.substring(0, 2), '12');
    const year = expiryString.substring(2, 4);

    return `${month}${year.length ? `/${year}` : ''}`;
  }

  formatExpiryMonth = (value, max) => {
    let expiryMonth = value;

    if (expiryMonth.length === 1 && expiryMonth[0] > max[0]) {
      expiryMonth = `0${expiryMonth}`;
    }

    if (expiryMonth.length === 2) {
      if (Number(expiryMonth) === 0) {
        expiryMonth = '01';

        // this can happen when user paste number
      } else if (expiryMonth > max) {
        expiryMonth = max;
      }
    }

    return expiryMonth;
  }

  createStripeToken = card => new Promise((resolve, reject) => {
    window.Stripe.card.createToken(card, (status, data) => {
      if (status <= 204) {
        resolve(data.id);
      } else {
        /* eslint-disable prefer-promise-reject-errors */
        reject('There is something went wrong, please try again or contact us for assistance.');
      }
    });
  });

  onChange = ({ values }) => {
    const creditCard = head(creditCardType(values.number));
    this.setState({ creditCard });
  }

  onValidate = (values) => {
    const { Stripe: windowStripe } = window;
    const {
      number: cardNumber,
      expiry: cardExpiry,
      cvc: cardCvc,
      name: cardName,
    } = values;
    const errors = {};

    const inputCard = String(cardNumber).split(' ').join('');
    if (!cardNumber) {
      errors.number = 'Please enter a card number';
    } else if (windowStripe && !windowStripe.card.validateCardNumber(inputCard)) {
      errors.number = 'Please enter a valid card number';
    }

    const [cardExpiryMonth, cardExpiryYear] = cardExpiry ? cardExpiry.split('/') : ['', ''];
    if (!cardExpiry) {
      errors.expiry = 'Please enter an expiry';
    } else if (windowStripe && !windowStripe.card.validateExpiry(cardExpiryMonth || '', cardExpiryYear || '')) {
      errors.expiry = 'Please enter a valid expiry';
    }

    if (!cardCvc) {
      errors.cvc = 'Please enter a CVV';
    } else if (windowStripe && !windowStripe.card.validateCVC(cardCvc)) {
      errors.cvc = 'Please enter a valid CVV';
    }

    if (!cardName) {
      errors.name = 'Please enter a name on card';
    }

    return errors;
  }

  onSubmit = (values) => {
    const cardInformation = {
      name: values.name,
      number: String(values.number).split(' ').join(''),
      cvc: values.cvc,
      exp_month: values.expiry.split('/')[0] || '',
      exp_year: values.expiry.split('/')[1] || '',
    };

    return this.createStripeToken(cardInformation);
  }

  render() {
    const { creditCard } = this.state;
    const { errorMessage } = this.props;
    const isAmexCard = creditCard && creditCard.type === CardType.AMERICAN_EXPRESS;

    return (
      <React.Fragment>
        {errorMessage && (
          <Banner type="danger" marginBottom={6}>
            <Text fontWeight="semibold">
              {errorMessage}
            </Text>
          </Banner>
        )}
        <View border={1} borderColor="faded" borderRadius={3} backgroundColor="faint" color="contrast" padding={5}>
          <View flexDirection="row" justifyContent="space-between" marginBottom={2}>
            <View
              alignItems="center"
              color="subtle"
              flexDirection="row"
              css={{
                [foundations.breakpoints.md]: {
                  justifyContent: 'space-between',
                  width: '100%',
                },
              }}
            >
              <Text fontSize={2} color="default" fontWeight="semibold">
                Card details
              </Text>

              <IconLock
                size={3}
                marginLeft={3}
                position="relative"
                css={{
                  bottom: foundations.spacing[1],
                }}
              />
            </View>

            <View
              flexShrink={1}
              flexGrow={1}
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              color="subtle"
              css={{
                [foundations.breakpoints.md]: {
                  display: 'none',
                },
              }}
            >
              <IconVisa size={3} marginRight={2} />
              <IconMastercard size={3} marginRight={2} />
              <IconAmex size={3} />
            </View>
          </View>

          <View>
            <Field
              id="cardNumber"
              type="text"
              name="number"
              label="Card number"
              placeholder="**** **** **** ****"
              format={this.cardNumber}
              component={PaymentField}
              hideLabel
              floating
              required
              requiredText=""
              borderColor="faded"
              maxLength={19} // 16 digits + 3 spaces
              suffixNode={(
                <View
                  flexDirection="row"
                  color="subtle"
                  css={{
                    [foundations.breakpoints.sm]: {
                      display: 'none',
                    },
                  }}
                >
                  <IconVisa size={3} marginRight={2} />
                  <IconMastercard size={3} marginRight={2} />
                  <IconAmex size={3} marginRight={4} />
                </View>
              )}
              viewCss={{
                borderRadius: `${foundations.spacing[2]}px`,
              }}
            />
          </View>

          <View flexDirection="row">
            <View width={[1 / 2, 1 / 2, 1 / 2]} paddingRight={2}>
              <Field
                id="cardExpiry"
                type="text"
                name="expiry"
                label="Expiry"
                placeholder="MM/YY"
                hideLabel
                floating
                required
                component={PaymentField}
                format={this.cardExpiry}
                requiredText=""
                borderColor="faded"
                viewCss={{
                  borderRadius: `${foundations.spacing[2]}px`,
                }}
              />
            </View>

            <View width={[1 / 2, 1 / 2, 1 / 2]} paddingLeft={2}>
              <Field
                id="cardCVC"
                type="text"
                name="cvc"
                label="CVV"
                hideLabel
                floating
                component={PaymentField}
                required
                requiredText=""
                placeholder={isAmexCard ? '****' : '***'}
                format={isAmexCard ? '####' : '###'}
                mask=""
                suffixNode={(
                  <View flexDirection="row" color="subtle">
                    <IconCreditCard size={3} marginRight={4} />
                  </View>
                )}
                borderColor="faded"
                viewCss={{
                  borderRadius: `${foundations.spacing[2]}px`,
                }}
                css={{
                  minWidth: 0,
                }}
              />
            </View>
          </View>

          <View marginTop={5}>
            <Field
              required
              requiredText=""
              id="cardName"
              type="text"
              name="name"
              label="Name on card"
              errorForLabel
              borderRadius={2}
              borderColor="faded"
              component={PaymentField}
              viewCss={{
                borderRadius: `${foundations.spacing[2]}px`,
              }}
            />
          </View>
        </View>
      </React.Fragment>
    );
  }
}

export default PaymentForm;
