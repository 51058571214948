import React from 'react';
import { foundations, View, Text } from '@go1d/go1d';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import go1Terms from '../../../constants/go1Terms';
import { REACT_APP_BASE_DIRECTORY } from '../../../config';

export default () => {
  const searchQuery = get(window, 'location.search');
  const baseDirectory = REACT_APP_BASE_DIRECTORY || '';
  const privacyLink = `${baseDirectory}/policy/privacy-policy${searchQuery}`;
  const acceptableUseLink = `${baseDirectory}/policy/acceptable-use${searchQuery}`;
  const customerTerms = `${baseDirectory}/policy/customer-terms${searchQuery}`;

  return (
    <View
      css={{
        strong: {
          fontWeight: 'bold',
        },
        p: {
          marginBottom: '.5rem',
          marginTop: '1.25rem',
        },
        li: {
          margin: '.5rem 0',
          marginTop: '1.25rem',
        },
        ul: {
          listStyleType: 'disc',
          paddingLeft: '2rem',
        },
        ol: {
          listStyleType: 'decimal',
          paddingLeft: '2rem',
        },
        h3: {
          color: foundations.colors.accent,
          fontWeight: 'bold',
          margin: '0.5rem 0',
        },
        h1: {
          color: foundations.colors.subtle,
          marginBottom: '.5rem',
          marginTop: '1.25rem',
          fontSize: foundations.spacing[5],

        },
        a: {
          color: foundations.colors.accent,
        },
        tab: {
          marginRight: '1.25rem',
        },
        tab2: {
          marginRight: '2rem',
        },
      }}
    >
      <h1>
        <tab2>
          1.
        </tab2>
        Application
      </h1>
      <p>
        <strong>From the word GO</strong>
      </p>
      <li>
        <tab>
          1.1
        </tab>
        These terms and conditions
        <Text fontWeight="semibold">(“User Terms”)</Text>
        govern your access and use of our Services (as defined at the end of this page/document).  These User Terms will apply (including to all Services) from the beginning of our relationship with
        you as the User, which occurs as soon as you access any of the Services. Please read these User Terms carefully, as they are legally binding upon you.
      </li>
      <tab>
        1.2
      </tab>
      When reference is made to “we” or “us” or “our” in these User Terms, it means Go1.
      <li>
        <tab>
          1.3
        </tab>
        As a User, you may also be a Customer (as defined at the end of these User Terms) in which case, you must read and accept the
        {' '}
        <Link to={customerTerms}>Customer Terms of Service.</Link>
        {' '}
        If you are joining us through one of your associations or on your own, then you
        are both the Customer and the User.
      </li>
      <li>
        <tab>
          1.4
        </tab>
        Alternatively, you may be using our Services through a Customer, who has already accepted our Customer Terms of Service.  If this is the case, you also agree to follow all lawful and reasonable directions given by that Customer with
        respect to your use of the Services, in accordance with that Customer’s obligations to us. An organisation (such as your employer) , or other third party, or you through your association, or on your own, (“Customer”) have obtained
        permission to access our Services via our Website or App. If you are accessing our Services from one of your employer’s platform or have downloaded the App from your employer’s app marketplace, then the Customer is your employer.
      </li>
      <li>
        <tab>
          1.5
        </tab>
        Heading are used for convenience only and do not affect the interpretation of these User Terms.
      </li>
      <li>
        <tab>
          1.6
        </tab>
        The capitalised terms used are defined at the end of these User Terms or may otherwise be found in the
        {' '}
        <Link to={customerTerms}>Customer Terms of Service.</Link>
        {' '}
      </li>
      <li>
        <tab>
          1.7
        </tab>
        Any version of these User Terms in a language other than English is provided for convenience only and you understand and agree that the English language version will control if there is any conflict.
      </li>
      <p>
        <strong>
          <tab>
          2.
          </tab>
          Services and support
        </strong>
      </p>
      <p>
        <strong>
          Good to GO
        </strong>
      </p>
      <li>
        <tab>
          2.1
        </tab>
        Subject to these User Terms, we will use commercially reasonable efforts to provide you with the Services. As part of the registration process, you will identify an administrative user name and password for your Go1 account. We
        reserve the right to refuse registration of, or cancel passwords we deem inappropriate.
      </li>
      <li>
        <tab>
          2.2
        </tab>
        Subject to these User Terms, we will provide reasonable technical support services in accordance with our standard practice.
      </li>
      <li>
        <tab>
          2.3
        </tab>
        The Services may also extend to any related items quoted and billed by us.
      </li>
      <li>
        <tab>
          2.4
        </tab>
        We may modify the Services or discontinue their availability at any time.
      </li>
      <p>
        <strong>
          <tab>
            3.
          </tab>
            Restrictions and responsibilities
        </strong>
      </p>
      <p>
        <strong>
          GO and no GO
        </strong>
      </p>
      <li>
        <tab>
          3.1
        </tab>
        You acknowledge that you have read our
        {' '}
        <Link to={acceptableUseLink}>Acceptable Use Policy</Link>
        {' '}
        and
        {' '}
        <Link to={privacyLink}>Privacy Policy</Link>
        {' '}
        and that you agree with the content of and to be bound by both the
        {' '}
        <Link to={acceptableUseLink}>Acceptable Use Policy</Link>
        {' '}
        and
        {' '}
        <Link to={privacyLink}>Privacy Policy</Link>
        {' '}
        , which are incorporated by reference into these User Terms.
      </li>
      <li>
        <tab>
          3.2
        </tab>
        Customer has separately agreed to our Customer Terms of Service or entered into a written agreement with us (in either case, the “Contract”) that permitted Customer to create and configure a platform or make our App available on
        their app marketplace, so that you and others could join (each invitee granted access to the Services, including you, is a “User”). The Contract contains our commitment to deliver the Services to Customer, who may then invite Users
        to join its platform(s). When a User (including, you) submits Customer Data (including content or information to the Services, such as messages or files), you acknowledge and agree that the Customer Data is owned by Customer and
        the Contract provides Customer with many choices and control over that Customer Data. For example, Customer may provision or deprovision access to the Services, enable or disable third party integrations manage permissions,
        retention and export settings, transfer or assign platform instance, share channels, or consolidate your instance or channels with other instance or channels, and these choices and instructions may result in the access, use,
        disclosure, modification or deletion of certain or all Customer Data.
      </li>
      <li>
        <tab>
          3.3
        </tab>
        You represent, warrant and covenant that you are over the age of 18, or, if not, you will only use the Services with the involvement, supervision and approval of a parent or legal guardian (children under the age of 16 must not
        register for an account or register for or use Courses).
      </li>
      <li>
        <tab>
        3.4
        </tab>
        You understand and agree that:
      </li>
      <ul>
        <li>
          the Website, special technology used in connection with the Website, the App, the Services, the Software, the Courses and the Materials are provided by us or third-party providers (including Content Providers) and are the
          copyrighted works of Go1 and/or such third parties;
        </li>
        <li>
          Content Providers may choose to terminate their relationships with us and become inactive (we do not guarantee Content Provider or Course availability and we are not liable for any issues relating to Content Provider or Course
          availability);
        </li>
        <li>
          you assume any and all risks from any meetings or contact you have with any Content Providers or Customers or other Users; and
        </li>
        <li>
          you are responsible for your account with us and for all uses of the account or the Equipment, with or without your knowledge or consent.
        </li>
      </ul>
      <p>
        <strong>
          <tab>
          4.
          </tab>
          Confidentiality; proprietary rights
        </strong>
      </p>
      <p>
        <strong>
          GO confidentially
        </strong>
      </p>
      <li>
        <tab>
        4.1
        </tab>
        You agree to take reasonable precautions to protect our non-public information regarding features, functionality and performance of the Services and Go1’s Intellectual Property (“Go1 Proprietary Information”) and to comply with all
        lawful and reasonable directions given to you by the Customer with respect to Go1 Proprietary Information.
      </li>
      <li>
        <tab>
        4.2
        </tab>
        We own and retain all right, title and interest in and to (a) the Services and Software (including all improvements, enhancements or modifications to the Services and Software), (b) any software, applications, inventions or other
        technology developed in connection with Implementation Services or support, (c) the Materials, and (d) all Intellectual Property in or related to any of the foregoing.
      </li>
      <li>
        <tab>
        4.3
        </tab>
        Unless otherwise expressly provided in these User Terms, Content remains the proprietary property of the person or entity supplying it (or their affiliated and/or third party providers and suppliers, as is relevant) and is
        protected, without limitation, pursuant to Australian, United States, United Kingdom, European Union and other applicable copyright and other intellectual property laws.
      </li>
      <li>
        <tab>
        4.4
        </tab>
        Go1 has not agreed to and does not agree to treat as confidential any suggestion or idea provided by you (“Feedback”) and nothing in this Agreement or in the parties’ dealings arising out of or related to this Agreement will
        restrict Go1’s right to use, profit from, disclose, publish or otherwise exploit any Feedback, without compensation to you.
      </li>
      <li>
        <tab>
        4.5
        </tab>
        We have granted a limited, non-exclusive, non-transferable license for you to access and use the Customer Content and the Go1 Content (after all required fees, which in part are in consideration for your use of the Service, have
        been paid by the Customer), solely for the purpose of your education or training, through the Services, in accordance with these User Terms and the Customer Terms of Service and any conditions or restrictions associated with
        particular Courses or Services. All other uses are prohibited without our express written consent.
      </li>
      <li>
        <tab>
        4.6
        </tab>
        You must not reproduce, redistribute, transmit, assign, sell, broadcast, rent, share, lend, modify, adapt, edit, create derivative works of, license, or otherwise transfer or use any Go1 Content, in whole or in part, other than as
        expressly authorised under these User Terms or as separately authorised by us in writing.
      </li>
      <li>
        <tab>
        4.7
        </tab>
        You must not accept licensed rights to Content from Content Providers and any such direct license is a material breach of these User Terms.
      </li>
      <li>
        <tab>
        4.8
        </tab>
        You agree that we may, in accordance with our Privacy Policy: (a) retain and use your personal information (as that phrase is defined in the
        {' '}
        <Link to={privacyLink}>Privacy Policy</Link>
        {' '}
        ), including, without limitation, name, address, contact details and Courses
        undertaken or scheduled; and (b) contact, deal directly with and offer Courses to you, including after you have ceased employment or engagement with a Customer.
      </li>
      <li>
        <tab>
        4.9
        </tab>
        Notwithstanding the foregoing, we reserve the right to revoke the license to access and use the Content granted by us.
      </li>
      <li>
        <tab>
        4.10
        </tab>
        Notwithstanding anything to the contrary, and subject to the
        {' '}
        <Link to={privacyLink}>Privacy Policy,</Link>
        {' '}
        Go1 has the right to collect and analyse data and other information relating to the provision, use and performance of various aspects of the Services and
        related systems and technologies (including information concerning Customer Data and data derived therefrom), and we will be free at all times, including after your access and use ceases, to (i) use such information and data to
        improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other Go1 offerings, and (ii) disclose such data solely in aggregate or other de-identified form in
        connection with our business. No rights or licenses are granted except as expressly set forth in these User Terms or the Customer Terms of Service.
      </li>
      <li>
        <tab>
        4.11
        </tab>
        Go1 does not screen the Customer Content and all use of the Customer Content by you is at your own risk. We have no responsibility or liability for such use. In particular, no review or posting or appearance of the Customer Content
        on the Services or though the Services is intended to act as an endorsement or representation that any Customer Content is free of violation of any copyright, privacy or other laws or will suit a particular purpose or be accurate or
        useful.
      </li>
      <li>
        <tab>
        4.12
        </tab>
        If you believe that the Customer Content violates any law, or is inaccurate or poses any risk whatsoever to a third party, it is your responsibility to take such steps that you deem necessary to correct the situation. If you believe
        that any Customer Content, originating from a third party, or any Go1 Content violates any laws, including any copyright laws, you must report such belief to us.
      </li>
      <p>
        <strong>
          <tab>
            5.
          </tab>
          Term and termination
        </strong>
      </p>
      <p>
        <strong>Come & GO</strong>
      </p>
      <li>
        <tab>
          5.1
        </tab>
          Your use of the Services is permitted only for as long as the Customer Terms of Service and the Contract between the Customer and Go1 are in operation and are such that they permit your access and use.
      </li>
      <p>
        <strong>
          <tab>
            6.
          </tab>
            Information, general exclusions and operation of laws
        </strong>
      </p>
      <p>
        <strong>
          GO all in
        </strong>
      </p>

      <li>
        <tab>
        6.1
        </tab>
        Whilst all Materials and other information communicated to you via Content Providers or our employees, contractors, agents or representatives are provided in good faith and are believed to be accurate and current as at the date of
        publication, presentation or communication, we provide no warranty or guarantee that any Materials, information on linked websites or information otherwise communicated to you will be accurate or complete. In addition, all such
        material is not intended as professional advice and must not be relied upon as such.
      </li>

      <li>
        <tab>
        6.2
        </tab>
        Prior to taking any particular course of action in connection with the Website, the App or Services, you should make your own enquiries and seek independent advice tailored to your specific circumstances, needs and objectives.
      </li>

      <li>
        <tab>
        6.3
        </tab>
        The Services are a web based marketplace for Content Providers and Customers. Go1 does not hire or employ Content Providers.
      </li>

      <li>
        <tab>
        6.4
        </tab>
        We are not responsible or liable for any interactions between any Content Providers and Customers or Users who purchase or use a Content Provider’s Course via the Services. We are not responsible for disputes, claims, losses,
        injuries or damages of any kind that might arise out of or relate to conduct of Content Providers or Customers or Users, including any Customer&apos;s or User’s reliance upon any information provided by a Content Provider.
      </li>

      <li>
        <tab>
        6.5
        </tab>
        You also understand that, by using the Services, you may be exposed to content that you consider objectionable. We have no responsibility to keep such content from you and no liability for your access or use of any Course or
        content, to the extent permissible under applicable law.
      </li>

      <li>
        <tab>
        6.6
        </tab>
        We understand and you acknowledge and agree that the Services and any other goods or services supplied by us to you or used by you are not of a kind ordinarily acquired for personal, domestic or household use or consumption and that
        you do not intend such use or consumption. If, at any time, this becomes not the case, you must notify us; you are welcome to discuss and raise any issues of concern you have with these User Terms.
      </li>
      <li>
        <tab>
        6.7
        </tab>
        To the maximum extent permitted by law:
      </li>
      <ul>
        <li>
        We make no guarantee as to the usefulness of the Services or Materials, nor any of the products and services promoted on the Website or App or
              via linked websites or communicated to you by us. In particular, we make no guarantee as to the appropriateness of the Courses or Materials provided
              to you by us or our employees, contractors, agents or representatives.
        </li>
        <li>
        We make no warranty or representation (express or implied) regarding the quality, accuracy, reliability, currency, performance, completeness or
              fitness for purpose of any part of the Services, the Website, the App, the Materials and other information communicated to you by us or our employees,
              contractors, agents or representatives.
        </li>
        <li>
        We do not warrant that the Website, the App or the Services will be uninterrupted; nor do we make any warranty as to the results that may be obtained
              from use of the Services. We do not represent or warrant (expressly or impliedly) that the Website, the Materials or the facilities that make the Website
              and the Go1 Content available, will not cause damage, or are free from any computer virus or any other defects or errors.
        </li>
        <li>
          We are not liable (whether in contract, negligence or otherwise) for any loss or damage arising from the use of the Website, the App, the Materials or the Services.
        </li>
        <li>
          Our liability for breach of any implied warranty or conditions that cannot be excluded is restricted, at Go1&apos;s option, to:
        </li>
        <ul>
          <li>the re-supply of the relevant goods or services;</li>
          <li>the cost of the re-supply of the relevant goods or services; or</li>
          <li>any amount paid by you to Go1 in respect of the relevant goods or services.</li>
        </ul>
        <li>
        You agree that, in no circumstances, will we be liable whether in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising under or in connection with this Contract, your use of or reliance on
        the Services, the Materials, the Website, OR the APP
        </li>
        <ul>
          <li>Loss of profits;</li>
          <li>Loss of sales or business;</li>
          <li>Loss of agreements or contracts;</li>
          <li>Loss of anticipated savings;</li>
          <li>Loss of use or corruption of software, data or information;</li>
          <li>Loss of or damage to goodwill; and</li>
          <li>Any indirect or consequential loss.</li>
        </ul>
        <li>
          Notwithstanding the above, to the maximum extent permitted by law, in no event shall our aggregate liability for any claims arising out of or related to
          these customer terms or the contract exceed the amount that you paid, if any, to us for access or use of the services during the twelve (12) months immediately
          prior to the event giving rise to such liability.
        </li>
        <li>
          You agree to indemnify Go1 and its affiliates, officers, agents and employees (indemnified parties) in respect of any claim, action, damage, loss, liability,
          cost, charge, expense, outgoing or payment (including legal expenses (on a full indemnity basis), arising from or relating to: (I) your use of the services or
          any materials; (ii) a breach of these customer terms or the contract by you; and (iii) your breach of any applicable law.
        </li>
        <li>
          We do not exclude any rights or remedies available to you under the Australian Consumer Law that cannot be excluded or restricted.
        </li>
        <li>
          Nothing in these Customer Terms shall limit or exclude our liability for death or personal injury caused by negligence, or the negligence of our employees,
          agents or contractors (as applicable), fraud or fraudulent misrepresentation, breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982,
          or any matter in respect of which it would be unlawful for us to exclude or restrict liability.
        </li>
        <li>
          We shall not be in breach of this Contract nor liable for delay in performing, or failure to perform, any of our obligations under this Contract if such delay or
          failure results from events, circumstances, or causes beyond our reasonable control (including, without limitation, fire, flood, acts of God, interruption or failure
          of utility or telecommunications service, government actions, labour disputes or other similar events). In such circumstances, we shall be entitled to a reasonable
          extension of time for performing such obligations. If the period of delay or non-performance continues for 4 weeks, either we or you may terminate the Contract by
          giving 7 days’ written notice.
        </li>
      </ul>
      <p>
        <strong>
          <tab>
          7.
          </tab>
          Links to other websites
        </strong>
      </p>
      <p>
        <strong>
          GO between
        </strong>
      </p>
      <li>
        <tab>
        7.1
        </tab>
        The Website and/or the App may contain links and pointers to other websites operated by third parties, which are included solely for your convenience. Links to third party websites do not constitute endorsement, sponsorship or
        approval by us of the
        content, policies or practices of those third parties, nor the content available on or for download from those third party sites.
      </li>
      <li>
        <tab>
        7.2
        </tab>
        You agree that, by accessing any third party linked website, you do so at entirely your own risk.
      </li>
      <li>
        <tab>
        7.3
        </tab>
        We are not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with, use or reliance on any goods or services available on or through any third party website.
      </li>
      <p>
        <strong>
          <tab>
          8.
          </tab>
          Updates to Terms
        </strong>
      </p>
      <p>
        <strong>
          New way to GO
        </strong>
      </p>
      <li>
        <tab>
        8.1
        </tab>
        From time to time, we may update these User Terms to clarify our practices or to reflect new or different practices, such as when we add new features, or for other reasons. We reserve the right in our sole discretion to modify
        and/or make changes to these User Terms at any time. If we make any material change to these User Terms, we will notify you using prominent means, such as by email notice sent to the latest email address that we have on record for
        you, or by posting a notice through our Services, the Website or the App. Modifications will become effective on the day they are posted or otherwise published, unless stated otherwise.
      </li>
      <li>
        <tab>
        8.2
        </tab>
        Your continued use of our Services after changes become effective will mean that you accept those changes. You must visit the Services, the Website and the App regularly to ensure that you are aware of the latest version of the User
        Terms, as any revised User Terms will supersede all previous User Terms.
      </li>
      <p>
        <strong>
          <tab>
          9.
          </tab>
          Entire Agreement
        </strong>
      </p>
      <p>
        <strong>
        Full GO
        </strong>
      </p>
      <p>
        <tab>
        9.1
        </tab>
        This page/document is the complete and exclusive statement of the mutual understanding between you and us and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the
        subject matter of this page/document.  All waivers must be in a writing signed by both parties.
      </p>
      <p>
        <strong>
          <tab>
          10.
          </tab>
          Miscellaneous
        </strong>
      </p>
      <p>
        <strong>
          GO formal
        </strong>
      </p>

      <li>
        <tab>
        10.1
        </tab>
        If any provision of these User Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the User Terms will otherwise remain in full force and effect and
        enforceable.
      </li>
      <li>
        <tab>
        10.2
        </tab>
        No relationship of agency, partnership, joint venture, or employment is created as a result of these User Terms and you do not have any authority of any kind to bind us in any respect whatsoever.
      </li>
      <li>
        <tab>
        10.3
        </tab>
        In any action or proceeding to enforce rights under these User Terms, the prevailing party will be enabled to recover costs and attorneys’ fees.
      </li>
      <li>
        <tab>
        10.4
        </tab>
        These User Terms are governed by the laws of Queensland, Australia, and the parties irrevocably submit to the non-exclusive jurisdiction of the courts of Queensland, Australia.
      </li>
      <p>
        <strong>
          <tab>
          11.
          </tab>
        Definitions
        </strong>
      </p>
      <p>
        <strong>
          GO for meaning
        </strong>
      </p>
      <p>
        <tab>
          11.1
        </tab>
      </p>
      <li>
        The following definitions apply:
        <p>
          <strong>App</strong>
  &nbsp;
          means any application or interface in which the Go1 Content and Services are accessible.
        </p>
        <p>
          <strong>Applicable Jurisdiction</strong>
          &nbsp;means:
          <br />
          1. England and Wales if the Services are supplied in any country within the United Kingdom or Europe;
          <br />
          2. the State of Delaware in the United States of America (US) if the Services are supplied in the US; or
          <br />
          3. the State of Queensland, Australia if the Services are supplied in any other country.
        </p>
        <p>
          <strong>Content</strong>
&nbsp;
            means all software, technology, designs, materials, information, communications, text, graphics, links, electronic art, animations, illustrations, artwork, audio clips, video clips, photos, images, reviews, ideas, and other
            data or copyrightable materials or content, including its selection and arrangements.
        </p>
        <p>
          <strong>Content Provider</strong>
&nbsp;
            means the author, designer, creator or provider of a Course.
        </p>
        <p>
          <strong>Contract</strong>
&nbsp;
            means the agreement between the Customer and Go1 for the supply of the Services, or any other goods or services, such supply being on these Customer Terms.
        </p>
        <p>
          <strong>Course</strong>
&nbsp;
            means a course or other resource for instructing, educating, training, learning and/or tutoring and includes mobile applications and related and/or supporting materials.
        </p>
        <p>
          <strong>Customer</strong>
&nbsp;
            means any person who orders, purchases, applies for, commences, initiates a trial, test or other preliminary use of or otherwise uses Go1’s web based marketplace or learning management facility or Go1 Content, Website,
            the App, any Course, the Materials, the Software or any of the Services, and includes: (a) any representative authorised by such person (whether actual, implied or ostensible) to do any of the foregoing or to accept these
            Customer Terms (in these Customer Terms, the Customer is also referred to as “you” or “your”).
        </p>
        <p>
          <strong>Customer Content</strong>
&nbsp;
            means Content uploaded, transmitted or posted to the Services by a Customer and includes any Customer Data so provided.
        </p>
        <p>
          <strong>Customer Data</strong>
&nbsp;
            non-public data provided by the Customer to Go1 to enable the provision of the Services.
        </p>
        <p>
          <strong>Equipment</strong>
&nbsp;
            includes modems, hardware, servers, software, operating systems, networking, telecommunications, web servers and the like.
        </p>
        <p>
          <strong>Fees</strong>
&nbsp;
            means the Implementation Fees and the License Fees.
        </p>
        <p>
          <strong>Go1</strong>
&nbsp;means:
          <br />
          1. Go1 USA LLC if the Applicable Jurisdiction is the State of Delaware in the United States of America;
          <br />
          2. Go1 UK Learning Limited, registered in England and Wales with company number 11867333 and whose registered office is at 1st Floor West Davidson House, Forbury Square, Reading, Berkshire, RG1 3EU, and whose contact email
          address is as set out in the Subscription Agreement or otherwise as notified to you as part of the Services, if the Applicable Jurisdiction is England and Wales; or
          <br />
          3. Go1 Pty Limited (ABN 91 134 998 020) of Units 4 & 5, 2908 Logan Road, Underwood, Queensland, Australia, 4119 if the Applicable Jurisdiction is the State of Queensland, Australia.
        </p>
        <p>
          <strong>Go1 Content</strong>
&nbsp;
            means Content published or otherwise provided by or through Go1, including through the Website or the App.
        </p>

        <p>
          <strong>Implementation Services</strong>
&nbsp;
            means that part of the Services, including any training that Go1 may offer at its discretion, provided by Go1 to enable the Customer to commence to use the Courses.
        </p>
        <p>
          <strong>Intellectual Property</strong>
&nbsp;
            means all intellectual property rights, including the following rights:
          <br />
          (a) patents, copyright, rights in circuit layouts, designs, trade and service marks (including goodwill in those marks), domain names and trade names and any right to have confidential information kept confidential;
          <br />
          (b) any application or right to apply for registration of any of the rights referred to in paragraph (a); and
          <br />
          (c) all rights of a similar nature to any of the rights in paragraphs (a) and (b) that may subsist anywhere in the world (including Australia),
          {' '}
          <br />
            whether or not such rights are registered or capable of being registered.
        </p>
        <p>
          <strong>Materials</strong>
&nbsp;
            means documents, information, materials (including marketing materials) and communications, provided or made available by or through Go1, including through the Website, the App and learning management portal, and includes Go1
            Content.
        </p>
        <p>
          <strong>Privacy Policy</strong>
&nbsp;
            means GO’s privacy policy as amended from time to time, and available at
          {' '}
          <a href={go1Terms.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            .
        </p>
        <p>
          <strong>Services</strong>
&nbsp;
            means all services supplied by Go1 to the Customer and/or the User via the Website, the App or other means, including marketing, promoting, facilitating, offering, hosting and providing Courses, providing access to Software
            and access to or licensing of specific Courses and includes the Implementation Services, as may be detailed more specifically at the discretion of Go1 in a Subscription Agreement or Order Form or service and/or support level
            agreement.
        </p>
        <p>
          <strong>Software</strong>
&nbsp;
            means the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services.
        </p>
        <p>
          <strong>User</strong>
&nbsp;
            means a person who in any way uses the Website, the App, any Course, the Materials, the Software or any of the Services (a User may also be a Customer).
        </p>
        <p>
          <strong>Website</strong>
&nbsp;
            means Go1’s website at
          {' '}
          <a href="https://www.go1.com/" target="_blank" rel="noopener noreferrer">https://www.go1.com</a>
            .
        </p>
      </li>
      <li>
        Interpretation
        <p>The following rules of interpretation apply unless the contrary intention appears:</p>
        <ul>
          <li>the singular includes the plural and vice versa;</li>
          <li>words that are gender neutral or gender specific include each gender;</li>
          <li>where a word or phrase is given a particular meaning, other parts of speech and grammatical forms of that word or phrase have corresponding meanings;</li>
          <li>the words &apos;such as&apos;, &apos;including&apos;, &apos;particularly&apos; and similar expressions are not used as nor are intended to be interpreted as words of limitation.</li>
          <li>
            a reference to:
            <ul>
              <li>a person includes a natural person, partnership, joint venture, government agency, association, corporation or other body corporate;</li>
              <li>a thing (including a chose in action or other right) includes a part of that thing;</li>
              <li>a party includes its successors and permitted assigns;</li>
              <li>a document (which may be in electronic form) includes all amendments or supplements to that document;</li>
              <li>a clause, term or party is a reference to a clause or term of, or party to these Customer Terms or the Contract;</li>
              <li>
                a law includes a constitutional provision, treaty, decree, convention, statute, regulation, ordinance, by-law, judgment, rule of common law or equity or a rule of an applicable financial market and is a reference to that
                law as amended, consolidated or replaced;
              </li>
            </ul>
          </li>
          <li>an agreement on the part of two or more persons binds them jointly and severally; and</li>
          <li>no rule of construction applies to the disadvantage of a party because that party was responsible for the preparation of these Customer Terms.</li>
        </ul>
      </li>
    </View>
  );
};
