import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonFilled,
  foundations,
  Text,
  View,
  TextInput,
  InputGroup,
} from '@go1d/go1d';

import { CouponDuration } from '@go1d/go1d-exchange';
import { validateCoupons } from '../../../../services/subscription';

class Coupon extends React.Component {
  static propTypes = {
    packages: PropTypes.arrayOf(PropTypes.any),
    interval: PropTypes.string,
    onCouponFound: PropTypes.func,
  };

  state = {
    isCouponVisible: false,
    couponCode: '',
    couponError: false,
    matchedCoupons: [],
  };

  toggleCouponInput = isCouponVisible => {
    this.setState({ isCouponVisible });
  };

  onRemoveCoupon = () => {
    const { onCouponFound } = this.props;
    const couponCode = '';
    this.onChangeCoupon(couponCode);
    this.setState({ matchedCoupons: [] });
    onCouponFound([]);
  };

  onChangeCoupon = couponCode => {
    this.setState({ couponCode, couponError: false });
  };

  applyCoupon = async couponCode => {
    const {
      jwt,
      onCouponFound,
      packages,
    } = this.props;
    const productIds = packages.map(pack => pack.id) || [];
    const matchedCoupons = await validateCoupons(couponCode, productIds, jwt);
    if (matchedCoupons.length <= 0) {
      this.setState({ couponError: true });
      return;
    }
    onCouponFound(matchedCoupons);
    this.setState({ matchedCoupons });
  }

  getProductName = (id) => {
    const { packages = [] } = this.props;
    const filteredPackages = packages.filter(pack => pack.id === id);
    return filteredPackages[0] && filteredPackages[0].entity.name;
  }

  render() {
    const {
      isCouponVisible,
      couponCode,
      couponError,
      matchedCoupons,
    } = this.state;
    const { packages, interval } = this.props;
    return (
      <View>
        {!isCouponVisible && (
          <View marginTop={5}>
            <Text
              color="accent"
              element="span"
              fontSize={2}
              css={{
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                },
              }}
              onClick={() => this.toggleCouponInput(true)}
            >
              Have a coupon code?
            </Text>
          </View>
        )}

        {isCouponVisible && matchedCoupons.length <= 0 && (
          <View marginTop={5} marginBottom={4}>
            <Text fontSize={2} marginBottom={3}>
              Coupon code
            </Text>

            <View
              css={{
                [foundations.breakpoints.lg]: {
                  maxWidth: '70%',
                },
              }}
            >
              <InputGroup>
                <TextInput
                  id="subscription-page-coupon"
                  border={0}
                  borderRadius={0}
                  viewCss={{
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                  value={couponCode}
                  onChange={evt => this.onChangeCoupon(evt.target.value)}
                />
                <ButtonFilled
                  color="accent"
                  transition="none"
                  css={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    minHeight: 42,

                    '&:hover': {
                      transform: 'translate(0, 0)',
                    },
                  }}
                  onClick={
                    () => this.applyCoupon(couponCode)
                  }
                >
                  Apply
                </ButtonFilled>
              </InputGroup>
            </View>
          </View>
        )}

        {couponError && (
          <Text color="danger" marginTop={3}>
            {`Coupon ${couponCode} is not valid`}
          </Text>
        )}

        {!couponError && matchedCoupons.length > 0
            && (
            <View
              borderColor="soft"
              flexDirection="row"
              justifyContent="space-between"
              marginTop={4}
              marginBottom={4}
            >
              <View>
                <View flexDirection="row">
                  <Text color="subtle" data-testid="coupon--label" fontSize={2} marginBottom={2}>
                    Coupon:
                  </Text>

                  <Text data-testid="coupon--code" fontWeight="semibold" fontSize={2} marginBottom={2} marginLeft={2}>
                    {couponCode}
                  </Text>
                </View>

                {matchedCoupons.map(item => {
                  const coupon = item.stripe_coupon;
                  let content = '';
                  if (coupon.duration === CouponDuration.Forever) {
                    content = `(${coupon.percent_off}% off) ${packages.length > 1 ? this.getProductName(item.product_id) : ''}`;
                  } else {
                    content = `(${coupon.percent_off}% off - First ${interval} only) ${packages.length > 1 ? this.getProductName(item.product_id) : ''}`;
                  }

                  return (
                    <Text data-testid="discount--label" fontSize={2} marginBottom={2}>
                      {content}
                    </Text>
                  );
                })
              }

              </View>

              <Text
                color="subtle"
                data-testid="coupon--remove"
                fontSize={2}
                marginTop={4}
                css={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
                onClick={() => this.onRemoveCoupon()}
              >
              remove
              </Text>
            </View>
            )
        }
      </View>
    );
  }
}

export default Coupon;
