import { connect } from 'react-redux';
import { formActions } from '../../actions/form';
import { errorActions } from '../../actions/error';
import { packageActions } from '../../actions/packages';
import SubscriptionsPage from './page';

/* istanbul ignore next */
const mapStateToProps = state => ({
  storedFormValues: state.formValues,
  storedOnboardValues: state.onboardValues,
  storedPortalValues: state.portalValues,
  storedPackageValues: state.packageValues,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  dispatchStoreValues: (...values) => dispatch(formActions.storeValues(...values)),
  dispatchRemovePackage: packageId => dispatch(packageActions.removePackage(packageId)),
  dispatchSelectPackagePlan: packagePlanId => dispatch(packageActions.selectPackagePlan(packagePlanId)),
  dispatchUpdatePackage: pack => dispatch(packageActions.updatePackage(pack)),
  dispatchErrorValues: (...errors) => dispatch(errorActions.storeValues(...errors)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionsPage);
