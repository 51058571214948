import React from 'react';
import {
  Container,
  Checkbox,
  CheckboxGroup,
  Text,
  View,
  Form,
  Field,
  TextInput,
  SubmitButton,
  Link,
} from '@go1d/go1d';
import PropTypes from 'prop-types';
import go1Terms from '../../constants/go1Terms';
import { REACT_APP_BASE_DIRECTORY } from '../../config';
import LogoContainer from '../../components/LogoContainer';

export default class AuthorDetails extends React.Component {
  static propTypes = {
    dispatchStoreAuthorDetailsValues: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    storedFormValues: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  validate = (values) => {
    const errors = {};
    if (!values.number_of_courses) {
      errors.number_of_courses = 'You must enter a number of courses.';
    }
    if (values.course_type.length === 0) {
      errors.course_type = 'You must select at least one course type.';
    }
    if (values.sell_via.length === 0) {
      errors.sell_via = 'You must select at least one option.';
    }
    if (values.agreed_to_tnc !== true) {
      errors.agreed_to_tnc = 'You must agree to the Content Partners terms of service.';
    }

    return errors;
  };

  render() {
    const { history, dispatchStoreAuthorDetailsValues, storedFormValues } = this.props;
    return (
      <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
        <Container contain="narrow" marginTop={6}>
          <LogoContainer
            title="Author Details"
            description="Help us understand how you&apos;d like to use Go1"
          >
            <Form
              onSubmit={(values) => {
                dispatchStoreAuthorDetailsValues(values);
                /* istanbul ignore next */
                history.push(`${REACT_APP_BASE_DIRECTORY}/policy/customer-terms?signupTOSAgreement=true`);
              }}
              validate={this.validate}
              initialValues={{
                number_of_courses: storedFormValues.number_of_courses ? storedFormValues.number_of_courses : '',
                course_type: storedFormValues.course_type ? storedFormValues.course_type : [],
                sell_via: storedFormValues.sell_via ? storedFormValues.sell_via : [],
                author_message: storedFormValues.author_message ? storedFormValues.author_message : '',
                agreed_to_tnc: '',
              }}
            >
              <View
                marginBottom={5}
              >
                <Field
                  component={TextInput}
                  label="Number of courses"
                  name="number_of_courses"
                  inputType="number"
                  min={1}
                  required
                />
              </View>
              <View
                marginBottom={5}
              >
                <Field
                  component={CheckboxGroup}
                  required
                  label="Course types"
                  name="course_type"
                  options={[{
                    label: 'Accredited training / compliance',
                    value: 'accredited_training',
                  }, {
                    label: 'Personal development',
                    value: 'personal_development',
                  }, {
                    label: 'Professional development',
                    value: 'professional_development',
                  }, {
                    label: 'Other',
                    value: 'other',
                  }]}
                />
              </View>
              <View marginBottom={5}>
                <Field
                  component={CheckboxGroup}
                  required
                  label="I would like to sell my courses via..."
                  name="sell_via"
                  options={[{
                    label: 'My own website',
                    value: 'own_website',
                  }, {
                    label: 'My Go1 training portal',
                    value: 'go1_training_portal',
                  }, {
                    label: 'The Go1 Content Hub',
                    value: 'go1_marketplace',
                  }]}
                />
              </View>
              <View marginBottom={5}>
                <Field component={TextInput} label="Message" multiline={5} name="author_message" />
              </View>
              <View marginBottom={3}>
                <Field component={Checkbox} required name="agreed_to_tnc" paddingY={4}>
                  <Text css={{ whiteSpace: 'pre-wrap' }}>
                    As a seller of content on the Go1 platform, I agree to the
                    {' '}
                    <Link href={go1Terms.contentProvider} target="_blank" color="accent">Content Partners terms of service</Link>
                  </Text>
                </Field>
              </View>
              <SubmitButton
                justifyContent="center"
                size="lg"
                css={{
                  width: '100%',
                }}
              >
                Continue
              </SubmitButton>
            </Form>
          </LogoContainer>
        </Container>
      </Container>
    );
  }
}
