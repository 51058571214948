import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import http from './utils/http';
import rootReducer from './reducers';

export const history = createBrowserHistory();

const middleware = [routerMiddleware(history), thunk.withExtraArgument({ http })];

export const store = createStore(
  connectRouter(history)(rootReducer),
  composeWithDevTools(applyMiddleware(...middleware)),
);
