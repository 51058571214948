import merge from 'lodash/merge';

export default function packageEntitiesReducer(state = {}, action) {
  if (action.entities) {
    Object.keys(action.entities).forEach((key) => {
      if (action.entities[key]) {
        /* eslint-disable no-param-reassign */
        state = merge({}, state, {
          [key]: action.entities[key],
        });
      }
    });
  }

  return state;
}
