import React from 'react';
import PropTypes from 'prop-types';
import {
  Banner,
  Text,
} from '@go1d/go1d';

const MappedErrorMessages = {
  'SSO:PortalNotFound': {
    default: { title: 'Portal details could not be found', message: 'Please contact your Administrator to setup Single Sign On for your portal' },
  },
  'SSO:PortalAuthorizationExpired': {
    default: { title: 'Portal authorization expired', message: 'Sorry, the link to sign into your Go1 Portal expired. Please generate a new link and try again.' },
  },
  'SSO:UnknownIdentityProvider': {
    azure: { title: 'Microsoft Single Sign On is currently not available', message: 'Please contact your Administrator.' },
    default: { title: 'The Identity Provider is currently not available', message: 'Please contact your Administrator.' },
  },
  'SSO:UnsupportedProvider': {
    default: { title: 'The Identity Provider is currently not supported', message: 'Please contact your Administrator.' },
  },
  'SSO:PortalNotConfigured': {
    azure: { title: 'Microsoft Single Sign On has not been configured for the Portal', message: 'Please contact your Administrator to setup Single Sign On for this Portal.' },
    default: { title: 'The Identity Provider has not been configured for the Portal', message: 'Please contact your Administrator to setup Single Sign On for this Portal.' },
  },
  'SSO:InternalServerError': {
    default: { title: 'An error occurred', message: 'An unexpected error occurred. Please try again later.' },
  },
  'SSO:ProviderError': {
    default: { title: 'An error occurred', message: 'An unexpected error occurred. Please try again later.' },
  },
  'SSO:LoginTimeout': {
    default: { title: 'Could not login', message: 'Login session expired. Please try again.' },
  },
  'SSO:UserNotFound': {
    default: { title: 'Could not login', message: 'Your account is not available at this time. Please try again later.' },
  },
  'SSO:PermissionDenied': {
    default: { title: 'Permission Denied', message: 'Your account does not have access. Please contact your Administrator.' },
  },
  'SSO:ProviderNotConfigured': {
    azure: { title: 'Microsoft has not been configured for the Portal', message: 'Please contact your Administrator to setup Single Sign On for this Portal.' },
    default: { title: 'The Identity Provider has not been configured for the Portal', message: 'Please contact your Administrator to setup Single Sign On for this Portal.' },
  },
  'SSO:IncorrectIdentityGroup': {
    azure: { title: 'Incorrect Microsoft Azure AD Tenant', message: 'Please try to log in again with a different Microsoft Tenant.' },
    default: { title: 'Incorrect Identity Group', message: 'Please try to log in again with a different Identity Group.' },
  },
  'SSO:DeactivatedUser': {
    default: {
      title: 'Login unsuccessful',
      message: 'This user has been deactivated.',
    },
  },
  'SSO:DeactivatedPortalAccount': {
    default: {
      title: 'Login unsuccessful',
      message: 'This account has been deactivated.',
    },
  },
  'SSO:AutoCreationFailed': {
    default: {
      title: 'Login unsuccessful',
      message: 'The user’s account could not be created. Please try again later.',
    },
  },
  'SSO:UpsetMappingFailed': {
    default: {
      title: 'Login unsuccessful',
      message: 'The account mapping could not be updated. Please try again later.',
    },
  },
  'SSO:IdentityUnknown': {
    default: {
      title: 'Identity Unknown',
      message: 'We were unable to retrieve your email address from your Identity Provider. Please contact support.',
    },
  },
};

const SSOMappedErrorMessage = ({ errorStatus, sessionCode, identityProvider }) => {
  let mappedError = { default: { title: 'An unexpected error occurred', message: 'Please contact your portal administrator' } };

  if (errorStatus in MappedErrorMessages) {
    mappedError = MappedErrorMessages[errorStatus];
  }

  let error = mappedError.default;

  if (identityProvider && identityProvider in mappedError) {
    error = mappedError[identityProvider];
  }

  return (
    <Banner type="danger" width="100%" maxWidth={620} marginBottom={6}>
      <Text fontWeight="semibold" marginBottom={3}>{error.title}</Text>
      <Text marginBottom={3}>{error.message}</Text>
      {sessionCode && (
      <Text fontSize={1} color="subtle">
        Support ID:
        {' '}
        {sessionCode}
      </Text>
      )}
    </Banner>
  );
};

SSOMappedErrorMessage.propTypes = {
  errorStatus: PropTypes.string,
  sessionCode: PropTypes.string,
  identityProvider: PropTypes.string,
};

export default SSOMappedErrorMessage;
