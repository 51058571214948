import { connect } from 'react-redux';
import CreatePortalPage from './page';
import { formActions } from '../../actions/form';
import { errorActions } from '../../actions/error';
import { onboardActions } from '../../actions/onboard';

/* istanbul ignore next */
const mapStateToProps = state => ({
  storedFormValues: state.formValues,
  storedPortalValues: state.portalValues,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  dispatchStoreValues: (...variables) => dispatch(formActions.storeValues(...variables)),
  dispatchErrorValues: (...errors) => dispatch(errorActions.storeValues(...errors)),
  dispatchOnboardValues: (...values) => dispatch(onboardActions.storeValues(...values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePortalPage);
