import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import {
  Container,
  Banner,
  View,
  Text,
  Form,
  foundations,
  Field,
  TextInput,
  Select,
  PortalUrlField,
  SubmitButton,
  Stepper,
} from '@go1d/go1d';
import axios from '../../utils/http';
import onboardUser from '../../services/userOnboard';
import {
  userRequest, portalRequest, customerRequest, validateRequest,
} from '../../services/requestModels';
import fetchIndustryList from '../../services/selectData';
import LogoContainer from '../../components/LogoContainer';

import Countries from './SelectData/countries';
import BigCompanyBanner from '../../components/BigCompanyBanner/BigCompanyBanner';
import { REACT_APP_BASE_DIRECTORY, REACT_APP_PORTAL_ROOT_DOMAIN } from '../../config';
import { PORTAL_PLAN } from '../../actions/portal';
import track from '../../services/houston';
import { findAccount, redirectToPortalWithOTT } from '../../services/user';
import getNested from '../../utils/getNested';


class CreatePortalPage extends React.Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    storedFormValues: PropTypes.objectOf(PropTypes.any).isRequired,
    storedPortalValues: PropTypes.objectOf(PropTypes.any),
    dispatchErrorValues: PropTypes.func.isRequired,
    dispatchOnboardValues: PropTypes.func.isRequired,
  }

  state = {
    isPortalValid: null,
    isPortalAvailable: null,
    lastSearchedPortal: '',
    dirty: {},
    values: {
      portalName: '',
      companyName: '',
      companySize: '1-20',
      country: '',
      industry: '',
    },
  };

  timer = 0;

  constructor(props) {
    super(props);
    const { storedFormValues, storedPortalValues } = props;
    const { values } = this.state;
    this.state.companyTooBig = this.isCompanyTooBig(storedFormValues.companySize);

    values.companyName = storedFormValues.companyName || '';
    values.companySize = storedFormValues.companyName ? storedFormValues.companySize : (storedPortalValues.companySizeStepper ? 1 : '1-20');
    values.portalName = storedFormValues.companyName ? storedFormValues.portalName : '';
    values.country = storedFormValues.companyName ? storedFormValues.country : '';
    values.industry = storedFormValues.companyName ? storedFormValues.industry : '';
  }

  componentWillMount() {
    fetchIndustryList().then((list) => {
      const industryList = list.map(item => ({ label: item, value: item }));
      this.setState({ industryList });
    }, () => {});
  }

  componentDidMount() {
    const {
      storedPortalValues,
      dispatchStoreValues,
    } = this.props;

    if (storedPortalValues.contentPackageSignupFlow) {
      dispatchStoreValues({ plan: PORTAL_PLAN.REFERRAL_CONTENT_PARTNER });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  isHostnameValid = (portalName) => {
    if (portalName) {
      const r = new RegExp('^(?=.{1,63}$)[A-Za-z0-9]+(-[A-Za-z0-9]+)*$');
      return r.test(portalName);
    }
    return true;
  }

  getIsPortalAvailable = (portalName) => {
    this.setState({
      isPortalValid: null,
      isPortalAvailable: null,
      lastSearchedPortal: portalName,
    });

    const portalNameLowerCase = portalName && portalName.toLowerCase();
    if (!this.isHostnameValid(`${portalName}`)) {
      this.setState({
        isPortalAvailable: false,
        isPortalValid: false,
        lastSearchedPortal: portalName,
      });
    } else if (portalName) {
      axios.get(`/portal/${portalNameLowerCase}.${REACT_APP_PORTAL_ROOT_DOMAIN}`).then(
        () => {
          this.setState({
            isPortalAvailable: false,
            isPortalValid: true,
            lastSearchedPortal: portalNameLowerCase,
          });
        },
        (err) => {
          if (err.response && err.response.status === 404) {
            this.setState({
              isPortalAvailable: true,
              isPortalValid: true,
              lastSearchedPortal: portalNameLowerCase,
            });
            return;
          }

          this.setState({
            isPortalAvailable: false,
            isPortalValid: true,
            lastSearchedPortal: portalNameLowerCase,
          });
        },
      );
    }
  }

  handleFormChange = (e) => {
    const { values } = e;
    const { dirty } = this.state;

    Object
      .keys(values)
      .filter(k => values[k] !== undefined && values[k].length > 0)
      .forEach(k => { dirty[k] = true; });

    this.setState({ values, dirty });
  }

  validate = (values) => {
    const errors = {};
    const { lastSearchedPortal } = this.state;
    Object.entries(values).forEach((v) => {
      if (!v[1]) {
        const n = v[0].replace(/([A-Z])/g, ' $1').toLowerCase();
        errors[v[0]] = `Please enter a ${n}.`;
      } else if (v[0] === 'portalName' && lastSearchedPortal !== v[1]) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.setState({
          lastSearchedPortal: v[1],
        }, () => {
          this.timer = window.setTimeout(() => new Promise(() => this.getIsPortalAvailable(v[1])), 400);
        });
      }
    });
    return errors;
  }

  handleFormSubmission = (values, actions) => {
    const { isPortalAvailable, companyTooBig } = this.state;
    actions.setSubmitting(false);
    if (companyTooBig) {
      return;
    }

    if (isPortalAvailable) {
      actions.setSubmitting(true);
      const {
        history,
        storedFormValues,
        storedPortalValues = {},
        dispatchStoreValues,
        dispatchErrorValues,
        dispatchOnboardValues,
      } = this.props;

      if (storedFormValues.plan === PORTAL_PLAN.PREMIUM) {
        dispatchStoreValues(values);
        /* istanbul ignore next */
        history.push(`${REACT_APP_BASE_DIRECTORY}/payment`);
      } else {
        const { jwt } = storedFormValues;
        const user = userRequest(storedFormValues);
        const portal = portalRequest(values, null, storedPortalValues.companySizeStepper);
        const customer = customerRequest(values);
        const public_portal_id = 449228;
        const partner_portal_id = storedFormValues.portal ? storedFormValues.portal : null;
        const creation_path = storedFormValues.type === 'referral' && !(partner_portal_id === public_portal_id) ? PORTAL_PLAN.REFERRAL_PARTNER : PORTAL_PLAN.WEBSITE_FREE;
        const onboardData = {
          user, portal, customer, creation_path, jwt, partner_portal_id,
        };

        const errors = validateRequest(user, portal, customer);
        if (Object.keys(errors).length > 0) {
          // some field(s) missing, pop errors
          dispatchErrorValues(errors);
          // preserve inputs when user returns
          dispatchStoreValues(values);
          history.push(`${REACT_APP_BASE_DIRECTORY}/`);
        } else if (storedFormValues.plan === PORTAL_PLAN.REFERRAL_CONTENT_PARTNER) {
          track(
            'create-portal',
            {
              id: 'submit-create-portal',
              company_size: values.companySize,
              company_industry: values.industry,
            },
          );
          dispatchOnboardValues({ ...onboardData, creation_path: PORTAL_PLAN.REFERRAL_CONTENT_PARTNER });
          history.push(`${REACT_APP_BASE_DIRECTORY}/choose_learning${get(window, 'location.search', '')}`);
        } else {
          onboardUser(onboardData)
            .then((res) => {
              const userResponse = res.data.user;
              const portalResponse = res.data.portal;
              dispatchStoreValues({
                ...values,
                user: userResponse,
                portal: portalResponse,
              });

              if (storedFormValues.skip_preference) {
                this.goToDashboardPage();
              } else {
                history.push(`${REACT_APP_BASE_DIRECTORY}/whos_learning`);
              }
            })
            .catch((err) => {
              let errorMessage = 'Your request could not be processed.';
              if (err.response && err.response.status === 403) {
                errorMessage = 'An account already exists with this email.';
              }
              this.setState({ genericError: errorMessage });
            });
        }
      }
    }
  }

  isCompanyTooBig = (companySize) => {
    const { storedFormValues } = this.props;
    const { type, plan } = storedFormValues;
    const tooBig = companySize === '100+';
    return type === 'team' && plan === PORTAL_PLAN.PREMIUM && tooBig;
  }

  isFreemium = () => {
    const { storedFormValues } = this.props;
    return storedFormValues.freemium;
  }

  validateCompanySize = (value) => {
    this.setState({
      companyTooBig: this.isCompanyTooBig(value),
    });
  }

  prefillUrl = (e: React.FormEvent<HTMLInputElement>) => {
    const { dirty, values } = this.state;
    if (!dirty.portalName) {
      values.portalName = kebabCase(e.currentTarget.value);
      this.setState({ values });
      this.getIsPortalAvailable(values.portalName);
      if (e.currentTarget.name === 'portalName') {
        this.validate({ values });
      }
    }
  }

  goToDashboardPage() {
    const { storedFormValues: { portal, user }, storedPortalValues: { redirectUrl } } = this.props;
    const portalId = portal.id;
    const targetAccountId = findAccount(user.accounts, portalId) || getNested(['accounts', 0, 'id'], user);
    redirectToPortalWithOTT(targetAccountId, user.jwt, redirectUrl || '').catch(() => { });
  }

  render() {
    const {
      isPortalValid, isPortalAvailable, genericError, companyTooBig, industryList, values,
    } = this.state;
    const {
      storedPortalValues: {
        customDomain,
        portalUrlHelpText,
        companySizeStepper,
        portalCreationTagline,
        portalCreationSecondaryTagline,
      },
    } = this.props;

    const errorMessage = isPortalValid === false
      ? 'This portal is not valid'
      : isPortalAvailable === false
        ? 'This portal url is not available'
        : null;

    return (
      <Container
        paddingX={4}
        paddingBottom={4}
        contain="wide"
        alignItems="center"
      >
        <Container contain="narrow" marginTop={6}>
          <LogoContainer
            title={portalCreationTagline || 'Create your Portal'}
            description={portalCreationSecondaryTagline || 'You’re seconds away from your own learning portal.'}
            errorBannerLayout={genericError}
          >
            {genericError
              && (
                <Banner
                  type="danger"
                  marginBottom={6}
                >
                  <Text fontWeight="semibold">
                    {genericError}
                  </Text>
                  <br />
                  <a href="https://www.go1.com/contact" rel="noopener noreferrer" target="_blank">Please contact support.</a>
                </Banner>
              )
            }
            {
              companyTooBig
              && <BigCompanyBanner companySize={101} sizeThreshold={100} />
            }
            <Form
              validate={this.validate}
              enableReinitialize
              initialValues={{
                companyName: values.companyName,
                companySize: values.companySize,
                portalName: values.portalName,
                country: values.country,
                industry: values.industry,
              }}
              onSubmit={this.handleFormSubmission}
              onChange={this.handleFormChange}
            >
              <View
                flexDirection="row"
                paddingBottom={5}
                css={{
                  [foundations.breakpoints.sm]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <View
                  flexGrow={1}
                  paddingRight={!this.isFreemium() ? 4 : 0}
                  css={{
                    [foundations.breakpoints.sm]: {
                      width: '100%',
                      paddingRight: 0,
                    },
                  }}
                >
                  <Field
                    component={TextInput}
                    name="companyName"
                    borderColor="soft"
                    required
                    label="Company Name"
                    onBlur={this.prefillUrl}
                  />
                </View>
                {!this.isFreemium() && (
                <View
                  flexGrow={1}
                  paddingLeft={4}
                  css={{
                    [foundations.breakpoints.sm]: {
                      width: '100%',
                      paddingLeft: 0,
                      paddingTop: foundations.spacing[4],
                    },
                  }}
                >
                  <Field
                    component={companySizeStepper ? Stepper : Select}
                    name="companySize"
                    required
                    label={companySizeStepper ? 'Seats for Purchase' : 'Company Size'}
                    size="lg"
                    validate={this.validateCompanySize}
                    options={[
                      // if changed, please also update payment/pages.jsx,
                      // seat numbers will be preset there depending on the selection here
                      { value: '1-20', label: '1 - 20' },
                      { value: '20-100', label: '20 - 100' },
                      { value: '100+', label: '100+' },
                    ]}
                    {...(companySizeStepper ? { minNumber: 1, defaultValue: 1 } : {})}
                  />
                </View>
                )}
              </View>
              <View paddingBottom={5}>
                <PortalUrlField
                  name="portalName"
                  label="Portal Url"
                  suffixValue={`.${customDomain || REACT_APP_PORTAL_ROOT_DOMAIN}`}
                  borderColor="soft"
                  required
                  isAvailable={isPortalAvailable && isPortalValid}
                  errorMessage={errorMessage}
                  description={portalUrlHelpText}
                />
              </View>
              <View paddingBottom={5}>
                <Field
                  component={Select}
                  name="country"
                  label="Company Location"
                  size="lg"
                  required
                  searchable
                  options={Countries}
                />
              </View>
              <View paddingBottom={5}>
                <Field
                  component={Select}
                  name="industry"
                  label="Company Industry"
                  size="lg"
                  required
                  searchable
                  options={industryList}
                />
              </View>
              <SubmitButton
                width="100%"
                color="accent"
                justifyContent="space-around"
                size="lg"
              >
                Create portal
              </SubmitButton>
            </Form>
          </LogoContainer>
        </Container>
      </Container>
    );
  }
}

CreatePortalPage.propTypes = {
  dispatchStoreValues: PropTypes.func,
  customDomain: PropTypes.string,
};

export default CreatePortalPage;
