import { findAccount, redirectToPortalWithOTT } from '../services/user';
import getNested from './getNested';
import onboardUser from '../services/userOnboard';

const loginWithAccount = (accountId, jwt) => redirectToPortalWithOTT(accountId, jwt)
  .catch(() => Promise.reject(new Error('account-error')));

export default function onboardPortal(onboardData) {
  return onboardUser(onboardData).then(res => {
    const user = getNested(['data', 'user'], res, {});
    const portal = getNested(['data', 'portal'], res, {});
    const targetAccountId = findAccount(user.accounts, portal.id);
    if (targetAccountId && user.jwt) {
      return loginWithAccount(targetAccountId, user.jwt);
    }
    return Promise.reject(new Error('account-error'));
  });
}
