/* istanbul ignore file */
import http from '../utils/http';

export const DIMENSION_TYPES = {
  TOPIC: 1,
  INDUSTRY: 2,
  REGION_RESTRICTION: 3,
  LOCATION: 4,
  BUSINESS_AREA: 5,
};

const getInterestedData = portalId => http.get(`topic-recommend-writer/portal/${portalId}`);

const getPredefinedDimension = (dimensionType = DIMENSION_TYPES.BUSINESS_AREA, level = 1) => http.get(`dimensions/browse?type=${dimensionType}&level=${level}`)
  .then(res => res.data.hits)
  .then(list => list.map(item => item.name));

const getPredefinedTopics = portalId => http.get(`recommender/topic/${portalId}`)
  .then(res => res.data)
  .then(list => list.map(item => item.name));

const updateInterestedTeams = (portalId, teamList) => http.put(`topic-recommend-writer/portal/${portalId}`, { business_area: teamList });

const updateInterestedTopic = (portalId, topicList) => http.put(`topic-recommend-writer/portal/${portalId}`, { topic: topicList });

export {
  getInterestedData,
  getPredefinedDimension,
  getPredefinedTopics,
  updateInterestedTeams,
  updateInterestedTopic,
};
