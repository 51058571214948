import { connect } from 'react-redux';
import AuthorDetailsPage from './page';
import { formActions } from '../../actions/form';

/* istanbul ignore next */
const mapStateToProps = state => ({
  storedFormValues: state.formValues,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  dispatchStoreAuthorDetailsValues: (
    number_of_courses,
    course_type,
    sell_via,
  ) => dispatch(
    formActions.storeAuthorDetailsValues(
      number_of_courses,
      course_type,
      sell_via,
    ),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthorDetailsPage);
