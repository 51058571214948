
export default function isPackageFree(pack) {
  const plans = pack.plans || ((pack.raw && pack.raw.plans) ? pack.raw.plans : []);
  if (plans.length === 0) {
    return false;
  }
  const total = plans.reduce((pre, curr) => pre + curr.amount, 0);
  return total <= 0;
}

/**
 * Format a date value
 * @param date A date value of type string in format "dd/mm"
 * @returns a formatted renewal date
 */
export function getFixedRenewalDates(date) {
  const splitDate = date.split('/');
  const month = splitDate[1] - 1;
  const day = splitDate[0];

  const now = new Date();
  const year = now.getFullYear();
  const renewalDate = new Date(year, month, day);
  const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };

  if (renewalDate > now) {
    const periodStart = new Date(year - 1, month, day);
    renewalDate.setDate(renewalDate.getDate() - 1);
    return `${periodStart.toLocaleDateString('en-GB', dateOptions)} - ${renewalDate.toLocaleDateString('en-GB', dateOptions)}`;
  }
  const periodEnd = new Date(year + 1, month, day);
  periodEnd.setDate(periodEnd.getDate() - 1);
  return `${renewalDate.toLocaleDateString('en-GB', dateOptions)} - ${periodEnd.toLocaleDateString('en-GB', dateOptions)}`;
}
