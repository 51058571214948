import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonFilled, foundations, SlatMini, Text, View, Form, Field, Stepper, ButtonMinimal,
} from '@go1d/go1d';
import { SubscriptionFormatPrice, PackageLicenseType } from '@go1d/go1d-exchange';
import { getFixedRenewalDates } from '../../../../utils/package';

const validation = (values) => {
  const errors = {};
  if (!values.seat || parseInt(values.seat, 10) < 1) {
    errors.seat = 'Please select at least one seat';
  }

  return errors;
};

class EditSubscriptionPlan extends React.Component {
  static propTypes = {
    subscription: PropTypes.objectOf(PropTypes.any),
    selectedPlanId: PropTypes.string,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    onCancel: PropTypes.func,
    storedPortalValues: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  getBottomMeta = (subscription, selectedPlanId = 'year') => {
    const { product, stripePlan, entity } = subscription;
    const selectedPlan = stripePlan.find(plan => plan.interval === selectedPlanId);
    const {
      currency = 'aud',
      interval = 'year',
      trialPeriodDays = 0,
    } = selectedPlan;
    const price = SubscriptionFormatPrice(currency, selectedPlan.amount * subscription.seats, 0);

    const today = new Date();
    const billingDate = new Date();

    // Get billing date base on trial days
    billingDate.setDate(today.getDate() + (trialPeriodDays || 0));

    // Get next billing date base on trial days
    if (interval === 'year') {
      billingDate.setFullYear(billingDate.getFullYear() + 1);
    } else {
      billingDate.setMonth(billingDate.getMonth() + 1);
    }

    const thirdRow = [];
    if (entity && entity.raw && entity.raw.subscription_renewal_date && selectedPlan.interval === 'year') {
      thirdRow.push('Fixed term');
      thirdRow.push(getFixedRenewalDates(entity.raw.subscription_renewal_date));
    } else {
      thirdRow.push(`Next billing date: ${billingDate.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })}`);
    }

    return [
      [`${product.contentCount} resources`, product.portalName],
      [
        `${price} ${selectedPlan.interval}ly`,
      ],
      thirdRow,
    ];
  }

  onSubmit = (values) => {
    const { subscription, onUpdate, onCancel } = this.props;
    const { seat = 1 } = values;
    subscription.seats = seat;
    onUpdate(subscription);
    onCancel();
  };

  render() {
    const {
      subscription,
      selectedPlanId = 'year',
      onRemove,
      storedPortalValues,
    } = this.props;
    const { product, stripePlan, entity } = subscription;
    const bottomMeta = this.getBottomMeta(subscription, selectedPlanId);
    const isLearnerSignupFlow = storedPortalValues.enableLearnerSignupFlow;

    return (
      <Form
        onSubmit={this.onSubmit}
        validateOnBlur
        initialValues={{ seat: subscription.seats }}
        validate={validation}
      >
        <View>
          <SlatMini
            marginBottom={0}
            title={product.name}
            image={product.image}
            bottomMeta={bottomMeta}
          />

          <View
            borderColor="soft"
            borderBottom={1}
            marginTop={5}
            paddingBottom={3}
          >
            <Text color="default" fontWeight="semibold" fontSize={2}>
              Plan
            </Text>
          </View>

          {stripePlan && stripePlan.filter(filterPlan => filterPlan.interval === selectedPlanId).map((plan) => {
            const planInterval = plan.interval || 'year';
            const planText = planInterval.charAt(0).toUpperCase() + planInterval.slice(1);
            return (
              <View
                key={plan.id}
                flexDirection="row"
                justifyContent="space-between"
                fontSize={2}
                marginTop={4}
                color="default"
              >
                <Text data-testid="plan-text">
                  Premium (
                  { planText }
                  )
                </Text>

                <View flexDirection="row" alignItems="center">
                  <Text fontWeight="semibold">
                    {SubscriptionFormatPrice(plan.currency, plan.amount)}
                  </Text>
                </View>
              </View>
            );
          })}

          {
            !isLearnerSignupFlow && entity.licenseType !== PackageLicenseType.PER_PORTAL
            && (
              <View marginTop={5}>
                <Text
                  color="default"
                  fontWeight="semibold"
                  fontSize={2}
                  paddingBottom={3}
                >
                  Seats
                </Text>

                <View width={foundations.spacing[9]}>
                  <Field
                    name="seat"
                    width={9}
                    defaultValue={subscription.seats}
                    hideLabel
                    stepIncrement={1}
                    minNumber={1}
                    component={Stepper}
                  />
                </View>
              </View>
            )
          }

          <View flexDirection="row" marginTop={6}>
            <ButtonFilled type="submit" data-testid="btn-submit" color="accent" marginRight={4}>{entity.licenseType !== PackageLicenseType.PER_PORTAL ? 'Update' : 'Close'}</ButtonFilled>
            <ButtonMinimal color="danger" data-testid="btn-remove" onClick={() => onRemove()}>Remove Package</ButtonMinimal>
          </View>
        </View>
      </Form>
    );
  }
}

export default EditSubscriptionPlan;
