function getErrors(values) {
  const errors = {};

  Object.entries(values).forEach(([key, value]) => {
    let keyStr = [];
    key.split('_').forEach((k, i) => {
      keyStr[i] = k.charAt(0).toUpperCase() + k.slice(1);
    });
    keyStr = keyStr.join(' ');
    if (!value) {
      errors[key] = `${keyStr} is required to register.`;
    }
  });

  return errors;
}

export default getErrors;
