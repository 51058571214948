import { SubscriptionPackage, LicenseType } from '@go1d/go1d-exchange';
import every from 'lodash/every';
import { getStripeErrorMessage } from '../utils/stripe';
import { REACT_APP_API_URL } from '../config';
import http from '../utils/http';

const baseUrl = REACT_APP_API_URL || '';

function getPlans(rawPackage) {
  return rawPackage.stripePlan || [];
}

function fetchPackages(portalId) {
  return http
    .get(`${REACT_APP_API_URL}/signup-content/packages?portalId=${portalId}&x-expensive=1`)
    .then(res => (res.data || []).map((pack) => {
      const subscriptionPackage = new SubscriptionPackage(pack);
      subscriptionPackage.loId = pack.entity.entity_id;
      return subscriptionPackage;
    }))
    .catch(() => []);
}

// To make sure all package contains same plan(s)
function mappingPackages(rawPackages) {
  if (rawPackages.length > 1) {
    // all packages have only `Monthly` plan
    const isAllMonthly = every(rawPackages, (pack) => {
      const planTexts = getPlans(pack).map(packPlan => packPlan.interval);
      return every(planTexts, 'month');
    });
    if (isAllMonthly) {
      return rawPackages;
    }

    // all packages have only `Yearly` plan
    const isAllYearly = every(rawPackages, (pack) => {
      const planTexts = getPlans(pack).map(packPlan => packPlan.interval);
      return every(planTexts, 'year');
    });
    if (isAllYearly) {
      return rawPackages;
    }

    // all packages have both `Monthly` & `Yearly` plan
    const isAllMonthlyYearly = every(rawPackages, (pack) => {
      const planTexts = getPlans(pack).map(packPlan => packPlan.interval);
      return planTexts.includes('month') && planTexts.includes('year');
    });
    if (isAllMonthlyYearly) {
      return rawPackages;
    }

    // every packages contain 'Monthly' plan
    const isAllContainsMonthly = every(rawPackages, (pack) => {
      const planTexts = getPlans(pack).map(packPlan => packPlan.interval);
      return planTexts.includes('month');
    });
    if (isAllContainsMonthly) {
      return rawPackages.map((rawPack) => {
        /* eslint-disable no-param-reassign */
        rawPack.stripePlan = rawPack.stripePlan.filter(packPlan => packPlan.interval === 'month');
        return rawPack;
      });
    }

    const isAllContainsYearly = every(rawPackages, (pack) => {
      const planTexts = getPlans(pack).map(packPlan => packPlan.interval);
      return planTexts.includes('year');
    });
    if (isAllContainsYearly) {
      return rawPackages.map((rawPack) => {
        /* eslint-disable no-param-reassign */
        rawPack.stripePlan = rawPack.stripePlan.filter(packPlan => packPlan.interval === 'year');
        return rawPack;
      });
    }

    // every packages only 'Monthly' or 'Yearly' plan
    return rawPackages
      .filter((pack) => {
        const planTexts = getPlans(pack).map(packPlan => packPlan.interval);
        return planTexts.includes('year');
      })
      .map((rawPack) => {
        /* eslint-disable no-param-reassign */
        rawPack.stripePlan = rawPack.stripePlan.filter(packPlan => packPlan.interval === 'year');
        return rawPack;
      });
  }

  return rawPackages;
}

/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
export const getAvailablePackages = async ({ portalId = 500470 }, onboardValues) => {
  const { portal = {} } = onboardValues;
  const { seats = 1 } = portal;

  return fetchPackages(portalId)
    .then((packagesList) => {
      const allPackages = packagesList.reduce((prev, next) => prev.concat(next), []);
      return mappingPackages(allPackages.filter(pack => getPlans(pack).length > 0)).map((pack) => {
        pack.seats = pack.entity.licenseType !== LicenseType.PER_PORTAL ? seats : 1;
        return pack;
      });
    });
};

const getPercentageAmount = (amount, percentage) => {
  const priceTax = (amount * percentage) / 100;
  return Number(priceTax.toFixed(2));
};

export const getTotalPrice = (packages, planInterval = 'year') => {
  const plansAmount = [];
  packages.forEach((pack) => {
    const plan = pack.stripePlan.find(p => p.interval === planInterval);
    if (plan) {
      plansAmount.push((plan.amount * pack.seats));
    }
  });

  if (plansAmount.length) {
    return plansAmount.reduce((prev, next) => prev + next, 0);
  }
  return 0;
};

export const getDiscount = (packages, planInterval = 'year', coupons = {}) => {
  const plansAmount = [];
  packages.forEach((pack) => {
    const coupon = coupons[pack.id];
    const plan = pack.stripePlan.find(p => p.interval === planInterval);
    if (plan && coupon) {
      const total = (plan.amount * pack.seats);
      plansAmount.push(getPercentageAmount(total, coupon.stripe_coupon.percent_off));
    }
  });
  if (plansAmount.length) {
    return plansAmount.reduce((prev, next) => prev + next, 0);
  }
  return 0;
};


export const purchaseSubscription = async (subscribeParams, jwt) => {
  const headers = { Authorization: `Bearer ${jwt}` };
  return http
    .post(`${baseUrl}/content-subscription/subscribe`, subscribeParams, { headers })
    .then(res => {
      return res;
    }).catch(err => {
      const errorData = err && err.response && err.response.data && err.response.data;
      let errorMsg = errorData ? errorData.message : 'Failed to purchase subscriptions, please try again or contact us for assistance';

      const stripeErrorMessage = errorData && errorData.errors && errorData.errors[0] && errorData.errors[0].status === 400 && getStripeErrorMessage(errorData.errors[0].title);
      if (stripeErrorMessage) {
        errorMsg = stripeErrorMessage;
      }
      throw new Error(errorMsg);
    });
};

export const validateCoupons = (couponCode, productIds = [], jwt = '') => {
  const headers = { Authorization: `Bearer ${jwt}` };
  const productsString = productIds.reduce((previous, id) => `${previous}&product_id[]=${id}`, '');
  return http
    .get(`${REACT_APP_API_URL}/content-subscription/coupons?code=${couponCode}${productsString}`, { headers })
    .then(res => {
      return res.data;
    })
    .catch(() => []);
};
