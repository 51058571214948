export const PACKAGES = {
  STORE_PACKAGES: 'STORE_PACKAGES',
  ADD_PACKAGE: 'ADD_PACKAGE',
  REMOVE_PACKAGE: 'REMOVE_PACKAGE',
  SELECT_PACKAGE_PLAN: 'SELECT_PACKAGE_PLAN',
  UPDATE_PACKAGE: 'UPDATE_PACKAGE',
};

export const packageActions = {
  storePackages(payload) {
    const { packages, selectedPackageIds } = payload;
    let actionPayload = {};
    if (packages) {
      actionPayload = { ...actionPayload, packages };
    }
    if (selectedPackageIds) {
      actionPayload = { ...actionPayload, selectedPackageIds };
    }
    return {
      type: PACKAGES.STORE_PACKAGES,
      payload: actionPayload,
    };
  },
  addPackage(packageId) {
    return {
      type: PACKAGES.ADD_PACKAGE,
      payload: packageId,
    };
  },
  removePackage(packageId) {
    return {
      type: PACKAGES.REMOVE_PACKAGE,
      payload: packageId,
    };
  },
  selectPackagePlan(packagePlanId) {
    return {
      type: PACKAGES.SELECT_PACKAGE_PLAN,
      payload: packagePlanId,
    };
  },
  updatePackage(pack) {
    return {
      type: PACKAGES.UPDATE_PACKAGE,
      payload: pack,
    };
  },
};
