import { setAccountEckValues } from '../services/eck';
import registerUser from '../services/userRegister';
import { findAccount } from '../services/user';
import getNested from './getNested';

export default async (storedFormValues, storedPortalValues) => {
  const {
    email, password, first_name, last_name, jwt,
  } = storedFormValues;
  const {
    portalId, signupIntoExistingPortal, enableLearnerSignupFlow, siteDomain, customFields,
  } = storedPortalValues;
  const existingPortalId = ((signupIntoExistingPortal || enableLearnerSignupFlow) && portalId) ? parseInt(`${portalId}`, 10) : '';

  const eckFields = {};
  const formFields = Object.keys(storedFormValues);
  if (customFields && formFields) {
    customFields.forEach((cField) => {
      const matched = formFields.find(fField => fField === cField.name);
      if (matched) {
        const val = storedFormValues[cField.name];
        const isMandatory = !!((cField.mandatory === '1' || cField.mandatory === 1 || cField.mandatory === true));
        if (val || (isMandatory && val)) {
          eckFields[cField.name] = [{ value: val }];
        }
      }
    });
  }

  const res = await registerUser({
    email, password, first_name, last_name, portal: existingPortalId, jwt,
  });
  const user = res && res.data;
  const newUserJwt = user.jwt;
  // sends eck fields
  if (eckFields && Object.keys(eckFields).length > 0) {
    const targetAccountId = findAccount(user.accounts, portalId) || getNested(['accounts', 0, 'id'], user);
    await setAccountEckValues(siteDomain, targetAccountId, eckFields, newUserJwt);
  }

  // stores JWT
  localStorage.setItem('jwt', newUserJwt);

  return user;
};
