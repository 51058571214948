import http from '../utils/http';
import { REACT_APP_API_URL } from '../config';

function getAccountCustomFields(domain) {
  return http
    .get(`${REACT_APP_API_URL}/eck/fields/${domain}/account`)
    .then(res => res.data);
}

export const setAccountEckValues = (domain, userId, eckValues, jwt) => {
  const body = { fields: eckValues };
  const headers = { headers: { Authorization: `Bearer ${jwt}` } };
  return http
    .post(`${REACT_APP_API_URL}/eck/entity/${domain}/account/${userId}`, body, headers)
    .then(res => res.data)
    .catch([]);
};

export default getAccountCustomFields;
