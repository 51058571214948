import { REACT_APP_PORTAL_ROOT_DOMAIN } from '../config';

export function userRequest(values) {
  const user = {
    email: values.email,
    first_name: values.first_name,
    last_name: values.last_name,
    password: values.password,
    consent: {
      term_privacy: true,
      term_tos: true,
      term_collection: true,
    },
  };
  if (values.phone) {
    user.phone = values.phone;
  }

  return user;
}

export function portalRequest(values, num_of_seats, companySizeStepper = false) {
  let seats;
  if (num_of_seats) {
    seats = num_of_seats;
  } else if (values) {
    if (!values.companySize) {
      seats = null;
    } else if (values.companySize === '1-20') {
      seats = 20;
    } else if (values.companySize === '20-100') {
      seats = 100;
    } else {
      seats = 101;
    }
  } else {
    seats = 1;
  }

  if (companySizeStepper) {
    seats = values.companySize;
  }

  let domain;
  if (values && values.portalName) {
    domain = `${values.portalName.toLowerCase()}.${REACT_APP_PORTAL_ROOT_DOMAIN}`;
  }

  return {
    domain,
    seats,
  };
}

export function customerRequest(values) {
  const customer = {
    name: values && values.companyName ? values.companyName : '',
    industry: values && values.industry ? values.industry : '',
    country: values && values.country ? values.country : '',
    billing_interval:
    values && values.payment_interval === 'Monthly' ? 'monthly' : 'annual',
  };
  if (values && values.credit_card_token && values.credit_card_token.token) {
    customer.stripe_token = values.credit_card_token.token.id;
  }
  return customer;
}

export function validateRequest(user, portal, customer) {
  const errors = {};
  Object.entries(user, portal, customer).forEach(([key, value]) => {
    let keyStr = [];
    key.split('_').forEach((k, i) => {
      keyStr[i] = k.charAt(0).toUpperCase() + k.slice(1);
    });
    keyStr = keyStr.join(' ');
    if (!value) {
      errors[key] = `${keyStr} is required to register.`;
    }
  });
  return errors;
}
