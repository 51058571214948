import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Button,
  ButtonMinimal,
  ButtonFilled,
  Container,
  TagToggle,
  Text,
  View,
} from '@go1d/go1d';
import IconChevronRight from '@go1d/go1d/build/components/Icons/ChevronRight';
import IconChevronLeft from '@go1d/go1d/build/components/Icons/ChevronLeft';
import IconChevronDown from '@go1d/go1d/build/components/Icons/ChevronDown';
import { union, memoize } from 'lodash';
import {
  getInterestedData,
  updateInterestedTopic,
  getPredefinedTopics,
} from '../../services/recommender';
import { findAccount, redirectToPortalWithOTT } from '../../services/user';
import { REACT_APP_BASE_DIRECTORY } from '../../config';
import LogoContainer from '../../components/LogoContainer';
import getNested from '../../utils/getNested';

export default class WhatToLearn extends React.Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    storedFormValues: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any),
  };

  state = {
    topicList: [],
    remainTopicList: [],
    pickedList: [],
    hasPickedTopic: false,
    hrefQuery: null,
  };

  pickTopic = memoize(topic => () => {
    const { pickedList } = this.state;
    const index = pickedList.indexOf(topic);
    if (index >= 0) {
      pickedList.splice(index, 1);
    } else {
      pickedList.push(topic);
    }
    const hasPickedTopic = pickedList.length > 0;
    this.setState({ pickedList: [...pickedList], hasPickedTopic });
  });

  componentDidMount() {
    const { storedFormValues, history, location } = this.props;
    if (!storedFormValues || !storedFormValues.user || !storedFormValues.portal) {
      history.push(`${REACT_APP_BASE_DIRECTORY}`);
      return;
    }
    const hrefQuery = location && location.search ? QueryString.parse(location.search) : null;
    this.setState({ hrefQuery });
    const { storedFormValues: { portal } } = this.props;
    getPredefinedTopics(portal.id, 2).then((res) => {
      const remainTopicList = res.slice(15, res.length);
      let topicList = res.slice(0, 15);
      let pickedList = [];
      getInterestedData(portal.id).then((response) => {
        pickedList = response.data.topic ? response.data.topic : [];
      }, () => { }).finally(() => {
        const hasPickedTopic = pickedList && pickedList.length > 0;
        topicList = union(topicList, pickedList);
        this.setState({
          topicList,
          pickedList,
          hasPickedTopic,
          remainTopicList,
        });
      });
    });
  }

  skip = () => {
    const { storedFormValues: { portal } } = this.props;
    updateInterestedTopic(portal.id, [])
      .then(() => (this.goToDashboardPage()));
  }

  done = () => {
    const { pickedList } = this.state;
    if (!pickedList || pickedList.length <= 0) {
      return;
    }
    const { storedFormValues: { portal } } = this.props;
    updateInterestedTopic(portal.id, pickedList)
      .then(() => (this.goToDashboardPage()));
  }

  goBack = () => {
    const { history, storedFormValues: { portal }, location: { search } } = this.props;
    const { pickedList } = this.state;
    updateInterestedTopic(portal.id, pickedList)
      .then(() => history.push(`${REACT_APP_BASE_DIRECTORY}/whos_learning${search}`));
  }

  selectOtherTopic = (topic, stateAndHelpers) => {
    const { topicList, pickedList } = this.state;
    if (!topic) {
      return;
    }
    if (topicList.indexOf(topic) >= 0) {
      stateAndHelpers.clearSelection();
      if (pickedList.indexOf(topic) < 0) {
        pickedList.push(topic);
      }
      const hasPickedTopic = pickedList.length > 0;
      this.setState({ pickedList, hasPickedTopic });
      return;
    }
    topicList.push(topic);
    pickedList.push(topic);
    const hasPickedTopic = true;
    this.setState({ topicList, pickedList, hasPickedTopic });
    stateAndHelpers.clearSelection();
  }

  showMoreTopics = () => {
    const { topicList, remainTopicList, pickedList } = this.state;
    if (remainTopicList.length === 0) {
      return;
    }
    const newTopicList = union(topicList, remainTopicList, pickedList);
    this.setState({ topicList: newTopicList, remainTopicList: [] });
  }

  goToDashboardPage() {
    const { storedFormValues: { portal, user } } = this.props;
    const portalId = portal.id;
    // find correct account id
    const targetAccountId = findAccount(user.accounts, portalId) || getNested(['accounts', 0, 'id'], user);
    // By default without reactDestination, apiom redirects to react app.dashboard
    const redirectUrl = '';
    redirectToPortalWithOTT(targetAccountId, user.jwt, redirectUrl).catch(() => { });
  }

  renderTopics = () => {
    const { topicList, pickedList } = this.state;
    if (!topicList || topicList.length <= 0 || !pickedList) {
      return [];
    }
    const topics = [];
    topicList.forEach((topic, i) => {
      topics.push(
        <View
          key={i}
          marginRight={4}
          marginBottom={4}
          flexDirection="column"
          flexWrap="wrap"
        >
          <TagToggle
            onChange={this.pickTopic(topic)}
            value={pickedList.indexOf(topic) >= 0 ? 1 : ''}
          >
            {topic}
          </TagToggle>
        </View>,
      );
    });
    return topics;
  }

  render() {
    const { hasPickedTopic, remainTopicList, hrefQuery } = this.state;
    const rightIconStyle = { display: 'inline', marginLeft: '5px' };
    return (
      <Container paddingX={4} paddingBottom={4} contain="normal" alignItems="center">
        <Container contain="narrow" alignItems="flex-start" marginTop={6} flexDirection="row" css={{ flexWrap: 'wrap' }} justifyContent="center">
          <LogoContainer
            title="What do they need to learn"
            description="We’ll help you find the content you need for your company"
          >

            <View>
              <Text color="subtle" marginBottom={6}>
                What topics are relevant to your company?
              </Text>
              <View
                flexDirection="row"
                flexWrap="wrap"
                flexGrow={1}
              >
                {this.renderTopics()}
              </View>
              {remainTopicList.length > 0 && (
                <View flexDirection="row">
                  <Button paddingLeft={0} onClick={this.showMoreTopics}>
                    Show more topics
                    <IconChevronDown style={rightIconStyle} />
                  </Button>
                </View>
              )}
              <View
                flexDirection="row"
                flexWrap="wrap"
                marginTop={6}
              >
                <View
                  flexDirection="row"
                  flexWrap="wrap"
                  flexGrow={1}
                  justifyContent="flex-start"
                >
                  <ButtonMinimal size="lg" iconName={IconChevronLeft} onClick={this.goBack}>Back</ButtonMinimal>
                </View>
                <View
                  flexDirection="row"
                  flexWrap="wrap"
                  flexGrow={1}
                  justifyContent="flex-end"
                >
                  <ButtonMinimal size="lg" marginRight={4} onClick={this.skip}>Skip</ButtonMinimal>
                  {hrefQuery && (
                    <ButtonFilled size="lg" color="accent" onClick={this.done} disabled={!hasPickedTopic}>
                      Save
                    </ButtonFilled>
                  )}
                  {!hrefQuery && (
                    <ButtonFilled size="lg" color="accent" onClick={this.done} disabled={!hasPickedTopic}>
                      Explore
                      <IconChevronRight style={rightIconStyle} />
                    </ButtonFilled>
                  )}
                </View>
              </View>
            </View>
          </LogoContainer>
        </Container>
      </Container>
    );
  }
}
