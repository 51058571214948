export const ONBOARD = {
  STORE_VALUES: 'ONBOARD_STORE_VALUES',
};

export const onboardActions = {
  storeValues(payload) {
    return {
      type: ONBOARD.STORE_VALUES,
      payload,
    };
  },
};
