import axios from 'axios';
import { REACT_APP_API_URL, REACT_APP_USE_COOKIE_SESSION } from '../config';

axios.defaults.baseURL = `${REACT_APP_API_URL}`;

const shouldUseCookieSession = (portalsForCookieSession, portalTitle) => {
  if (portalsForCookieSession) {
    const portalsArray = portalsForCookieSession.split(',');
    if (portalsArray.includes('*') || (portalTitle && portalsArray.includes(portalTitle))) {
      return true;
    }
  }
  return false;
};

axios.interceptors.request.use((reqConfig) => {
  const useCookieSession = shouldUseCookieSession(REACT_APP_USE_COOKIE_SESSION, window.location.hostname);

  if (!useCookieSession) return reqConfig;

  const updatedConfig = { ...reqConfig };
  const urlObject = new URL(reqConfig.url);
  updatedConfig.baseURL = '/api';
  updatedConfig.url = urlObject.pathname + urlObject.search + urlObject.hash;
  delete updatedConfig.headers.Authorization;
  delete updatedConfig.headers.authorization;
  return updatedConfig;
});

export const setCredentials = (token) => {
  if (token) {
    axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
    };
  } else {
    delete axios.defaults.headers;
  }
};

export default axios;
