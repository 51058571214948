import React from 'react';
import {
  Banner,
  Container,
  Text,
  View,
  Form,
  Field,
  SubmitButton,
  ButtonFilled,
  UL,
  LI,
  PasswordInput,
} from '@go1d/go1d';
import IconSuccess from '@go1d/go1d/build/components/Icons/Success';
import IconNotPassed from '@go1d/go1d/build/components/Icons/NotPassed';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { loginWithOnTimeToken, setNewPassword } from '../../services/user';
import { getNested, setCredentials } from '../../utils';
import { REACT_APP_PUBLIC_PORTAL } from '../../config';
import LogoContainer from '../../components/LogoContainer';


export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetSuccess: false,
      errorCode: 0,
      errorMessage: '',
      tokenError: false,
      jwt: '',
      uuid: '',
      instance: '',
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { one_time_token } = match.params;

    loginWithOnTimeToken(one_time_token).then(
      (result) => {
        const jwt = getNested(['data', 'jwt'], result);
        this.setState({ jwt, uuid: getNested(['data', 'uuid'], result), instance: getNested(['data', 'instance'], result) });
        setCredentials(jwt);
      },
      () => {
        this.setState({ tokenError: true });
      },
    );
  };

  doResetPassword = (formValues, actions) => {
    let errorCode = 500;
    let errorMessage = (
      <Text>
        Something went terribly wrong, please try again later or contact support at support@go1.com for assistance.
      </Text>
    );
    this.setState({ resetSuccess: false });
    const { instance } = this.state;
    setNewPassword(formValues.password, instance).then(
      () => {
        const { jwt } = this.state;
        localStorage.setItem('jwt', jwt);
        this.setState({ errorCode: 0, errorMessage: '', resetSuccess: true });
        actions.setSubmitting(false);
      },
      (error) => {
        if (error.response) {
          errorCode = getNested(['response', 'status'], error);
          const errors = getNested(['response', 'data', 'errors'], error);
          if (errors) {
            errorMessage = (
              <View marginTop={4}>
                Please change your password according following suggestions:
                <UL iconName={IconNotPassed} iconColor="danger" paddingX={0} paddingY={4}>
                  {errors.map((errorItem, index) => <LI key={index}>{errorItem}</LI>)}
                </UL>
              </View>
            );
          } else {
            errorMessage = (<Text>{getNested(['response', 'data', 'message'], error)}</Text>);
          }
        }
        this.setState({ errorCode, errorMessage });
        actions.setSubmitting(false);
      },
    );
  };

  validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Please enter a new password';
    } else if (values.password.length < 8) {
      errors.password = 'Your new password must have at least 8 characters';
    }
    return errors;
  };

  render() {
    const {
      errorCode, errorMessage, resetSuccess, tokenError, uuid,
    } = this.state;

    const portalAddress = `${REACT_APP_PUBLIC_PORTAL}`;

    return (
      <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
        <Helmet>
          <title>Go1 Reset password</title>
        </Helmet>
        {tokenError && (
          <Container data-testid="resetPasswordContainerTokenInvalid" contain="normal" alignItems="center" css={{ flexWrap: 'wrap' }} justifyContent="center">
            <LogoContainer
              title="Link Expired"
            >
              <View textAlign="center" marginBottom={4} marginX={4} alignItems="center">
                <IconNotPassed size={8} color="danger" />
              </View>
              <View textAlign="center" marginTop={6} marginX={4}>
                <Text color="subtle">
                  Your reset password link expired or is invalid.
                  <ButtonFilled marginY={6} justifyContent="center" color="accent" data-testid="resend-link" href="/user/reset_password">Resend reset password link</ButtonFilled>
                </Text>
              </View>
            </LogoContainer>
          </Container>
        )}
        {resetSuccess && (
          <Container data-testid="successMessageContainer" contain="normal" alignItems="center" justifyContent="center">
            <LogoContainer
              title="Password Changed"
              description="Your password has been updated successfully."
            >
              <View textAlign="center" marginBottom={4} marginX={4} alignItems="center">
                <IconSuccess size={8} color="success" />
              </View>
              <View textAlign="center" marginTop={2} marginX={4}>
                <Text color="subtle">
                  You are now logged in
                  <br />
                  <ButtonFilled marginTop={6} justifyContent="center" color="accent" data-testid="resend-link" href={`https://${portalAddress}/p/#/token?token=${uuid}`}>Go to dashboard</ButtonFilled>
                  .
                </Text>
              </View>
            </LogoContainer>
          </Container>
        )}
        {!resetSuccess && !tokenError && uuid && (
          <Container data-testid="resetPasswordContainer" contain="normal" alignItems="center" css={{ flexWrap: 'wrap' }} justifyContent="center">
            <LogoContainer
              title="Reset password"
              description="Enter your new password for your Go1 account."
              errorBannerLayout={errorCode}

            >
              {errorCode > 0 && (
                <Banner type="danger" width="100%" maxWidth={620}>
                  <Text fontWeight="semibold">Password reset failed</Text>
                  {errorMessage}
                </Banner>
              )}
              <Form
                onSubmit={this.doResetPassword}
                validate={this.validate}
                initialValues={{
                  password: '',
                }}
              >
                <View
                  marginBottom={5}
                >
                  <Field
                    component={PasswordInput}
                    name="password"
                    label="Password"
                    required
                  />
                </View>
                <SubmitButton
                  justifyContent="center"
                  size="lg"
                  id="submitBtn"
                  css={{
                    width: '100%',
                  }}
                >
                  Change my password
                </SubmitButton>
              </Form>
            </LogoContainer>
          </Container>
        )}
      </Container>
    );
  }
}

ResetPassword.propTypes = {
  match: PropTypes.shape({}),
};
