import React from 'react';
import {
  Text,
  View,
  Container,
  Prose,
} from '@go1d/go1d';


const TextContainer = ({
  title,
  userText,
  defaultText,
  backgroundColor,
  paddingX,
  marginBottom,
  paddingY,
  ...props
}) => (

  <Container
    padding={4}
  >
    <View
      {...props}
    >
      {title && (
        <Text
          fontSize={4}
          fontWeight="semibold"
          textAlign="start"
          marginTop={6}
          marginBottom={marginBottom}
        >
          {title}
        </Text>
      )}
    </View>
    <View
      paddingX={paddingX}
      paddingY={paddingY}
      borderRadius={2}
      backgroundColor={backgroundColor}
    >
      {userText && (
        <View marginY={3}>
          <Prose HTML={userText} />
        </View>
      )}
      {defaultText && (
        <View marginY={3}>
          <Text
            color="default"
            textAlign="start"
            lineHeight="paragraph"
          >
            {defaultText}
          </Text>
        </View>
      )}
    </View>
  </Container>
);

export default TextContainer;
