export const PORTAL = {
  STORE_VALUES: 'PORTAL_STORE_VALUES',
};

export const PORTAL_PLAN = {
  PREMIUM: 'premium',
  PLATFORM: 'platform',
  WEBSITE_FREE: 'website_free',
  WEBSITE_PREMIUM: 'website_premium',
  REFERRAL_PARTNER: 'referral_partner',
  REFERRAL_CONTENT_PARTNER: 'content_package_purchasing',
};

export const portalActions = {
  storeValues(payload) {
    return {
      type: PORTAL.STORE_VALUES,
      payload,
    };
  },
};
