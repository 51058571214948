import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import qs from 'query-string';
import Cookies from 'js-cookie';
import {
  Banner,
  ButtonFilled,
  Container,
  Field,
  Form,
  Link,
  Provider,
  PasswordInput,
  SubmitButton,
  Text,
  TextInput,
  View,
  foundations,
} from '@go1d/go1d';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { REACT_APP_BASE_DIRECTORY } from '../../config';
import {
  loginUser, redirectToPortalWithOTT, getCurrentUser, loadAllAccounts,
} from '../../services/user';
import getNested from '../../utils/getNested';
import LogoContainer from '../../components/LogoContainer';
import SsoLoginButton from '../../components/SsoLoginButton';
import getSSOConfig from '../../services/sso';
import SSOMappedErrorMessage from '../../components/SSOMappedErrorMessage';
import MicrosoftLogo from './MicrosoftLogo.svg';


const BaseDirectory = REACT_APP_BASE_DIRECTORY || '';

export default class Login extends React.Component {
  supportedSSOList = [];

  constructor(props) {
    super(props);
    const { storedPortalValues = {} } = props;
    const { redirectUrl } = storedPortalValues;
    this.state = {
      errorCode: 0,
      errorTitle: '',
      errorMessage: '',
      ssoLoading: true,
      ssoErrorStatus: '',
      ssoSessionCode: '',
      ssoIdentityProvider: '',
      ssoConnections: [],
      redirect_url: redirectUrl || '',
      ssoButtons: props.ssoButtons ? props.ssoButtons : {},
      showForm: true,
    };

    this.supportedSSOList = ['azure', 'azure-b2c', 'go1-auth0'];

    const query = get(window, 'location.search', '');
    if (query) {
      const searchQuery = qs.parse(query);
      this.state.email = searchQuery.email;
      this.state.ssoErrorStatus = searchQuery.error_code;
      this.state.ssoSessionCode = searchQuery.session_id;
      this.state.ssoIdentityProvider = searchQuery.identity_provider;
      const queryRedirect = searchQuery.redirect_url || searchQuery.redirect; // redirect is deprecated use redirect_url instead
      if (queryRedirect) {
        this.state.redirect_url = queryRedirect;
      }
    }
  }


  componentDidMount() {
    const { portal = '', ssoAllowLogin = true } = this.props;
    const { redirect_url } = this.state;

    // Do not load SSO if we are coming from signup to ask the person to login, @TODO need a better way to determin if we are coming from signup
    if (redirect_url !== 'signup') {
      getSSOConfig(portal, redirect_url)
        .then((response) => {
          if (response && response.length > 0) {
            const ssoProviderList = (response || []).filter(
              providerDetails => this.supportedSSOList.includes(providerDetails.provider),
            );
            if (!ssoAllowLogin && ssoProviderList.length === 1) {
              const link = get(ssoProviderList[0], 'links.authorize', null);
              // Move the logic to add the redirect_url to backend, I think it's currently not done with SSO v2 connections
              const url = `${link}${redirect_url && !link.includes('redirect_url') ? `?redirect_url=${encodeURIComponent(redirect_url)}` : ''}`;
              window.location.assign(url);
            }
            this.setState({
              ssoConnections: ssoProviderList,
              showForm: !(ssoProviderList.length > 0),
              ssoLoading: false,
            });
          }
          // if sso not enabled, try to login with stored jwt
          this.loginWithExistingSession();
        }, () => {
        });
    }
  }

  loginWithExistingSession = () => {
    const session = Cookies.get('go1');
    if (session) {
      const parts = session.split(':');
      if (parts.length === 4) {
        const jwt = parts[3];
        getCurrentUser(jwt)
          .then((res) => {
            const accounts = getNested(['data', 'accounts'], res);
            this.loginWithAccount(accounts[0], jwt, null);
          });
      }
    }
  }

  loginWithAccount = (account, jwt, actions) => {
    const { redirect_url } = this.state;

    redirectToPortalWithOTT(account.id, jwt, redirect_url).catch((error) => {
      let errorCode = 413;
      let errorMessage = (
        <Text>
          Your account has too many portals, please contact
          support at support@go1.com for assistance
        </Text>
      );
      if (error.response) {
        errorCode = error.response.status;
        errorMessage = <Text>{error.response.message}</Text>;
      }
      this.setState({ errorCode, errorMessage, errorTitle: 'Account Error' });
      if (actions) {
        actions.setSubmitting(false);
      }
    });
  }

  doLogin = (formValues, actions) => {
    const { portal = 'public.mygo1.com', storedPortalValues = {} } = this.props;
    const { redirect_url } = this.state;
    const {
      signupIntoExistingPortal, portalId, redirectUrl: finalRedirectUrl, ignorePortal, portalSelectionFilter, adminOnly,
    } = storedPortalValues;

    let errorCode = 500;
    let errorTitle = 'Login failed';
    let errorMessage = (
      <Text>
        Something went terribly wrong, please try again later or contact support at support@go1.com for assistance.
      </Text>
    );
    // have to perform loginUser twice because we might have the usecase where somebody logs in on the DP's portals login page, to get into a child portal
    loginUser(formValues.email, formValues.password, '').then(
      async (payload) => {
        const { history } = this.props;
        const jwt = getNested(['data', 'jwt'], payload);
        let accounts = await loadAllAccounts(jwt) || [];
        // Enforced account requirements
        if (adminOnly) {
          // inactive accounts do not have roles attached
          accounts = accounts.filter(account => account.roles && account.roles.includes('administrator'));
          if (accounts.length === 0) {
            errorMessage = (<Text>You need to be an administrator of a portal to use this function.</Text>);
            this.setState({ errorCode, errorMessage, errorTitle });
            actions.setSubmitting(false);
            return;
          }
        }

        let accountsForPortalSelection = accounts;

        // Nice to have account requirements, ENFORCING portalSelectionFilter RULES AND DISPLAY ERROR MESSAGE SHOULD BE DONE IN TARGET APPLICATION
        if (portalSelectionFilter === 'partner') {
          accountsForPortalSelection = accountsForPortalSelection.filter(account => account.instance && (account.instance.type === 'distribution_partner' || account.instance.type === 'content_partner'));
        }

        localStorage.setItem('jwt', jwt);
        // redirect_url === signup -> User existed and has been asked to login, so we need to redirect back to signup with finalRedirectUrl which has been stored in redux on page load.
        // @TODO need a better way to determin if we are coming from signup
        if (redirect_url === 'signup') {
          window.location.assign(`${BaseDirectory}/signup?${portalId ? `&portal=${portalId}` : ''}${signupIntoExistingPortal ? `&signupIntoExisting=${signupIntoExistingPortal}&redirect_url=${finalRedirectUrl}` : ''}`);
          return;
        }

        if (!ignorePortal && (accounts.length === 0)) {
          // no accounts returned from API
          errorMessage = (<Text>No accounts found for your user</Text>);
          this.setState({ errorCode, errorMessage, errorTitle });
          actions.setSubmitting(false);
        } else {
          // If portal selection has only one account left after filtering, login using that one.
          let matchingAccount = accountsForPortalSelection.length === 1 ? accountsForPortalSelection[0] : null;

          // if no account fit the nice to have account requirements, login with first matching account,ENFORCING portalSelectionFilter RULES AND DISPLAY ERROR MESSAGE SHOULD BE DONE IN TARGET APPLICATION
          // need to check if accounts is there again in case of "ignorePortal"
          if (accountsForPortalSelection.length === 0 && accounts.length > 0) {
            matchingAccount = get(accounts, '0', null);
          }

          // Check if one of the accounts match the current domain
          accounts.forEach((account) => {
            if (account.instance_name === portal && portal !== 'public.mygo1.com') {
              matchingAccount = account;
            }
          });

          if ((!matchingAccount) && !ignorePortal) {
            history.push({
              pathname: `${BaseDirectory}/portals`,
              state: {
                user: getNested(['data'], payload),
                filteredAccounts: accountsForPortalSelection,
                hideCreateNewPortal: !!portalSelectionFilter,
              },
            });
            return;
          }
          // retrieve short jwt
          loginUser(formValues.email, formValues.password, matchingAccount ? matchingAccount.instance_name : '').then(
            (payload_single) => {
              const jwt_short = getNested(['data', 'jwt'], payload_single);
              localStorage.setItem('jwt', jwt_short);
              if (jwt_short) {
                // If we are ignoring the portal, we just get the first account for now.
                // TODO: When houston implements user level logins, update this
                this.loginWithAccount(ignorePortal ? getNested(['data', 'accounts', 0], payload) : matchingAccount, jwt_short, actions);
                // is admin on account
              } else {
                this.setState({ errorCode, errorMessage, errorTitle });
                actions.setSubmitting(false);
              }
            },
            () => {
              this.setState({ errorCode, errorMessage, errorTitle });
              actions.setSubmitting(false);
            },
          );
        }
      },
      (error) => {
        if (error.response) {
          errorCode = getNested(['response', 'status'], error);
          if (errorCode === 400 || errorCode === 404) {
            errorMessage = (
              <Text>
                Please check you have entered your email or password correctly. Still having trouble?
                {' '}
                <Link to="reset_password" style={{ textDecoration: 'none' }}>
                  <Text
                    css={{ textDecoration: 'underline' }}
                    color="contrast"
                  >
                    Reset your password.
                  </Text>
                </Link>
              </Text>
            );
            errorTitle = 'Invalid email or password';
          } else {
            errorMessage = (<Text>{getNested(['response', 'data', 'message'], error)}</Text>);
          }
        }
        this.setState({ errorCode, errorMessage, errorTitle });
        actions.setSubmitting(false);
      },
    );
  };

  validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'You must enter an email.';
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(values.email)) {
      errors.email = 'Invalid email format.';
    }
    if (values.password.length === 0) {
      errors.password = 'You must enter a password.';
    }

    return errors;
  };

  render() {
    const {
      email, errorCode, errorMessage, errorTitle, ssoConnections, ssoErrorStatus, ssoSessionCode, ssoIdentityProvider, ssoButtons, ssoLoading, showForm, redirect_url,
    } = this.state;
    const {
      ssoAllowLogin,
      logo,
      allowRegister,
      siteName,
      storedPortalValues = {},
    } = this.props;
    const date = new Date();
    const year = date.getFullYear();

    /* eslint-disable no-param-reassign */
    const ssoProviders = ssoConnections.map((providerDetails) => {
      switch (providerDetails.provider) {
        case 'azure':
          providerDetails.title = ssoButtons.azure || 'Microsoft';
          if (providerDetails.title === 'Microsoft') {
            providerDetails.image = MicrosoftLogo;
          }
          break;
        case 'azure-b2c':
          providerDetails.title = ssoButtons.azure_b2c || 'Azure B2C';
          break;
        case 'go1-auth0':
          providerDetails.title = ssoButtons.go1_auth0 || 'Identity Provider';
          break;
        default:
      }
      return providerDetails;
    });
    /* eslint-enable no-param-reassign */
    const links = [
      { label: 'Terms & conditions', url: '/policy/user-terms' },
      { label: 'Privacy policy', url: '/policy/privacy-policy' },
      { label: `© ${siteName} Copyright ${year}`, url: '/policy/copyright' },
    ];

    const initialValues = {
      email,
      password: '',
    };

    const {
      loginTagline,
      loginSecondaryTagline,
      referral,
      redirectUrl,
    } = storedPortalValues;

    const partnerHubLogin = redirectUrl && redirectUrl.includes('partnerhub') ? 'Log in to Partner Hub' : false;
    const websitePresetReferral = '449228';

    return (
      <Provider linkComponent={RouterLink}>
        <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
          <Helmet>
            {!logo && (<title>Go1 Log in</title>)}
            {logo && (<title>Log in</title>)}
          </Helmet>
          <Container contain="narrow" marginTop={6}>
            <LogoContainer
              title={loginTagline || partnerHubLogin || (logo ? 'Log in' : 'Log in to Go1 ')}
              description={loginSecondaryTagline || (logo || partnerHubLogin ? '' : 'Access thousands of learning items from top content providers.')}
              errorBannerLayout={errorCode || ssoErrorStatus}
            >
              {ssoErrorStatus && (
                <SSOMappedErrorMessage errorStatus={ssoErrorStatus} sessionCode={ssoSessionCode} identityProvider={ssoIdentityProvider} />
              )}

              {errorCode > 0 && (
                <Banner type="danger" width="100%" maxWidth={620} marginBottom={6}>
                  <Text fontWeight="semibold">{errorTitle}</Text>
                  {errorMessage}
                </Banner>
              )}

              <View marginTop={loginSecondaryTagline && ssoProviders.length > 0 ? '-48px' : ''}>
                {ssoProviders.length > 0 && ssoProviders.map((ssoProvidersDetails, providersIndex) => (
                  <SsoLoginButton
                    key={providersIndex}
                    ssoLink={() => window.location.assign(`${ssoProvidersDetails.links.authorize}`)}
                    marginTop={logo ? [3, 5] : ''}
                    title={ssoProvidersDetails.title}
                    image={ssoProvidersDetails.image}
                    loading={ssoLoading}
                    color={['Microsoft', 'Azure B2C', 'Identity Provider'].includes(ssoProvidersDetails.title) ? 'background' : 'accent'}
                  />
                ))}
              </View>
              {!showForm && ssoAllowLogin && (
                <View textAlign="center">
                  <Text fontWeight="semibold" color="subtle">
                    If you have a Go1 account,
                    {' '}
                    <Text
                      color="accent"
                      fontWeight="semibold"
                      onClick={() => this.setState({ showForm: true })}
                      css={{ cursor: 'pointer' }}
                    >
                      log in here
                    </Text>
                  </Text>
                </View>
              )}
              {showForm && (
                <View>
                  {ssoProviders.length > 0 && (
                    <View
                      flexDirection="row"
                      marginBottom={[6, 7]}
                      marginTop={[4, 5]}
                      borderColor="faded"
                      borderBottom={1}
                      justifyContent="center"
                    >
                      <Text
                        color="subtle"
                        fontWeight="semibold"
                        paddingTop="-5px"
                        marginBottom="-10px"
                        textAlign="center"
                        css={{
                          backgroundColor: foundations.colors.background,
                          width: '50px',
                        }}
                      >
                        OR
                      </Text>
                    </View>
                  )}
                  <Form
                    onSubmit={this.doLogin}
                    validate={this.validate}
                    initialValues={initialValues}
                  >
                    <View
                      marginBottom={5}
                    >
                      <Field
                        component={TextInput}
                        name="email"
                        label="Email"
                        type="email"
                        onChange={(event) => {
                          const { dispatchStoreValues } = this.props;
                          dispatchStoreValues({ login_email: event.target.value });
                        }}
                        required
                      />
                    </View>
                    <View
                      marginBottom={7}
                    >
                      <Field
                        component={PasswordInput}
                        name="password"
                        label="Password"
                        required
                      />
                      <Link
                        to="reset_password"
                        hoverFocusColor="muted"
                        activeColor="default"
                      >
                        <Text
                          color="subtle"
                          style={{ textDecoration: 'underline' }}
                        >
                          Forgot your password?
                        </Text>
                      </Link>
                    </View>
                    <SubmitButton
                      justifyContent="center"
                      size="lg"
                      id="submitBtn"
                      css={{
                        width: '100%',
                      }}
                    >
                      Log in
                    </SubmitButton>
                  </Form>
                </View>
              )}
            </LogoContainer>
            {allowRegister && redirect_url !== 'signup' && referral !== websitePresetReferral && !partnerHubLogin && (
              <React.Fragment>
                <ButtonFilled
                  element={Link}
                  to={`${BaseDirectory}`}
                  width="100%"
                  size="lg"
                  color="background"
                  marginTop={6}
                >
                  No account? Sign up
                </ButtonFilled>
              </React.Fragment>
            )}

            <View
              paddingY={6}
              paddingX={6}
              flexDirection="row"
              justifyContent="center"
              flex={1}
              display="flex"
              flexWrap="wrap"
            >
              {links.map((link, linksIndex) => (
                <View paddingX={4} paddingY={3} alignItems="center" justifyContent="center" key={linksIndex}>

                  <Link
                    to={`${BaseDirectory}${link.url}`}
                    target="_blank"
                  >
                    <Text
                      fontSize={1}
                      style={{ textDecoration: 'underline', textAlign: 'center' }}
                      color="subtle"
                    >
                      {link.label}
                    </Text>
                  </Link>
                </View>
              ))}
            </View>
          </Container>
        </Container>
      </Provider>
    );
  }
}

Login.propTypes = {
  dispatchStoreValues: PropTypes.func,
  portal: PropTypes.string,
  logo: PropTypes.string,
  allowRegister: PropTypes.bool,
  siteName: PropTypes.string,
  ssoButtons: PropTypes.objectOf(PropTypes.string),
  ssoAllowLogin: PropTypes.bool,
  history: PropTypes.objectOf(PropTypes.any),
  storedPortalValues: PropTypes.objectOf(PropTypes.any),
};
