/**
 * Format a Stripe error message
 * @param error - The error string that we will need to use
 * @returns either null for not a stripe error or a valid message that can be displayed
 */
export function getStripeErrorMessage(error = '') {
  if (typeof error !== 'string' || !error.includes('Stripe:')) {
    return null;
  }
  const split = error.split(':');
  const code = split[1];

  switch (code) {
    case 'card_not_supported':
      return 'Your card does not support this type of purchase.';
    case 'card_velocity_exceeded':
    case 'insufficient_funds':
      return 'There are insufficient funds on your card to support this purchase.';
    case 'expired_card':
      return 'Your card has expired.';
    case 'incorrect_cvc':
    case 'invalid_cvc':
    case 'incorrect_number':
    case 'invalid_expiry_year':
      return 'Your card details are incorrect.';
    default:
      return 'Payment has failed, no charge has been made to your card. Please try again.';
  }
}

export default getStripeErrorMessage;
