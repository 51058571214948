import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {
  Banner, Button, Container, Text, View, Skeleton,
} from '@go1d/go1d';
import { ContentOverview } from '@go1d/mine/content-overview';
import {
  REACT_APP_API_URL, REACT_APP_BASE_DIRECTORY, REACT_APP_WEBSITE_URL,
} from '../../config';
import { getStoredJWT, loginWithPublicKey } from '../../services/user';
import LogoContainer from '../../components/LogoContainer';

class PackageOverview extends React.Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    storedPortalValues: PropTypes.objectOf(PropTypes.any).isRequired,
    storedPackageValues: PropTypes.objectOf(PropTypes.any).isRequired,
    match: PropTypes.shape({}),
  }

  state = {
    publicJwt: null,
  }

  componentDidMount() {
    const {
      storedPortalValues: {
        publicKey,
        siteDomain: portalName,
      },
    } = this.props;
    const localJwt = getStoredJWT();

    if (localJwt) {
      this.setState({ publicJwt: localJwt });
    } else {
      loginWithPublicKey(publicKey, portalName)
        .then((result) => {
          const { jwt: publicJwt } = result.data;
          this.setState({ publicJwt });
        });
    }
  }

  goBack = () => {
    const { history } = this.props;
    history.push(`${REACT_APP_BASE_DIRECTORY}/choose_learning${get(window, 'location.search', '')}`);
  }

  generateGroupItemsHref = ({ itemId, itemTitle }) => {
    const path = itemTitle ? itemTitle.split(' ').join('-') : '';
    return `${REACT_APP_WEBSITE_URL}/en-au/${path}/c-${itemId}`;
  }

  render() {
    const {
      storedPackageValues: {
        packages = [],
      },
      match,
    } = this.props;
    const { publicJwt } = this.state;

    if (!publicJwt) {
      return (
        <Container
          backgroundColor="background"
          contain="full"
          minHeight="100vh"
        >
          <Container contain="wide">
            <View
              flexWrap="wrap"
              alignItems="center"
              padding={8}
            >
              <Skeleton
                backgroundColor="faded"
                fontSize={4}
                width="70%"
                marginBottom={4}
              />
              <Skeleton
                backgroundColor="faded"
                fontSize={2}
                width="100%"
                marginBottom={3}
              />
              <Skeleton
                backgroundColor="faded"
                fontSize={2}
                width="100%"
                marginBottom={3}
              />
              <Skeleton
                backgroundColor="faded"
                fontSize={2}
                width="100%"
                marginBottom={3}
              />
              <Skeleton
                backgroundColor="faded"
                fontSize={2}
                width="70%"
                marginBottom={3}
              />
            </View>
          </Container>
        </Container>
      );
    }

    const selectedPack = packages.find(pack => Number(pack.id) === Number(match.params.packageId));

    if (!selectedPack) {
      return (
        <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
          <Container contain="normal" marginTop={6}>
            <LogoContainer title="Kick-start your library with content packs">
              <View marginTop={6}>
                <Banner type="danger">
                  <Text fontWeight="semibold">Content pack  unavailable</Text>
                  <Text>
                    There was a problem requesting content pack.
                    {' '}
                    <Button
                      onClick={() => this.goBack()}
                      height="auto"
                      display="inline-flex"
                      color="contrast"
                      css={{
                        textDecoration: 'underline',
                        fontWeight: 600,
                        padding: 0,
                      }}
                    >
                      Go back
                    </Button>
                  </Text>
                </Banner>
              </View>
            </LogoContainer>
          </Container>
        </Container>
      );
    }

    const packLoId = selectedPack.entity && selectedPack.entity.entityId;
    const packInstanceId = selectedPack.portalId;

    return (
      <ContentOverview
        contentWidth="normal"
        loid={packLoId}
        activeInstance={packInstanceId}
        environment={REACT_APP_API_URL}
        groupItemsHref={this.generateGroupItemsHref}
        backLink={<View element="a" css={{ cursor: 'pointer' }} onClick={this.goBack}>Back</View>}
        packageOverview
        packagePublicJWT={publicJwt}
      />
    );
  }
}

export default PackageOverview;
