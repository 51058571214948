import React from 'react';
import PropTypes from 'prop-types';
import {
  Banner, ButtonFilled, Container, Text,
} from '@go1d/go1d';
import { parse } from 'querystring';
import Cookies from 'js-cookie';
import getNested from '../../utils/getNested';
import LogoContainer from '../../components/LogoContainer';
import { logout } from '../../services/user';
import { REACT_APP_BASE_DIRECTORY } from '../../config';

const BaseDirectory = REACT_APP_BASE_DIRECTORY || '';

export default class LogoutContainer extends React.Component {
  state = {
    success: false,
    errorMessage: null,
  };

  async componentDidMount() {
    try {
      await logout();

      this.clearCookies();
      localStorage.clear();

      this.setState({ errorMessage: '', success: true });
      const { alternativeLogoutUrl, ssoAllowPasswordLogin, location: { search } } = this.props;
      const { next } = parse(search.replace('?', ''));

      // If alternative logout URL is set, redirect immediately. Check for recursive redirection
      if (alternativeLogoutUrl && !alternativeLogoutUrl.match(`${window.location.host}/user/logout`)) {
        window.location.assign(alternativeLogoutUrl);
        // If we can be sure that SSO does not login the user in again
        // Temporary hack to treat "next=/p/" set by apiom
      } else if (typeof next !== 'undefined' && !next.match('://') && next !== '/p/') {
        window.location.assign(next);
      } else if (ssoAllowPasswordLogin) {
        this.goToDefaultLogin();
      }
    } catch (error) {
      if (error.response) {
        this.setState({
          success: false,
          errorMessage: getNested(['response', 'data', 'message'], error),
        });
      }
    }
  }

  clearCookies = () => {
    const cookies = document.cookie.split(';');
    Cookies.remove('go1', { path: '/' });
    /* eslint-disable-next-line */
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const time = '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      document.cookie = name + time;
    }
  };

  goToDefaultLogin = () => {
    const { location: { search }, storedPortalValues } = this.props;
    const { next } = parse(search.replace('?', ''));
    const { redirectUrl, adminOnly, portalSelectionFilter } = storedPortalValues;

    // Temporary work around to supply backwards compability for portals who are not on new signup app. Can be removed once we have moved everybody accross.
    // Need in case ssoAllowPasswordLogin is false, and the user clicks on the "back to login" button below.
    if (next === '/p/') {
      window.location.assign(next);
    } else if (redirectUrl.includes('partnerhub')) {
      const { history } = this.props;
      history.push(`${BaseDirectory}/login?redirect_url=${redirectUrl}&admin_only=${adminOnly}&portal_selection_filter=${portalSelectionFilter}`);
    } else {
      const { history } = this.props;
      history.push({ pathname: `${BaseDirectory}/login` });
    }
  };

  render() {
    const { errorMessage, success } = this.state;

    return (
      <Container paddingX={4} paddingBottom={4} contain="wide" alignItems="center">
        <Container data-testid="logoutContainer" contain="narrow" marginTop={6}>
          <LogoContainer
            title={success ? 'You have been logged out' : 'Logout'}
            description={success ? 'Your session has been ended and you can close this window if you are finished.' : 'Terminating your Go1 session.'}
          >
            {errorMessage && (
            <Banner type="danger" width="100%" maxWidth={620} marginBottom={6}>
              <Text fontWeight="semibold">Logout failed</Text>
              <Text>{errorMessage}</Text>
            </Banner>
            )}
            <ButtonFilled color="accent" onClick={this.goToDefaultLogin}>Return to login</ButtonFilled>
          </LogoContainer>
        </Container>
      </Container>
    );
  }
}

LogoutContainer.propTypes = {
  ssoAllowPasswordLogin: PropTypes.bool,
  alternativeLogoutUrl: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  storedPortalValues: PropTypes.objectOf(PropTypes.any).isRequired,
};
