import React from 'react';
import PropTypes from 'prop-types';
import {
  View, Text,
} from '@go1d/go1d';
import LogoHeader from '../LogoHeader';

const PolicyHeader = ({ subTitle }) => (
  <View backgroundColor="faint">
    <View
      marginTop={9}
      marginBottom={8}
      flexDirection="column"
    >
      <LogoHeader />
      <Text
        color="default"
        fontSize={6}
        fontWeight="semibold"
        textAlign="center"
      >
        Terms & policies
      </Text>
      {subTitle
        && (
        <Text
          paddingX={3}
          marginTop={4}
          color="subtle"
          fontSize={2}
          textAlign="center"
        >
          {subTitle}
        </Text>
        )}
    </View>
  </View>
);

PolicyHeader.propTypes = {
  subTitle: PropTypes.string,
};

export default PolicyHeader;
