import http from '../utils/http';
import { REACT_APP_API_URL } from '../config';
import getNested from '../utils/getNested';

export const loginUser = (username, password, portalInstance = 'public') => http.post(`${REACT_APP_API_URL}/user/account/login${portalInstance ? '' : '?allAccounts=false'}`, {
  username,
  password,
  portal: portalInstance,
});

export const resetPassword = (username, portalInstance = '') => http.post(
  `${REACT_APP_API_URL}/user/account/password/${portalInstance}/${username}`,
  {},
);

export const loginWithPublicKey = (publicKey, portalName) => http.get(`${REACT_APP_API_URL}/user/account/current/${publicKey}/${portalName}?allPortals=false`, {});

export const loginWithOnTimeToken = token => http.get(`${REACT_APP_API_URL}/user/account/password/${token}?allPortals=false`, {});

export const getOneTimeToken = (id, jwt, redirect_url) => http.post(`${REACT_APP_API_URL}/user/account/login/${id}`, {
  redirect_url,
}, { headers: { Authorization: `Bearer ${jwt}` } });

export const findAccount = (accounts, portalId) => {
  let targetAccountId = null;
  (accounts || []).forEach((account) => {
    if (parseInt(`${getNested(['instance', 'id'], account)}`, 10) === parseInt(`${portalId}`, 10) || (account.portal_id && parseInt(`${account.portal_id}`, 10) === parseInt(`${portalId}`, 10))) {
      targetAccountId = account.id;
    }
  });
  return targetAccountId;
};

export const redirectToPortalWithOTT = (accountId, jwt, redirect_url = '') => getOneTimeToken(accountId, jwt, redirect_url).then((result) => {
  let url = getNested(['data', 'url'], result);
  // Only apply the fix if we didn't actually want to go to go1.com in the first place
  if (redirect_url && !redirect_url.includes('www.go1.com')) {
    // Don't ask why... Dealing with some user service magic, where it replaces the public.mygo1.com with www.go1.com for one time login tokens
    url = url.replace('www.go1.com', 'public.mygo1.com/p#');
  }

  try {
    ((window !== window.top) ? window.top : window).location.assign(url);
  } catch (e) {
    // In case of failure due to same-origin policy, we dispatch a MessageEvent to the parent window to make redirect
    if (window.top) {
      window.top.postMessage({ type: 'REDIRECT_TO_PORTAL_DASHBOARD' }, url);
    }
  }
});

export const getCurrentUser = (jwt, allAccounts = false, limit = 100, offset = 0, retry = false) => http.get(`${REACT_APP_API_URL}/user/account/me`, {
  params: {
    ...(limit ? [] : allAccounts),
    addPortalConfig: false,
    'x-expensive': 1,
    limit,
    offset,
  },
  headers: { Authorization: `Bearer ${jwt}` },
  // Retry once
}).catch((err) => { if (!retry) { return getCurrentUser(jwt, allAccounts, limit, offset, true); } throw err; });

export const loadAllAccounts = async (jwt) => {
  const requests = [];
  const limit = 100;
  const firstRequest = await getCurrentUser(jwt, null, limit, 0);
  const totalAccounts = getNested(['data', 'totalAccounts'], firstRequest) || 0;
  const accounts = getNested(['data', 'accounts'], firstRequest) || [];
  if (totalAccounts <= limit) {
    return accounts;
  }

  for (let i = limit; i < totalAccounts; i += limit) {
    requests.push(getCurrentUser(jwt, null, limit, i));
  }
  return Promise.all(requests).then(
    (results) => results.reduce((acc, cur) => acc.concat(getNested(['data', 'accounts'], cur) || []), accounts),
  );
};

export const exchangeJwt = (jwt, portal) => http.post(`${REACT_APP_API_URL}/user/jwt-exchange`, {
  portal,
}, { headers: { Authorization: `Bearer ${jwt}` } });

export const setNewPassword = (newPassword, portalInstance = '') => http.put(`${REACT_APP_API_URL}/user/account/profile`, {
  instance: portalInstance,
  password: newPassword,
});

export const getStoredJWT = () => (localStorage.getItem('jwt') || '').replace(/['"]+/g, '');

// DELETE /access/v1.0/session
// done request for old JWT payload schema, not including format.
export const logout = () => {
  const token = getStoredJWT();

  if (token) {
    const parts = token.split('.');
    if (parts[1]) {
      const payload = JSON.parse(atob(parts[1]));
      if (typeof payload.sid !== 'undefined') {
        return http.delete(`${REACT_APP_API_URL}/access/v1.0/session`, { headers: { Authorization: `Bearer ${token}` } });
      }
    }
  }
  return false;
};
