import { connect } from 'react-redux';
import ChooseLearning from './page';
import { errorActions } from '../../actions/error';
import { packageActions } from '../../actions/packages';

/* istanbul ignore next */
const mapStateToProps = state => ({
  storedFormValues: state.formValues,
  storedErrorValues: state.errorValues,
  storedPortalValues: state.portalValues,
  storedPackageValues: state.packageValues,
  storedOnboardValues: state.onboardValues,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  dispatchErrorValues: (...errors) => dispatch(errorActions.storeValues(...errors)),
  dispatchPackageValues: payload => dispatch(packageActions.storePackages(payload)),
  dispatchAddPackage: packageId => dispatch(packageActions.addPackage(packageId)),
  dispatchRemovePackage: packageId => dispatch(packageActions.removePackage(packageId)),
  dispatchSelectPackagePlan: packagePlanId => dispatch(packageActions.selectPackagePlan(packagePlanId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseLearning);
