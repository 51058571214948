import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  ButtonFilled,
  foundations,
  Link,
} from '@go1d/go1d';
import get from 'lodash/get';
import go1Terms from '../../constants/go1Terms';

const PolicyFooter = ({
  onAgree, isAuthenticated, baseUrl, disabled,
}) => (
  <View
    backgroundColor="background"
    minHeight={140}
    flexDirection="row"
    alignItems="center"
    padding={3}
    css={{
      [foundations.breakpoints.sm]: {
        flexDirection: 'column',
      },
    }}
  >
    <View paddingX={3} width={[1, 8 / 12, 8 / 12]}>
      <Text>
        I have read and agree to the Go1
        {' '}
        <Text color="accent">
          {!isAuthenticated && (
            <Link to={`${baseUrl}/customer-terms${get(window, 'location.search', '')}`}>
              user terms and conditions
            </Link>
          )}
          {isAuthenticated && (
            <a href={go1Terms.customerTerms} target="_blank" rel="noopener noreferrer">
              user terms and conditions
            </a>
          )}
        </Text>
        {' '}
        and
        {' '}
        <Text color="accent">
          <Link to={`${baseUrl}/privacy-policy${get(window, 'location.search', '')}`}>
            privacy policy
          </Link>
        </Text>
        {' '}
        , I am over 16 years of age and I consent to Go1 collecting, processing, managing, storing and using personal information in accordance with the Go1 Privacy Policy.
      </Text>
    </View>
    <View
      width={[1, 4 / 12, 4 / 12]}
      paddingY={3}
      paddingX={3}
    >
      <ButtonFilled disabled={disabled} onClick={onAgree} color="accent" size="lg">Agree</ButtonFilled>
    </View>
  </View>
);

PolicyFooter.propTypes = {
  onAgree: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  baseUrl: PropTypes.string,
  disabled: PropTypes.string,
};

export default PolicyFooter;
