import jwt_decode from 'jwt-decode';
import { store } from '../store';

export const TRACKING_REPO_NAME = 'apps/signup';

function beamCall(func, args) {
  const w = window;
  try {
    if (typeof w.beam !== 'undefined') {
      w.beam[func](...args);
    }
  } catch (e) {
    // Do Nothing
  }
}

export const wrappedCalls = {
  track: (...args) => beamCall('track', args),
  startSession: (...args) => beamCall('startSession', args),
  endSession: (...args) => beamCall('endSession', args),
  identify: (...args) => beamCall('identify', args),
  setContext: (...args) => beamCall('setContext', args),
};

function track(page, feature = {}, type) {
  const states = store.getState();
  const { portalValues: { portalId } } = states;
  wrappedCalls.track(
    {
      type: type || feature.id || 'feature.triggered',
      context: {
        application: {
          repo: TRACKING_REPO_NAME,
          page,
        },
        feature: {
          portal_id: portalId, // for partner portal
          ...feature,
        },
      },
    },
  );
}

export function identify() {
  const states = store.getState();
  const { formValues: { jwt } } = states;
  try {
    const currentUser = jwt_decode(jwt).object.content || null;
    if (currentUser) {
      wrappedCalls.identify(
        {
          id: currentUser.id,
          JWT: jwt, // JWT must be in capital
          account: currentUser.accounts.length > 0 ? currentUser.accounts[0] : [],
        },
      );
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export function setContext() {
  wrappedCalls.setContext({
    application: {
      repo: TRACKING_REPO_NAME,
      page: '',
    },
  });
}

export default track;
