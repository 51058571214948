import extractDomain from 'extract-domain';

const genericHosts = [
  'localhost',
  'dev.mygo1.com',
  'web-dev.go1staging.com',
  'website.dev.go1.cloud',
  'website.qa.go1.cloud',
  'website.production-test.go1.cloud',
  'www.go1.com',
  'qa.go1.cloud',
  'staging.mygo1.com',
];

const extractSubDomain = rootDomain => {
  if (rootDomain) {
    // Only extract if there is sub-domain in portal primary domain
    const domainParts = rootDomain.split('.');
    return domainParts.length > 2 ? extractDomain(rootDomain) : rootDomain;
  }

  return null;
};

export {
  genericHosts, extractSubDomain,
};
